import React, { Component } from "react";
import { Navigate  } from 'react-router-dom';
import Serverurl from './Apiurl';
import axios from 'axios';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';  
import TableSortLabel from '@mui/material/TableSortLabel';
import TableBody from '@mui/material/TableBody'; 
import TableCell from '@mui/material/TableCell';  
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';  
import TablePagination from '@material-ui/core/TablePagination'; 
import PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow'; 
import { Bar } from "react-chartjs-2";
import stats1 from './images/yesterday-present.png';
import stats2 from './images/no-of-students.png';
import attndnceIcon from './images/attendance.png';
import RegisterIcon from './images/registration.png';
import yespreIcon from './images/yesterday-present.png';
import AlertsIcon from './images/Alerts.png';

import ParentPassword from "./ParentPassword";
export default class Dashboard extends Component 
{
	
	constructor(props) 
	  {
			super();

			this.state = {yesatdncecnt:0,parentcnt:1,open:false,errormessage:'',studcnt:0,prevpage:0,dataarr:[],redirect:false,pagename:'',notifycnt:0,notifyarray:[],rowsPerPage:10,page: 0 ,}
	  }
	  myChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
  this.setState({[nam]: val}); 
} 
getParameterByName(name, url) {
    if (!url) url = window.location.href;
    url = url.toLowerCase(); 
    name = name.replace(/[\[\]]/g, "\\$&").toLowerCase();
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

	componentWillMount() { 
localStorage.setItem("pageactive","1")
}
	componentDidMount()
	{
		


		
	if(localStorage.getItem("MMCuserid")===null)
	{
		this.setState({
							redirect: true,
							pagename:'/Login'
						  }) 
		

	}localStorage.removeItem("MMEdSrId")
					  
			localStorage.removeItem("MMCSRId")
		localStorage.removeItem("MMCsaid")			  
						  		let search = window.location.href;
let strmsg = this.getParameterByName('msg',search);
	if(strmsg==="1")
	{
			this.setState({
							open: true,
							errormessage:'Please Select Student/ Register Student'
						  }) 
	}
	if(strmsg==="2")
	{
			this.setState({
							open: true,
							errormessage:'Please Select Student/ Admit the Student'
						  }) 
	}
	localStorage.setItem("page",this.state.rowsPerPage)
	 document.addEventListener("keydown", this.escParentphFunction, false);

  
		this.StatsCountApicall();
	}
		componentWillUnmount(){
    document.removeEventListener("keydown", this.escParentphFunction, false);
	
  }
  BirthdayAlerts()
  {
	  
	 
					
		axios.get(Serverurl+'ajaxstudentregistration.php?action=birthdayalerts')
       .then(resp => { 	
	   
	   axios.get(Serverurl+'ajaxnotifications.php?action=getdate')
       .then(resp => { 	
	   if(resp.data===1){
				axios.get(Serverurl+'ajaxnotifications.php?action=notifyalert')
					   .then(resp => {
								axios.get(Serverurl+'ajaxfeepayments.php?action=feealert')
													   .then(resp => { 					
															})	
						   
							})	
	   }							
			})	
	})	
			
  }
  
  
	StatsCountApicall()
	{
		axios.get(Serverurl+'ajaxattendance.php?action=dashboardlist&ad_br_id='+localStorage.getItem("MMCbranchid"))
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({yesatdncecnt:resp.data[0]["attencnt"],studcnt:resp.data[0]["stucnt"]});
			}
			this.NotificationApicall(this.state.page,this.state.rowsPerPage);
	   });
	
	}
	NotificationApicall(page,rowsPerPage)
	{let strbranch=localStorage.getItem("MMCbranchid");
	
	  if(localStorage.getItem("MMCrole")==="1")
	 
		  {
			  strbranch="";
		  }
		axios.get(Serverurl+'ajaxnotifications.php?action=listbranch&no_branchid='+strbranch+'&index='+page+'&maxrecrd='+rowsPerPage)
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({notifyarray:resp.data,notifycnt:resp.data[0].count});
			}
			
	   });
	   this.BirthdayAlerts();
		this.StudentAttendanceGraphApicall();
	}
	StudentAttendanceGraphApicall(){
		var me=this;
	let strbranch=localStorage.getItem("MMCbranchid");
	
	  if(localStorage.getItem("MMCrole")==="1")
	 
		  {
			  strbranch="0";
		  }
axios.get(Serverurl+'ajaxattendance.php?action=attgraph&ad_br_id='+strbranch)
       .then(resp => { 		
			if(resp.data.length>0){
   
		let optionsRep = resp.data;
		let prev= "",title= "",prevsub="";
		let hlabel=[],subjectsarr=[];
		let hsub1=[];
		//this.setState({ studentarray:resp.data});
		
		 
		 	for (let eachrep of optionsRep)
	{		
		let strval=eachrep['tc_title']+"-"+eachrep['bc_title'];
		if(prev!=strval)
		{
			title=eachrep['tc_title']+"-"+eachrep['bc_title'];
			prev=eachrep['tc_title'];
		hlabel.push(title);
		}
		
				
	}
	
	

	  let sorted = optionsRep;

	//.sort((a, b) => b.tc_id - a.tc_id)
	
	 let edcell=[], cellsubject=[];
	let edrow=[];
	if(optionsRep.length>0){
		  {optionsRep.map((row, index) => {
		 var strdataset="";
		
		
			let j=parseInt(index)-1;
			cellsubject.push(row.percent);
		/*if(prevsub!=row.bc_id)
		{
			if(index!==0){
			//cellsubject.push(row.percent);
			 edcell["label"]=sorted[j]["bc_title"]+")";
			 edcell["backgroundColor"]=sorted[j]["bc_color"];
			
			 edcell["fill"]=true;
           
			  edcell["borderColor"]=sorted[j]["bc_color"];
			   edcell["borderWidth"]=1;
			    edcell["hoverBackgroundColor"]=sorted[j]["bc_color"];
				 edcell["hoverBorderColor"]=sorted[j]["bc_color"];
				 edcell["data"]=cellsubject;
			
		
				edrow.push(edcell);
		cellsubject=[];
		edcell=[];
			}
			
			
			
		
		cellsubject.push(row.percent);
			 prevsub=row.bc_id;
		}
		else{
			
			cellsubject.push(row.percent);
			 prevsub=row.bc_id;
		}*/

		  })};
		  }
		/* let  lastindex=parseInt(sorted.length)-1;
		  edcell["label"]=sorted[lastindex]["bc_title"];
			 edcell["backgroundColor"]=sorted[lastindex]["bc_color"];
			  edcell["borderColor"]=sorted[lastindex]["bc_color"];
			   edcell["borderWidth"]=1; edcell["fill"]=true;
			    edcell["hoverBackgroundColor"]=sorted[lastindex]["bc_color"];
				 edcell["hoverBorderColor"]=sorted[lastindex]["bc_color"];
				 edcell["data"]=cellsubject;*/
				 edcell["label"]="";
			 edcell["backgroundColor"]="#c0a2ec";
			  edcell["borderColor"]="#c0a2ec";
			   edcell["borderWidth"]=1; edcell["fill"]=true;
			    edcell["hoverBackgroundColor"]="#c0a2ec";
				 edcell["hoverBorderColor"]="#c0a2ec";
				 edcell["data"]=cellsubject;
			
			edrow.push(edcell);
		const dataperformance = {
         datasets:edrow,labels:hlabel,
         
       
        };
		
	 this.setState({
        dataarr: dataperformance,
    });
	
	

	
	
	   }else{
		   this.setState({
        dataarr: [],
    });
	   }
			
	   });
	}
	componentDidUpdate() {
	
  if(this.state.prevpage !== this.state.page) {
    this.setState({   prevpage: this.state.page});
  this.NotificationApicall(this.state.page, localStorage.getItem("page"));  
  }
}
handleChangePage = (event, newPage) => {  
   
    let pageval = localStorage.getItem("page");
    this.setState({  
      page: newPage,
      rowsPerPage:parseInt(pageval)
    });
   
 
    setTimeout(() =>  this.componentDidUpdate() ,500); 
  };  
  
  
   handleChangeRowsPerPage = event => {  
  
    let pageval = event.target.value;
    localStorage.setItem("page",pageval)

    setTimeout(() =>  this.componentDidUpdate() ,500);
    this.setState({  
      page: 0,
      rowsPerPage:parseInt(pageval),
	  prevpage:-1
    }); 
	
	
  };
escParentphFunction= (event) => {
	 var self=this; 
        let nam = event.target.name;
        let val = event.target.value;
		val=val.trim();
 this.setState({[nam]: val});

  if(event.keyCode === 13) {
	if(val.length<10)
	{
		 this.setState({open: true,errormessage:'Please Enter Valid Phone No.'});
		 return false;
	}
axios.get(Serverurl+'ajaxparent.php?action=getall&pr_phonenumber='+val)
       .then(resp => { 		
			if(resp.data.length>0){
				 this.setState({redirect: true,pagename:'/Mystudents?pid='+resp.data[0]["pr_id"]});
			}
			else
			{
				this.setState({parentcnt:0});
			}
	   })
	
  }
	
	 }
		handleErrorclose=(event)=>{
	  
	this.setState({open: false});  
  }
	 render() {
	  
	 if (this.state.redirect === true) {
      return <Navigate to={this.state.pagename} />
    }
	  
  const options = {
		
		 tooltips: {
    mode: 'label'
  }, 
      responsive: true,
      legend: {
        display: false,
		 position:'right'
      },
	  title:{
                    display:false,
                    text:'Performance',
                    fontSize:20
                    },
      type: "bar",
	  scales: {
      yAxes: [{
         ticks: {
            beginAtZero: true
         }
      }]
   }
      //   scales: {
      //     xAxes: [{
      //         stacked: true
      //     }],
      //     yAxes: [{
      //         stacked: true
      //     }]
      // }
    };

  return (
  
	 <React.Fragment> 
	 {this.state.parentcnt===0 && (
	 <ParentPassword phone={this.state.Phone}/>
	 )}
	 {this.state.parentcnt===1 && ( <React.Fragment> 
	 
	  <p>  <Collapse in={this.state.open}>
										<Alert
										  action={
											<IconButton
											  aria-label="close"
											  color="inherit"
											  size="small"
											  onClick={() => {
											   this.handleErrorclose();
											  }}
											>
											  <CloseIcon fontSize="inherit" />
											</IconButton>
										  } severity="error"
										>
										{this.state.errormessage}
										</Alert>
								</Collapse></p>
{localStorage.getItem("MMCrole")!=="1" &&( <React.Fragment> 
	 <div className="statssec-cnt1">
	 <div className="statsicon">
	 <img src={stats1} alt="MM Coaching" />
	 </div>
	  <div className="statstext">
	<p>Latest Attendance</p>
	<h2>{this.state.yesatdncecnt}</h2>
	   </div>
	 </div>
	  <div className="statssec-cnt1">
	   <div className="statsicon">
	 <img src={stats2} alt="MM Coaching" />
	 </div>
	  <div className="statstext">
	<p>Students</p>
	<h2>{this.state.studcnt}</h2>
	   </div>
	  </div>
	  
	  <div className="statssec-register"><div className="panelbox">
	  <div className="admitpad">
	  <h2><img src={RegisterIcon} /> Admission/Pay Fee</h2>
	  </div> </div>
	  <div className="admitpad">
	  
	  <p><TextField id="txtPhone" fullWidth type="Number" size="small"  placeholder="Phone No."  name="Phone" value={this.state.Phone}  inputProps={{tabIndex: "1",maxLength:15}} InputLabelProps={{ shrink: true }}  
	  onChange={this.escParentphFunction}
onFocus={this.escParentphFunction}
	  /></p>
			</div>
	   </div>
	   </React.Fragment>  )}
	 <div className="clear"></div>
	 	 <div className="attandace-box">
		  <div className="boxheading">
	  <div className="admitpad">
	  <h2><img src={attndnceIcon} /> Attendance</h2>
	  </div> </div> <div className="admitpad alerts">
	  	
		<Bar id="mychart"
	  
	  
	  
        data={this.state.dataarr}
        width={null}
        height={null}
       options={options}
      />
	  </div>
		 </div>
	 
	 <div className="statssec-alerts">
	 <div className="boxheading">
	  <div className="admitpad">
	  <h2><img src={AlertsIcon} /> Alerts</h2>
	  </div> </div>
	  <div className="admitpad alerts">
	 
	 <Paper >
  <TableContainer >  
                <Table stickyHeader aria-label="sticky table">  

					  
					  
                      <TableBody>
                     {(this.state.notifyarray).map((item,index) => {  
           return (
 <React.Fragment>
 <TableRow key={index} >


<TableCell>
<div className="alertleft">
<a href={'#/Notificationview?noid='+item.no_id} >{item.date}</a>
</div><div className="alertright">
<a href={'#/Notificationview?noid='+item.no_id} >{item.no_title}</a>
</div><div className="clear"></div>
<span className="tabfont">for {item.title} {item.optiontype}</span>
</TableCell>

 </TableRow>

 </React.Fragment>	
);
					})}
					
					{this.state.notifycnt===0 &&(
					
					<TableRow key={0} >
<TableCell align="center" colSpan="1">No Records</TableCell>
					</TableRow>
					) }
					
                      </TableBody>


</Table>
  </TableContainer> 
  <TablePagination  
        rowsPerPageOptions={[]}  
        component="div"  
        count={this.state.notifycnt}  
        rowsPerPage={this.state.rowsPerPage}  
        page={this.state.page}  
        onPageChange={this.handleChangePage}  
        onChangeRowsPerPage={this.handleChangeRowsPerPage}  
      />   
  </Paper>
	 
	 
	  </div>
	 </div>
	 </React.Fragment>   )}
	 </React.Fragment> 
	 )
	 }
}






