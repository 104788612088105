import React ,{Component} from 'react'; 
import ReactDOM from "react-dom";
import Button from '@mui/material/Button';
import {Navigate  } from 'react-router-dom';
import axios from 'axios';
import Serverurl from './Apiurl';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import basicicon from './images/basic-detail.png';
import call from './images/call-icon.png';
import dob from './images/dob-icon.png';
import gender from './images/gender-female.png';
import institute from './images/institute-icon.png';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
export default class MobProfile extends Component {
  constructor(props) {
    super(props);
	
	
    this.state = { studenetdata:[], schoolname:'',balamount:0 ,redirect:false,pagename:'', 
    };
  }
	componentDidMount()
	{
		if(localStorage.getItem("MMCPrid")===null)
	{
		this.setState({
							redirect: true,
							pagename:'/MobLogin'
						  }) 
		

	}
		this.OnloadStudentGetdetails();
		
		
		
	}
	
	OnloadStudentGetdetails()
{
	
	axios.get(Serverurl+'ajaxstudentregistration.php?action=list&sr_id='+ localStorage.getItem("MMCstudreid"))
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
							
							studenetdata:resp.data
						  }) 
			}
		this.OnloadStudentadmision();
	   });

}
OnloadStudentadmision(said)
{
	axios.get(Serverurl+'ajaxstudentadmission.php?action=list&sa_id='+localStorage.getItem("MMCstudid"))
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
							
							
							schoolname:resp.data[0]["sa_schoolname"]
							,balamount:parseFloat(resp.data[0]["totalfee"]) -parseFloat( resp.data[0]["balamt"]),totalfee:resp.data[0]["totalfee"]
						  }) 
			}

	   });

}

	
	render() { 
	return (
		<React.Fragment>
	 <div className="pagebox-col">
	 	<div className="boxheading">
		<div className="admitpad"> <h2><img src={basicicon} /> Basic details</h2></div></div>
		
	 <div className="admitpad ">
	 {(this.state.studenetdata).map((item,index) => { 
let strgenrtype="";
if(item.sr_gender==="1" )
{	strgenrtype="Son";	
}else{
strgenrtype="Daughter";	
}
           return ( <React.Fragment>
		<div className="studbasicimg">   
		   <img src={Serverurl+"uploads/"+item.sr_profileimg} />
		</div>
		<h3 className="aligncenter">{item.sr_studentname}</h3>
		
		
		<p className="aligncenter">{strgenrtype} of
		{item.sr_fathername!=="" && (<React.Fragment>
		
		  &nbsp;{item.sr_fathername}
		</React.Fragment>)}
		{item.sr_mothername!=="" && (<React.Fragment>
		
		 &nbsp;and {item.sr_mothername}
		</React.Fragment>)}
		</p>
		
		<ul className="studecontactdet">
		<li>
		<p>  <a href={'tel:'+item.sr_phonenumber}><img src={call} /></a></p>
		<p> <a href={'tel:'+item.sr_phonenumber}>{item.sr_phonenumber}</a></p>
		</li>
		<li>
		<p>  <img src={dob} /></p>
		<p> {item.dob}</p>
		</li>
			<li>
		<p>  <img src={gender} /></p>
		<p> {item.srgender}</p>
		</li>
		
		<li>
		<p>  <img src={institute} /></p>
		<p> {this.state.schoolname}</p>
		</li>
		
		</ul>
 </React.Fragment>	
);
					})}
					
					<p className="aligncenter"><a  className="frmPrimarybtn" href="#/MobileChangepassword">Change Password</a></p>
	 </div></div>
	  <div className="balanceamntbg">
 <p>Balance Payment</p>
 <h2><CurrencyRupeeIcon />{this.state.balamount}</h2></div>
 	  	</React.Fragment>
    );
  
  }
}
  
  