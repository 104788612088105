import React, { Component } from "react";
import { Navigate  } from 'react-router-dom';
import Serverurl from './Apiurl';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@mui/material/IconButton';
import prof from './images/test-result.png';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';  

import TableSortLabel from '@mui/material/TableSortLabel';
import TableBody from '@mui/material/TableBody'; 
import TableCell from '@mui/material/TableCell';  
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';  
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow'; 
import SearchIcon from '@mui/icons-material/Search';
import Checkbox from '@mui/material/Checkbox';
export default class TestUpload extends Component 
{
	constructor(props) 
	  {
			super();
			this.state = {testarr:[],testlabel:'Select Test',testid:'0',errormessage:'',open:false,Yeararr:[],yearid:'',yearlabel:'Select Year',sr_studentname:'',
			Batcharr:[],batchlabel:'Select Batch',batchid:'0',primarybtntext:'Search',classid:'0',classlabel:'Select Class',subjectlabel:'Select Subject',subjectarr:[],
			subjectid:'0',Classarr:[],studentsdata:[],Brancharr:[],branchid:'',secondarytext:'Save',teststatus:false,sylabus:'2',sr_issupend:'',
			}
	  }
	componentDidMount()
	{
		if(localStorage.getItem("MMCuserid")===null)
		{
			this.setState({redirect: true,
							pagename:'/Login'}) 
		}this.YearBind();
		 if(localStorage.getItem("MMCrole")==="1")
	 
		  { this.BranchBind();
		  }
		  else{
			  this.setState({branchid: localStorage.getItem("MMCbranchid")}) 
			
		  }
	}
	handleErrorclose=(event)=>{
	  
	this.setState({open: false});  
  }
		statusChange=(event)=>{
	  
	this.setState({teststatus: !this.state.teststatus});  
  }
	
myChangeHandler = (event) => {
		
		
  let nam = event.target.name;
  let val = event.target.value;

	  
	   this.setState({[nam]: val}, () => {
if(nam==="batchid")
	   {
		   
 	
	
	   }
	if(nam==="classid")
	   {
		 this.BatchBind();   
 this.TestApibind();
	this.SubjectsBind();
	   }  
}); 
	   
	   	
}
		componentWillMount() { 
localStorage.setItem("pageactive",5)
}
handleSyllabusChange = (event) => {
 
	 
  let nam = event.target.name;
  let val = event.target.value;

	  
	   this.setState({[nam]: val}, () => {
if(nam==="sylabus" && this.state.classid!=="0")
	   {
		this.BatchBind();      
 	this.SubjectsBind();
	   }
});  
}
ClassBind()
{
	axios.get(Serverurl+'ajaxtrustandclass.php?action=getall&tc_type=2')
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
						Classarr:resp.data	
							}) 
			}
		
	   }) .then(data => {
        let classFromApi = this.state.Classarr.map(row => {
                          
          return {value: row.tc_id, display:row.tc_title}
        });
      
		
        this.setState({
          Classarr: [{value: '0', display: 'Select Class'}].concat(classFromApi)
        });
      })

}
BatchBind()
{
	axios.get(Serverurl+'ajaxbatch.php?action=list&bc_class_id='+this.state.classid+'&bc_syllbustype='+this.state.sylabus+'&bc_br_id='+localStorage.getItem("MMCbranchid"))
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
						Batcharr:resp.data	
							}) 
			}else{
				
				this.setState({
						Batcharr:[]	
							}) 
			}
	
	   }).then(data => {
        let batchFromApi = this.state.Batcharr.map(row => {
                          
          return {value: row.bc_id, display:row.bc_title}
        });
      
		
        this.setState({
          Batcharr: [{value: '0', display: 'Select Batch'}].concat(batchFromApi)
        });
      })

}
SubjectsBind()
{
	axios.get(Serverurl+'ajaxsubjects.php?action=getall&sb_class_id='+this.state.classid+'&sb_syllabustype='+this.state.sylabus)
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
						subjectarr:resp.data	
							}) 
			}else{
				this.setState({
						subjectarr:[]	
							}) 
			}
			
	   }) .then(data => {
        let subFromApi = this.state.subjectarr.map(row => {
                          
          return {value: row.sb_id, display:row.sb_title}
        });
      
		
        this.setState({
          subjectarr: [{value: '0', display: 'Select Subject'}].concat(subFromApi)
        });
      })

}

YearBind()
{
	axios.get(Serverurl+'ajaxtrustandclass.php?action=getall&tc_type=3')
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
						Yeararr:resp.data,yearid:	localStorage.getItem("MMCyearid")	
							}) 
			}
		this.ClassBind();
	   }) .then(data => {
        let yearFromApi = this.state.Yeararr.map(row => {
                          
          return {value: row.tc_id, display:row.tc_title}
        });
      
		
        this.setState({
          Yeararr: [{value: '0', display: 'Select Year'}].concat(yearFromApi)
        });
      })

}
/*BatchBind()
{
	let strurl="";	
  if(localStorage.getItem("MMCrole")==="1")
	 
		  { 

		  strurl='ajaxbatch.php?action=list&bc_class_id='+this.state.classid;
		  }else{
    strurl='ajaxbatch.php?action=droplist&bc_class_id='+this.state.classid+'&bc_id='+localStorage.getItem("MMCbranchid");
	}
	axios.get(Serverurl+strurl)
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
						Batcharr:resp.data	
							}) 
			}else{this.setState({
						Batcharr:[]
							})
							}
	
	   }).then(data => {
        let batchFromApi = this.state.Batcharr.map(row => {
                          
          return {value: row.bc_id, display:row.bc_title}
        });
      
		
        this.setState({
          Batcharr: [{value: '0', display: 'Select Batch'}].concat(batchFromApi)
        });
      })

}*/
BranchBind()
{
	axios.get(Serverurl+'ajaxbranch.php?action=getall')
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
						Brancharr:resp.data	
							}) 
			}
	 
	   }) .then(data => {
        let branchFromApi = this.state.Brancharr.map(row => {
                          
          return {value: row.br_id, display:row.br_title}
        });
      
		
        this.setState({
          Brancharr: [{value: '0', display: 'Select Branch'}].concat(branchFromApi)
        });
      })

}
	TestApibind()
	{
	let strbranch=localStorage.getItem("MMCbranchid");
	 if(localStorage.getItem("MMCrole")==="1")
	 
		  { 
			  strbranch=this.state.branchid;
		  }	
	axios.get(Serverurl+'ajaxtest.php?action=getall&classid='+this.state.classid+'&te_branch_id='+strbranch+'&te_yearid='+this.state.yearid)
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
						testarr:resp.data	
							}) 
			}else{
				this.setState({
						testarr:[]	
							}) 
				
			}
		
	   }) .then(data => {
        let testFromApi = this.state.testarr.map(row => {
                          
          return {value: row.te_id, display:row.te_testname}
        });
      
		
        this.setState({
          testarr: [{value: '0', display: 'Select Test'}].concat(testFromApi)
        });
      })
	}
	OnSearchTest= (event) => 
		{
			let classid=this.state.classid;
			let branchid=this.state.branchid;
			let subjectid=this.state.subjectid;
			//let testid=this.state.testid;
			let batchid=this.state.batchid;
			let sylabus=this.state.sylabus;
			
			
	if(classid==='0'){
						
				this.setState({errormessage:"Please Select Class",open:true});
		 window.scrollTo({        top: 0     })
	return false; 
				}if(branchid==='0'){
						
				this.setState({errormessage:"Please Select Branch",open:true});
		 window.scrollTo({        top: 0     })
	return false; 
				}
				if(batchid==='0'){
						
				this.setState({errormessage:"Please Select Batch",open:true});
		 window.scrollTo({        top: 0     })
	return false; 
				}
				
				if(subjectid==='0'){
						
				this.setState({errormessage:"Please Select Subject",open:true});
		 window.scrollTo({        top: 0     })
	return false; 
				}
				/*
				if(testid==='0'){
						
				this.setState({errormessage:"Please Select Test",open:true});
		 window.scrollTo({        top: 0     })
	return false; 
				} */
			
			this.SudentsApicall();
		}
	SudentsApicall()
	{
		let strbranch=this.state.branchid;
	 if(localStorage.getItem("MMCrole")!=="1")
	 
		  { 
			  strbranch=localStorage.getItem("MMCbranchid");
		  }
		axios.get(Serverurl+'ajaxstudentmarks.php?action=markslist&sm_class_id='+this.state.classid+'&sm_branchid='+strbranch+'&sm_batch_id='+this.state.batchid+'&sm_sb_id='+this.state.subjectid+'&sm_te_id='+this.state.testid+'&yearid='+this.state.yearid+'&sr_studentname='+this.state.sr_studentname+'&sr_issupend=0')
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
						studentsdata:resp.data	
							}) 
			}else{	this.setState({
						studentsdata:[]
							}) }
		
	   })
		
	
	}
	
handleAbsent = (id,rowsd) => {
const updatedArray = [...this.state.studentsdata];

	if(updatedArray[rowsd].sm_isabsent==="0")
	{
		updatedArray[rowsd].sm_isabsent = "1" ;
			  
	}
else{
	updatedArray[rowsd].sm_isabsent = "0" ;
}
 this.setState({subjectoparr: updatedArray,});


	
  }; 
	OnSubmitResults= (event) => {
		 let strcol="",strsep="";
		let optioarray=this.state.studentsdata;
		 var newRow = optioarray.map((row, i) => {    
		 strcol=strcol+strsep+row.sa_id+"~"+row.sm_marks+"~"+row.sm_isabsent;
		 strsep=";";
		 });
		 this.setState({secondarytext:'Processing...',open:false});
		var formData = new FormData();
		formData.append('action','marksimport') 	
		formData.append('sm_te_id',this.state.testid)
		formData.append('sm_sb_id',this.state.subjectid)
		formData.append('userid',localStorage.getItem("MMCuserid"))
		formData.append('displaytype',strcol)
		if(this.state.teststatus===true)
		{
		formData.append('status',1)
		}
		else{
		formData.append('status',0)	
		}
		axios.post(Serverurl+"ajaxstudentmarks.php", formData, {
				}).then(res => {
					
					this.setState({secondarytext:'Save',errormessage:'Updated Successfully',open:true});
				});
	}
	
	 handleboxChange = (idx) => evt  => {
       
             
               
				const re = /^[0-9\.]+$/;
				if (evt.target.value === '' || re.test(evt.target.value)) {	
			const updatedArray = [...this.state.studentsdata];
		
			updatedArray[idx].sm_marks=evt.target.value;
	 	 this.setState({studentsdata:updatedArray});
				}
		  }
	render() {
	  
	 if (this.state.redirect === true) {
      return <Navigate to={this.state.pagename} />
    }
	  return (
  
	 <React.Fragment> 
	  <div className="pagebox-col">
	 <div className="formview">
			<div className="boxheading">
				  <div className="contntpad">
					 <p>  <Collapse in={this.state.open}>
										<Alert
										  action={
											<IconButton
											  aria-label="close"
											  color="inherit"
											  size="small"
											  onClick={() => {
											   this.handleErrorclose();
											  }}
											>
											  <CloseIcon fontSize="inherit" />
											</IconButton>
										  } severity="error"
										>
										{this.state.errormessage}
										</Alert>
								</Collapse></p>
				  <h2><img src={prof} /> Upload Test Results</h2>
				  </div> </div>
	   <div className="contntpad">
	 
	  <ul> 
	  
	  <li><p>Student Name</p>
	  <p ><TextField
            variant="outlined"
            margin="normal"
            fullWidth value={this.state.sr_studentname}
           inputProps={{tabIndex: "1",maxLength:150}}
			placeholder="Student Name"
            name="sr_studentname" onChange={this.myChangeHandler}
		
          /></p>
	  </li>
	  
	  
	  <li><p><span className="validation">*</span>Year</p>
	  <p>
	  <FormControl  fullWidth size="small" >
        
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.yearlabel, value: this.state.yearid }}
          onChange={this.myChangeHandler}
          id="state"
		   value={this.state.yearid}
		  name="yearid"
		
		
        >
		{this.state.Yeararr.map(row => (
            <option  
            
              value={row.value}
            >
              {row.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
	  
	  </li>
	    <li><p><span className="validation">*</span>Class</p>
	  <p>
	  <FormControl fullWidth  size="small">
       
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.classlabel, value: this.state.classid }}
          onChange={this.myChangeHandler}
          id="state"
		   value={this.state.classid}
		  name="classid"
		
		
        >
		{this.state.Classarr.map(row => (
            <option  
            
              value={row.value}
            >
              {row.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
	  
	  </li>
	 
	
	   <li><p><span className="validation">*</span> Sylabus Type</p>
	 <p> <Radio
        checked={this.state.sylabus === '2'}
        onChange={this.handleSyllabusChange}
        value="2"
        name="sylabus"
        inputProps={{ 'aria-label': 'B' }}
      />State<Radio
        checked={this.state.sylabus === '1'}
        onChange={this.handleSyllabusChange}
        value="1"
        name="sylabus"
        inputProps={{ 'aria-label': 'A' }}
      />CBSE
	  <Radio
        checked={this.state.sylabus === '3'}
        onChange={this.handleSyllabusChange}
        value="3"
        name="sylabus"
        inputProps={{ 'aria-label': 'A' }}
      />PUC
      </p>

	  
	 </li><div className="clear"></div>
	 
	 <li><p><span className="validation">*</span>Batch</p>
	  <p>
	  <FormControl  fullWidth size="small" >
       
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.batchlabel, value: this.state.batchid }}
          onChange={this.myChangeHandler}
          id="state"
		   value={this.state.batchid}
		  name="batchid"
		
		
        >
		{this.state.Batcharr.map(row => (
            <option  
            
              value={row.value}
            >
              {row.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
	  
	  </li>
	  <li><p>Subject</p>
	   <p>
	  <FormControl  fullWidth size="small" >
       
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.subjectlabel, value: this.state.subjectid }}
          onChange={this.myChangeHandler}
          id="state"
		   value={this.state.subjectid}
		  name="subjectid"
		
		
        >
		{this.state.subjectarr.map(row => (
            <option  
            
              value={row.value}
            >
              {row.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
	 
	 </li>
	  <li><p>Test</p>
	   <p>
	  <FormControl  fullWidth size="small" >
       
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.testlabel, value: this.state.testid }}
          onChange={this.myChangeHandler}
          id="state"
		   value={this.state.testid}
		  name="testid"
		
		
        >
		{this.state.testarr.map(row => (
            <option  
            
              value={row.value}
            >
              {row.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
	 
	 </li> <li>
	 <Button variant="standard" className="frmPrimarybtn" endIcon={<SearchIcon />} onClick={this.OnSearchTest}>
								  {this.state.primarybtntext}
						</Button>
	 </li>
	 </ul>
	 
	 	  <div className="clear"></div>
	 
 <Paper >
  <TableContainer >  
                <Table stickyHeader aria-label="sticky table">  

					  <TableHead> 
<TableRow>
                                              
              <TableCell>Student
</TableCell>         
			      <TableCell>Marks
</TableCell>  	<TableCell>Is Absent
</TableCell>  				
                      </TableRow></TableHead>
					  
                      <TableBody>
                     {(this.state.studentsdata).map((item,idx) => {  
           return (
 <React.Fragment>
 <TableRow key={idx} >
 

<TableCell>{item.sr_studentname}-({item.sa_referenceno})
</TableCell>

<TableCell><TextField
                              ref="innerpack"
                              name="innerpack"
                              value={this.state.studentsdata[idx].sm_marks}
                              onChange={this.handleboxChange(idx)}
                              id={this.state.studentsdata[idx].sa_id} 
                                   inputProps={{tabIndex: "1",maxLength:6}} 
                            /></TableCell>



<TableCell>
{item.sm_isabsent==="0" && (
<Checkbox
  
  onChange={()=>{this.handleAbsent(item.sm_id,idx)}}
  inputProps={{ 'aria-label': 'controlled' }}
/> 
)}{item.sm_isabsent==="1" && (
<Checkbox
  checked
  onChange={()=>{this.handleAbsent(item.sm_id,idx)}}
  inputProps={{ 'aria-label': 'controlled' }}
/> 
)}
 </TableCell>

 </TableRow>

 </React.Fragment>	
);
					})}
					
					{this.state.studentsdata.length===0 &&(
					
					<TableRow key={0} >
<TableCell align="center" colSpan="2" >No Records</TableCell>
					</TableRow>
					) }
					
                      </TableBody>


</Table>
  </TableContainer> 
  
  </Paper><br/>
  
  <p className="aligncenter">
  
   <Button variant="standard" className="frmPrimarybtn" endIcon={<CheckRoundedIcon />} onClick={this.OnSubmitResults}>
								  {this.state.secondarytext}
						</Button></p>
	  <div className="clear"></div>
	  </div>
	   </div>
	    </div>
	 </React.Fragment> 
	 )
	 }
}







