import { BrowserRouter as Router, Route, Routes,NavLink,Outlet,Link,useLocation } from 'react-router-dom';
import React ,{Component} from 'react';
import ReactDOM from "react-dom";
import axios from 'axios'; 
import logo from './images/weblogo.png';
import SettingsIcon from '@mui/icons-material/Settings';
import Box from '@mui/material/Box';
import Serverurl from './Apiurl';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import CopyAllOutlinedIcon from '@mui/icons-material/CopyAllOutlined';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import QuizIcon from '@mui/icons-material/Quiz';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import GetAppIcon from '@mui/icons-material/GetApp';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import CoPresentIcon from '@mui/icons-material/CoPresent';
class Layouthead extends Component {

	constructor(props) {
    super(props);
	 
	 this.state = {open:false,network:1,name:'',reprtclass:'',testclass:'',masterclass:'',studclass:'',mmenutab:false,menutab1:false,menutab3:false,menutab2:false,redirect:false,pagename:'',anchorEl:false,open:false}
	
	}
	
MenuPanelopen = () => 
{
this.setState({menutab:!this.state.menutab}, () => {
	
if(this.state.menutab===false )
	   {this.setState({masterclass:"active",studclass:"",testclass:"",reprtclass:""});
		  
	   }else{
		   this.setState({masterclass:"",studclass:"",testclass:"",reprtclass:""});
	   }
});  
}
MenuPanelopen1 = () => 
{
this.setState({menutab1:!this.state.menutab1}, () => {
	
if(this.state.menutab1===false )
	   {this.setState({masterclass:"",studclass:"",testclass:"active",reprtclass:""});
		  
	   }else{
		   this.setState({masterclass:"",studclass:"",testclass:"",reprtclass:""});
	   }
});  
}
	MenuPanelopen2 = () => 
{	

this.setState({menutab2:!this.state.menutab2}, () => {
	
if(this.state.menutab2===false )
	   {this.setState({studclass:"",masterclass:"",testclass:"",reprtclass:"active"});
		  
	   }else{
		   this.setState({studclass:"",masterclass:"",testclass:"",reprtclass:""});
	   }
});  
}
	MenuPanelopen3 = () => 
{	

this.setState({menutab3:!this.state.menutab3}, () => {
	
if(this.state.menutab3===false )
	   {this.setState({studclass:"active",masterclass:"",testclass:"",reprtclass:""});
		  
	   }else{
		   this.setState({studclass:"",masterclass:"",testclass:"",reprtclass:""});
	   }
});  
}
handleprofileClose = (event) => 
{	
	this.setState({anchorEl: !this.state.anchorEl,open: !this.state.anchorEl});
}
getNavLinkClass = (path) => {
	 
	let strurl=window.location.href.split('#');
	


		
   return strurl[1] === path ? 'active' : '';
	
 }
 OtherRemainder()
  {
	  var self=this;
	axios.get(Serverurl+'ajaxstudentregistration.php?action=notifyalert')
					   .then(resp => {
								
						    setTimeout(function(){self.OtherRemainder(); }, 2700000);
							})	
  
  }

	render() {
		
	console.log(1)
const { match, location, history } = this.props;



		return (
			<React.Fragment>					
				<div className="adminpanel">
					<div className="innerrow">
						<div className="innerleft-col">
							<div className="innerlogo">
								<img src={logo}/>
							</div>
							
							<div className="adminmenu">
								<ul>
								
								<li  className={this.getNavLinkClass("/Dashboard")}><NavLink  to="/Dashboard"  ><DashboardOutlinedIcon className="iconvertical"/> Dashboard</NavLink ></li>
									
									
									{localStorage.getItem("MMCrole")!=="1" && (<React.Fragment>
									<li  onClick={()=>this.MenuPanelopen3()} ><a  className={localStorage.getItem("pageactive")==="2" ? "active" : ""} ><SchoolOutlinedIcon className="iconvertical"/> Admission/ Readmission 
									{(this.state.menutab3===true || localStorage.getItem("pageactive")==="2") && (
									<ul  id="mas">
									
									<li ><NavLink  to="/StudentRegistration" > Registration</NavLink></li>
									<li ><NavLink to="/Studentadmission" > Admission</NavLink></li>
									<li ><NavLink to="/StudentFees" > Fee Payments</NavLink></li>
									</ul>)}
									</a>
									</li>
									</React.Fragment>	)}
								
									 {localStorage.getItem("MMCrole")==="1" && (<React.Fragment>
	 
		 
									<li  onClick={()=>this.MenuPanelopen()}><a className={localStorage.getItem("pageactive")==="3" ? "active" : ""}><CopyAllOutlinedIcon className="iconvertical"/> Masters 
										{(this.state.menutab===true || localStorage.getItem("pageactive")==="3") && (
									<ul>
									
									<li ><NavLink to="/Trustclassandyear" > Trust/Class/Year</NavLink></li>
								<li ><NavLink to="/Batch" > Batch</NavLink></li>
								<li ><NavLink to="/Branch" > Branch</NavLink></li>
								<li ><NavLink to="/Subjects" > Subjects</NavLink></li>
								
									
									</ul>)}
									</a>
									</li>
									
									
									<li className={this.getNavLinkClass("/User")} ><NavLink to="/User"><ManageAccountsIcon className="iconvertical"/> Users </NavLink></li>
									
								</React.Fragment>	)}
									 {localStorage.getItem("MMCrole")!=="1" && (<React.Fragment>
									<li  onClick={()=>this.MenuPanelopen1()}><a className={localStorage.getItem("pageactive")==="5" ? "active" : ""}><QuizIcon className="iconvertical"/>  Test 
										{(this.state.menutab1===true || localStorage.getItem("pageactive")==="5") && (<ul>
									
									<li><NavLink to="/AdminTest">Create  Test </NavLink></li>
									<li><NavLink to="/UploadTestResults">Upload Test Results </NavLink></li>
									<li><NavLink to="/ImportStudentsMarks">Import Bulk Results </NavLink></li>
									</ul>)}
									
									</a>
									</li>
									</React.Fragment>)}
								 {localStorage.getItem("MMCrole")==="1" && (<React.Fragment>
									
									<li> <NavLink to="/AdminTest"><QuizIcon className="iconvertical"/> Create  Test </NavLink></li>
									</React.Fragment>)}
									 {localStorage.getItem("MMCrole")!=="1" && (<React.Fragment>
									<li className={this.getNavLinkClass("/AttendanceImport")} ><NavLink to="/AttendanceImport"><CopyAllOutlinedIcon className="iconvertical"/> Import Attendance </NavLink></li>
																
									<li className={this.getNavLinkClass("/BulkAttendance")} ><NavLink to="/BulkAttendance"><CoPresentIcon className="iconvertical"/> Class-wise Attendance </NavLink></li>
										
									</React.Fragment>	)}
									
									<li className={this.getNavLinkClass("/NotificationCalendar")}><NavLink to="/NotificationCalendar"><NotificationsActiveIcon className="iconvertical"/> Notifications </NavLink></li>
									
									
									<li className={this.getNavLinkClass("/Downloads?type=2")}><NavLink to="/Downloads?type=2"><GetAppIcon className="iconvertical"/> Downloads </NavLink></li>
									
									
									<li className={this.getNavLinkClass("/FeeRemainder?type=3")}><NavLink to="/FeeRemainder?type=3"><CurrencyRupeeIcon className="iconvertical"/> Fee Remainder </NavLink></li>
									
									<li   onClick={()=>this.MenuPanelopen2()}><a className={localStorage.getItem("pageactive")==="10" ? "active" : ""}><CreditScoreIcon className="iconvertical"/>  Reports 
									{(this.state.menutab2===true || localStorage.getItem("pageactive")==="10") && (<ul>
									
									<li><NavLink to="/FeepaymentReport">Fee Payments </NavLink></li>
									 {localStorage.getItem("MMCrole")==="1" && (<React.Fragment>
									<li><NavLink to="/FeepaymentOtherReport">Other Payments </NavLink></li>
									 <li><NavLink to="/RefundReport">Students Refund Report </NavLink></li>
									 </React.Fragment>
									 )}
									
									<li><NavLink to="/Studentmanagement">Students Management </NavLink></li>
									<li><NavLink to="/StudentregistrationReport">Students Report </NavLink></li>
									<li><NavLink to="/StudentnotAdmissionlist">Student's Not Admitted </NavLink></li>
									<li><NavLink to="/Printallstudents">Print Performance </NavLink></li>
									
									<li><NavLink to="/TestReport">Batch Wise Test Report </NavLink></li>
									</ul>)}
									</a>
									
									</li>
								</ul>
							</div>
							
						</div>
						<div className="contentsecinner-col">
							
								<div className="toploginoptiondiv">
								<ul>
									<li>
									<p>Welcome</p>
									<h3>{localStorage.getItem("MMCUsername")}</h3>
									</li>
									<li><SettingsIcon className="iconcolor"  onClick={this.handleprofileClose}/>
									
									<Menu
        anchorEl={this.state.anchorEl}
        id="account-menu"
        open={this.state.open}
        onClose={this.handleprofileClose}
        onClick={this.handleprofileClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,top:'70px!important',
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 50,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
       
        
        
        
        <MenuItem onClick={this.handleprofileClose}>
         <a href="#/Changepassword">  <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Change Password</a>
        </MenuItem>
        <MenuItem onClick={this.handleprofileClose}>
         <a href="#/Logout"> <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout</a>
        </MenuItem>
      </Menu>
									</li>
								</ul>
								
								 
								</div>
							<Outlet />
						</div>
					</div>
				</div>
				
		    </React.Fragment>
		);
	}
}
//const ShowTheLocationWithRouter = withRouter(Layouthead);
export default Layouthead;
