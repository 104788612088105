
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { getMessaging, getToken } from "firebase/messaging";

export const initializeFirebase = () => {
  firebase.initializeApp({
     apiKey: "AIzaSyBB6RTqvsH6GA9-XRh6JtLp9uPCLpv88nY",
    authDomain: "AIzaSyBB6RTqvsH6GA9-XRh6JtLp9uPCLpv88nY",
       databaseURL: 'https://mm-coaching-center-e6ff9.firebaseio.com',
    projectId: "mm-coaching-center-e6ff9",
    storageBucket: "mm-coaching-center-e6ff9.appspot.com",
    messagingSenderId: "467280419696",
    appId: "1:467280419696:web:2e6b89f813f52cc9ca0db5",
    measurementId: "G-HNXSBYG0KT"
  });
}
export const askForPermissioToReceiveNotifications = async () => {
  try {
	  const messaging = getMessaging();
    return getToken(messaging, { vapidKey: "BNCyR4VgBH1rFGRhc88HZ-neYDsmAm60SHrmxfQBF2xMeS_vOyxmOHrm_1bEnqrR16vKXDqaGvIKxgjNIsJDu-s" })
    .then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        localStorage.setItem("MMCtoken",currentToken);
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
  } catch (error) {
    console.error(error);
  }
}
