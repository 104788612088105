import React ,{Component} from 'react'; 
import { Navigate  } from 'react-router-dom';
import axios from 'axios'; 
import Table from '@mui/material/Table';
import Serverurl from './Apiurl';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow'; 
import { makeStyles } from '@mui/styles'; 
import Paper from '@mui/material/Paper';  
import TableSortLabel from '@mui/material/TableSortLabel';
import TableBody from '@mui/material/TableBody'; 
import TableCell from '@mui/material/TableCell';  
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';  
import TablePagination from '@material-ui/core/TablePagination'; 

import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';

import DialogContent from '@mui/material/DialogContent';

import DialogTitle from '@mui/material/DialogTitle';

import Select from '@mui/material/Select';

import FormControl from '@mui/material/FormControl';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import pageimg from './images/attendance.png';


const headCells = [


  
  
  { id: 'bc_title', numeric: false, label: 'Title' },
  { id: 'tc_title', numeric: false, label: 'Class' },
  { id: 'syllabustype', numeric: false, label: 'Syllabus Type' },
  { id: 'br_title', numeric: false, label: 'Branch' },
  { id: '',  label: '' },
  
  ]
 function stableSort(array, cmp) {
	  const stabilizedThis = array.map((el, index) => [el, index]);
	  stabilizedThis.sort((a, b) => {
		const order = cmp(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	  });
	  return stabilizedThis.map(el => el[0]);
}
function desc(a, b, orderBy) {
	  if (b[orderBy] < a[orderBy]) {
		return -1;
	  }
	  if (b[orderBy] > a[orderBy]) {
		return 1;
	  }
	  return 0;
}
function getSorting(order, orderBy) {
	return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}
 
function EnhancedTableHead(props) {
  const { classes,  order, orderBy,  rowCount, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={useStyles.visuallyHidden}>
                  {order === 'desc' ? ' ' : ' '}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
 
  onRequestSort: PropTypes.func.isRequired,
 
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  
  
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  };

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 
 icon: {
   verticalalign:'middle',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
});


export default class Batchmaster extends Component {
	
    constructor(props) {
    super(props);
   
      this.state = {redirect: false,
      batchdata:[],bcid:'',page: 0 ,prevpage:0,totaldata:0,rowsPerPage:50,rowsPaging:[],Branchdata:[],branchid:'',branchlabel:'',
	 
	  order:'desc' ,orderBy:'',titles:'',syllabus:'',classesarray:[],classes:'',rolelabel:'Select Class',searchcustomer:'',
	          
      }
       this.updateDonenewParent = this.updateDonenewParent;
	   
	}
	
	updateDonenewParent = (text) => {this.componentDidMount();  }
	
	Search(){
	this.setState({  
      page: 0,
      rowsPerPage:50,
	  prevpage:0,Batchdata:[]
    });
	
//this.componentDidMount();  
}
	componentWillMount() { 
localStorage.setItem("pageactive",3)
}
componentDidUpdate() {
	
  if(this.state.prevpage !== this.state.page) {
    this.setState({   prevpage: this.state.page});
  this.apicall(this.state.page, localStorage.getItem("page"));  
  }
}




	componentDidMount() {
	


	 
		
	
	
	document.title = "MM Coching Center - Batch Management";
	
	if(localStorage.getItem("MMCuserid")==null)
		  {

		   this.setState({
            redirect: true,
			pagename:'/Login'
          })
		  }
	
	
	
	  localStorage.setItem("page",this.state.rowsPerPage)
  this.apicall(this.state.page,this.state.rowsPerPage);
  this.onloadclassbind();
} 


 onloadclassbind(){

    axios.get(Serverurl+'ajaxtrustandclass.php?action=getall&tc_type=2')
	
    
      .then((response) => {    
     
      this.setState({  classesarray: response.data,  });
	  this.BranchBind();
      })   
    
      .then(data => {
        let roleFromApi = this.state.classesarray.map(classes => {
                            
          return {value: classes.tc_id, display:classes.tc_title}
        });
      
		
        this.setState({
          classesarray: [{value: '', display: 'Select Class'}].concat(roleFromApi)
        });
      })


} 
BranchBind()
{
	axios.get(Serverurl+'ajaxbranch.php?action=getall')
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
						Branchdata:resp.data	
							}) 
			}
	 
	   }) .then(data => {
        let branchFromApi = this.state.Branchdata.map(row => {
                          
          return {value: row.br_id, display:row.br_title}
        });
      
		
        this.setState({
          Branchdata: [{value: '0', display: 'Select Branch'}].concat(branchFromApi)
        });
      })

} 

handleClickSearch=()=>{   
 
  
  this.apicall(this.state.page,this.state.rowsPerPage); 

	
} 

myChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
  this.setState({[nam]: val});
 
}

apicall(page,rowsPerPage){
	 
	 axios.get(Serverurl+'ajaxbatch.php?action=list&bc_title='+this.state.titles+'&bc_class_id='+this.state.classes+'&bc_br_id='+this.state.branchid+'&bc_syllbustype='+this.state.syllabus+'&StartIndex='+page+'&PageSize='+rowsPerPage)
     
	
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  batchdata: resp.data,  
			   totaldata:parseInt(resp.data[0].count),
			   

		    });
		
			
			let copy=[50, 100, 150, 200, 250];
			
	  
	  this.setState({rowsPaging:copy});
	 
     }
	 else{
		   this.setState({batchdata:[],totaldata:0});
	 }
 
    });
}





handleChangePage = (event, newPage) => {  
   
    let pageval = localStorage.getItem("page");
    this.setState({  
      page: newPage,
      rowsPerPage:parseInt(pageval)
    });
   
 
    setTimeout(() =>  this.componentDidUpdate() ,500); 
  };  
  
  
   handleChangeRowsPerPage = event => {  
  
    let pageval = event.target.value;
    localStorage.setItem("page",pageval)

    setTimeout(() =>  this.componentDidUpdate() ,500);
    this.setState({  
      page: 0,
      rowsPerPage:parseInt(pageval),
	  prevpage:-1
    }); 
	
	
  }; 
  
  
  handleRequestSort = (event, property) => {
  const isAsc = this.state.orderBy === property && this.state.order === 'asc';
  

  this.setState({   orderBy: property,
    order:isAsc  ? 'desc' : 'asc'
   
  });
};


      batchDelete(bcid){  
	
let r = window.confirm("Do you really want to Delete?");
 if(r === true){ //sign Out logic
 var formData = new FormData();
     formData.append('action','delete')
	 formData.append('bc_id',bcid)
	 	   
	formData.append('userid',localStorage.getItem("MMCuserid"))
	
	
	 axios.post(Serverurl+"ajaxbatch.php", formData, {
        }).then(res => {
    
             this.setState({  
      prevpage: -1,
    
    });
   
 
    setTimeout(() =>  this.componentDidUpdate() ,500); 
  
    
            });
 }
      }
      Batchaddbox(val){
        this.setState({ bcid: val });  
  
         setTimeout(() => this.refs.AddBatchbox.handleClickOpen(),500);

      }

render() {

if (this.state.redirect === true) {
      return <Navigate to={this.state.pagename} />
    }


  return (
  
  
  



 
  <React.Fragment>
   
<div className="containermain">
<div className="boxheading">
				  <div className="contntpad">
<h2><img src={pageimg}/>Batch Management</h2>
</div></div>
<div className="searchmaster">


  
  <ul>
  
 <li>
 <p ><TextField
            variant="outlined"
            margin="normal"
            fullWidth value={this.state.titles}
           inputProps={{tabIndex: "1",maxLength:150}}
			placeholder="Title"
            name="titles" onChange={this.myChangeHandler}
		
          /></p></li>
		
		<li>
	

	
		<p><FormControl  className="stadrp">
         
        <Select
         native  fullWidth
		 
		 
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.rolelabel, value: this.state.classes }}
          onChange={this.myChangeHandler}
          id="classes"
		   value={this.state.classes}
		  name="classes"
		
		
        >
		{this.state.classesarray.map(classes => (
            <option  
            
              value={classes.value}
            >
              {classes.display}
            </option  >
          ))}
		
        </Select></FormControl>
		
				</p></li>
				<li>
				
				
	

	
		<p><FormControl  className="stadrp">
          <Select
         native  fullWidth
		 
		 
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.branchlabel, value: this.state.branchid }}
          onChange={this.myChangeHandler}
          id="branchid"
		   value={this.state.branchid}
		  name="branchid"
		
		
        >
		{this.state.Branchdata.map(brrows => (
            <option  
            
              value={brrows.value}
            >
              {brrows.display}
            </option  >
          ))}
		
        </Select>
       </FormControl>
		
				</p>
				</li>
			<li>	
	
	<p>
		<FormControl  className="stadrp">			   
		<Select
		variant="outlined"
		native
		value={this.state.syllabus}
		onChange={this.myChangeHandler}
		name='syllabus'
		id='syllabus'
		InputLabelProps={{
		shrink: true,
		}}
		>
		<option value={0}>Select Syllabus</option>
		<option value={1}>CBSE</option>
		<option value={2}>State</option><option value={3}>PUC</option>			
		</Select>
		</FormControl>
	</p>
	</li>
	<li><p>Color Indicator</p>
	 <ul className="grphcolorindication">
	  <li><span></span> #c0a2ec</li>
	   <li><span></span> #00dc85</li>
	    <li><span></span> #00c9d9</li>
		  <li><span></span> #2592fb</li>
		  <li><span></span> #fc595a</li> 
		  <li><span></span> #8b8b8b</li>
	  </ul>
	</li>
	</ul>
		<div className="searchnewbtn">
		<p >
		<Button variant="contained"  className="frmPrimarybtn" onClick={this.handleClickSearch} endIcon={<SearchIcon />}>
        Search
      </Button>	&nbsp;<Button variant="contained" onClick={()=>{this.Batchaddbox(0)}} className="frmsecondarybtn" endIcon={<AddIcon />}>
        New
      </Button></p> 
		
		</div>
		
		
		
		
</div>		

   


<AddBatchbox ref="AddBatchbox" id={this.state.bcid}  updateDonenew={this.updateDonenewParent}/>
<Paper className={useStyles.root}>


  <TableContainer className={useStyles.container}>  
                <Table stickyHeader aria-label="sticky table">  
<TableHead> 
<TableRow>
                                              
                       
						
                      </TableRow></TableHead>
					  
					   <EnhancedTableHead           
              order={this.state.order}
              orderBy={this.state.orderBy}
              
              onRequestSort={this.handleRequestSort}
              rowCount={this.state.batchdata.length}
            />
                      <TableBody>
                     {
stableSort(this.state.batchdata, 
			getSorting(this.state.order,
			this.state.orderBy)).map((item,index) => {  
           return (
 <React.Fragment>
 <TableRow key={index} >



<TableCell>{item.bc_title}</TableCell>
<TableCell>{item.tc_title}</TableCell>
<TableCell>{item.syllabustype}</TableCell><TableCell>{item.br_title}</TableCell>

<TableCell align="left">
<div className="actionbtns">



<Button variant="contained"  className="frmPrimarybtn" onClick={()=>{this.Batchaddbox(item.bc_id)}} endIcon={<EditIcon />}>
Edit
</Button>
 

{item.batchcnt==="0" && (


 <Button variant="contained" className="admsndisablebtn" onClick={()=>{this.batchDelete(item.bc_id)}} endIcon={<DeleteIcon />}>
  Delete
</Button>
 
)}

 </div>
 
 </TableCell>
 </TableRow>

 </React.Fragment>	
);
					})}
					
					{this.state.totaldata===0 &&(
					
					<TableRow key={0} >
<TableCell align="center" colSpan="4">No Records</TableCell>
					</TableRow>
					) }
					
                      </TableBody>


</Table>
  </TableContainer> 
  <TablePagination  
        rowsPerPageOptions={[50, 100, 150, 200, 250]}  
        component="div"  
        count={this.state.totaldata}  
        rowsPerPage={this.state.rowsPerPage}  
        page={this.state.page}  
        onPageChange={this.handleChangePage}  
        onChangeRowsPerPage={this.handleChangeRowsPerPage}  
      />   
  </Paper>
  </div>	  

 </React.Fragment> 

 
 
  );
}
}


export  class  AddBatchbox extends Component {

   constructor(props) {
      super(props)
	  
      this.state = {
		  
		   open:false,title:'',bc_id:0,classesarray:[],classid:'',rolelabel:'Select Class',syllabustype:'',
      errormessage:'',erropen:false,Brancharr:[],branch:'',branchlabel:'Select Branch'
	  }
      
  }
  
  
  
 onloadclassbind(){

    axios.get(Serverurl+'ajaxtrustandclass.php?action=getall&tc_type=2')
	
    
      .then((response) => {    
     
      this.setState({  classesarray: response.data,  });
	  
	  this.BranchBind();
      })   
    
      .then(data => {
        let roleFromApi = this.state.classesarray.map(classes => {
                            
          return {value: classes.tc_id, display:classes.tc_title}
        });
      
		
        this.setState({
          classesarray: [{value: '0', display: 'Select Class'}].concat(roleFromApi)
        });
      })
  
  
  }
  
  
 Batchgetdata() {
	
  if(this.props.id!==0)
  {
	  
	   axios.get(Serverurl+'ajaxbatch.php?action=list&bc_id='+this.props.id)
  .then(resp => {  
		
    if(resp.data.length>0){
		
    this.setState({ 
     
      bc_id:resp.data[0].bc_id,
	  title: resp.data[0].bc_title,
      classes: resp.data[0].bc_class_id,
 branch:resp.data[0].bc_br_id,
colors: resp.data[0].bc_color, 
      syllabustype:resp.data[0].bc_syllabustype, 
      
    
    });
	
  }
  
});
	  
  }else{
	 this.setState({  
	  title:'',
	 branch:'',
	 classes:'',
	 syllabustype:'',
	 bc_id:0,
	 colors:''
	 
	  }); 
 }
  
    }

 BranchBind()
{
	axios.get(Serverurl+'ajaxbranch.php?action=getall')
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
						Brancharr:resp.data	
							}) 
			}
	 
	   }) .then(data => {
        let branchFromApi = this.state.Brancharr.map(row => {
                          
          return {value: row.br_id, display:row.br_title}
        });
      
		
        this.setState({
          Brancharr: [{value: '0', display: 'Select Branch'}].concat(branchFromApi)
        });
      })

}  
handleClickOpen = (e) => {
 
  this.setState({open:!this.state.open,  title:'', 
     errormessage:'',erropen:false,
	  });
		this.Batchgetdata();
		this.onloadclassbind();


};
myChangeHandler = (event) => {
	
	  const re = /^[0-9\b]+$/;
  let nam = event.target.name;
  let val = event.target.value;  
 
  this.setState({[nam]: val});
  

}

 handleErrorclose=(event)=>{
	  
	this.setState({open: false});  
  }


handleClose = (e) => {
	
	
 let title = this.state.title;
 let classes = this.state.classes;
 let syllabustype = this.state.syllabustype;
  let colors = this.state.colors;
   let branch = this.state.branch;

if(title==="")
{
	
	this.setState({errormessage:"Please Enter Title",erropen:true});
		 window.scrollTo({
          top: 0
      })
		 return false;
	
	
}

if(classes==="")
{
	
	this.setState({errormessage:"Please Select Class ",erropen:true});
		 window.scrollTo({
          top: 0
      })
		 return false;
	
	
}
if(branch==="0" || branch==="")
{
	
	this.setState({errormessage:"Please Select Branch ",erropen:true});
		 window.scrollTo({
          top: 0
      })
		 return false;
	
	
}

if(syllabustype==="")
{
	
	this.setState({errormessage:"Please Select Syllabus Type",erropen:true});
		 window.scrollTo({
          top: 0
      })
		 return false;
	
	
}

if(colors==="")
{
	
	this.setState({errormessage:"Please Select Color",erropen:true});
		 window.scrollTo({
          top: 0
      })
		 return false;
	
	
}
   var formData = new FormData();
  
  
   if(this.state.bc_id===0)
	  {
		  formData.append('bc_id',0) 
		  
	  }
	  else
	  {
		  
	   formData.append('bc_id',this.state.bc_id)
		
          }
	  
	  
	  
	  
	 
        formData.append('action','insertorupdate') 
		
		formData.append('bc_title',title)
		formData.append('bc_class_id',classes)
		formData.append('bc_syllabustype',syllabustype)
		formData.append('bc_color',colors)
		formData.append('bc_br_id',branch)
		
		formData.append('userid',localStorage.getItem("MMCuserid"))
		  
		axios.post(Serverurl+"ajaxbatch.php", formData, {
        }).then(res => {
    
	
	
	if(res.data === -1)
{ 
	 

		this.setState({errormessage:"Title Already Exist.",erropen:true,open:true
		});
		window.scrollTo({
          top: 0
      })
		 return false;

	}
	
	
            this.props.updateDonenew();
    
        });
  
	
	this.setState({ open:false });
};

	 handleErrorclose=(event)=>{
	  
	this.setState({erropen: false});  
  } 

	


render (){

return(
<React.Fragment>





    <Dialog open={this.state.open} maxWidth="xs" fullWidth="true" onClose={this.handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title"> Batch Management</DialogTitle>
      <DialogContent>
	  
<div className="formlist">

	<p>  <Collapse in={this.state.erropen}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
               this.handleErrorclose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          } severity="error"
        >
        {this.state.errormessage}
        </Alert>
      </Collapse></p>
						  
						  
	<p> <span className="validation">*</span> Title</p>
	
	<p><TextField name="title" 
	   onChange={this.myChangeHandler} 
	   fullWidth
	   value={this.state.title}
			 
			  ref={this.usernameInput}
			  inputProps={{tabIndex: "1",maxLength:150}} 
	   InputLabelProps={{ shrink: true }}
                          /></p>
						  <p> <span className="validation">*</span> Class</p>
	

	
		<p><FormControl  className="stadrp">
         
        <Select
         native  fullWidth
		 
		 
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.rolelabel, value: this.state.classes }}
          onChange={this.myChangeHandler}
          id="classes"
		   value={this.state.classes}
		  name="classes"
		
		
        >
		{this.state.classesarray.map(classes => (
            <option  
            
              value={classes.value}
            >
              {classes.display}
            </option  >
          ))}
		
        </Select></FormControl>
		
				</p>
		<p> <span className="validation">*</span> Branch</p>
	

	
		<p><FormControl  className="stadrp">
          <Select
         native  fullWidth
		 
		 
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.branchlabel, value: this.state.branch }}
          onChange={this.myChangeHandler}
          id="branch"
		   value={this.state.branch}
		  name="branch"
		
		
        >
		{this.state.Brancharr.map(brrows => (
            <option  
            
              value={brrows.value}
            >
              {brrows.display}
            </option  >
          ))}
		
        </Select>
       </FormControl>
		
				</p>
				
 <p><span className="validation">*</span> Syallabus Type</p>
		<p><FormControl  className="stadrp">			   
		<Select
		variant="outlined"
		native
		value={this.state.syllabustype}
		onChange={this.myChangeHandler}
		name='syllabustype'
		id='syllabustype'
		InputLabelProps={{
		shrink: true,
		}}
		>
		<option value={0}>Select</option>
		<option value={1}>CBSE</option>
		<option value={2}>State</option><option value={3}>PUC</option>			
		</Select>
		</FormControl></p>
		 <p><span className="validation">*</span> Color</p>
		<p><FormControl  className="stadrp">			   
		<Select
		variant="outlined"
		native
		value={this.state.colors}
		onChange={this.myChangeHandler}
		name='colors'
		id='colors'
		InputLabelProps={{
		shrink: true,
		}}
		>
		<option value={0}>Select</option>
		<option value="#c0a2ec">#c0a2ec</option>
		<option value="#00dc85">#00dc85</option>	
		<option value="#00c9d9">#00c9d9</option>
		<option value="#2592fb">#2592fb</option>
<option value="#fc595a"> #fc595a</option><option value="#8b8b8b">#8b8b8b</option>		
		</Select>
		</FormControl></p>
       <div className="actionbtns">
<div className="cancelbtn">	  
        <Button onClick={this.handleClickOpen} color="secondary" >
          Cancel
        </Button></div> 
<div className="savebtn">
        <Button onClick={this.handleClose} color="secondary" >
          Save
        </Button>
		</div>

 <div className="clear"></div>



 </div>     
 </div> 			 
						    </DialogContent>
      
    </Dialog>
</React.Fragment>

) }
 
} 
  