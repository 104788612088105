import React, { Component } from "react";
import { Navigate  } from 'react-router-dom';
import axios from 'axios'; 
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import SearchIcon from '@mui/icons-material/Search';
import Serverurl from './Apiurl';
import Button from '@mui/material/Button';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@mui/material/IconButton';
import prof from './images/test-result.png';
import { DropzoneArea } from 'material-ui-dropzone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
export default class AttendanceImport extends Component {
	
	constructor(props) 
	  {super();
		 this.state = {redirect:false,pagename:'',errormessage:'',open:false,imgfile:'',primarybtntext:'Upload',
		 
		 testarr:[],testlabel:'Select Test',testid:'0',Yeararr:[],yearid:'',yearlabel:'Select Year',
			Batcharr:[],batchlabel:'Select Batch',batchid:'0',classid:'0',classlabel:'Select Class',subjectlabel:'Select Subject',subjectarr:[],
			subjectid:'0',Classarr:[],studentsdata:[],Brancharr:[],branchid:'',secondarytext:'Save',teststatus:false,sylabus:'2',
		 }
	  }
			componentWillMount() { 
localStorage.setItem("pageactive",6)
}
 	componentDidMount()
	{
		if(localStorage.getItem("MMCuserid")===null)
		{
			this.setState({redirect: true,
							pagename:'/Login'}) 
		}
		this.ClassBind();
		 if(localStorage.getItem("MMCrole")==="1")
	 
		  { this.BranchBind();
		  }
		  else{
			  this.setState({branchid: localStorage.getItem("MMCbranchid")}) 
			
		  }
	}
	statusChange=(event)=>{
	  
	this.setState({teststatus: !this.state.teststatus});  
  }
  
  	
myChangeHandler = (event) => {
		
		const re = /^[0-9\b]+$/;
  let nam = event.target.name;
  let val = event.target.value;

	  
	   this.setState({[nam]: val}, () => {
if(nam==="batchid")
	   {
		   
 	
	
	   }
	if(nam==="classid")
	   {
		 this.BatchBind();   
 this.TestApibind();
	
	   }  
}); 
	   
	   	
}
handleSyllabusChange = (event) => {
 
	 
  let nam = event.target.name;
  let val = event.target.value;

	  
	   this.setState({[nam]: val}, () => {
if(nam==="sylabus" && this.state.classid!=="0")
	   {
		this.BatchBind();      
 
	   }
});  
}
ClassBind()
{
	axios.get(Serverurl+'ajaxtrustandclass.php?action=getall&tc_type=2')
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
						Classarr:resp.data	
							}) 
			}
		
	   }) .then(data => {
        let classFromApi = this.state.Classarr.map(row => {
                          
          return {value: row.tc_id, display:row.tc_title}
        });
      
		
        this.setState({
          Classarr: [{value: '0', display: 'Select Class'}].concat(classFromApi)
        });
      })

}
BatchBind()
{
	axios.get(Serverurl+'ajaxbatch.php?action=list&bc_class_id='+this.state.classid+'&bc_syllbustype='+this.state.sylabus+'&bc_br_id='+localStorage.getItem("MMCbranchid"))
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
						Batcharr:resp.data	
							}) 
			}else{
				
				this.setState({
						Batcharr:[]	
							}) 
			}
	
	   }).then(data => {
        let batchFromApi = this.state.Batcharr.map(row => {
                          
          return {value: row.bc_id, display:row.bc_title}
        });
      
		
        this.setState({
          Batcharr: [{value: '0', display: 'Select Batch'}].concat(batchFromApi)
        });
      })

}


BranchBind()
{
	axios.get(Serverurl+'ajaxbranch.php?action=getall')
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
						Brancharr:resp.data	
							}) 
			}
	 
	   }) .then(data => {
        let branchFromApi = this.state.Brancharr.map(row => {
                          
          return {value: row.br_id, display:row.br_title}
        });
      
		
        this.setState({
          Brancharr: [{value: '0', display: 'Select Branch'}].concat(branchFromApi)
        });
      })

}
	TestApibind()
	{
	let strbranch=localStorage.getItem("MMCbranchid");
	 if(localStorage.getItem("MMCrole")==="1")
	 
		  { 
			  strbranch=this.state.branchid;
		  }	
	axios.get(Serverurl+'ajaxtest.php?action=getall&classid='+this.state.classid+'&te_branch_id='+strbranch+'&te_yearid='+localStorage.getItem("MMCyearid"))
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
						testarr:resp.data	
							}) 
			}else{
				this.setState({
						testarr:[]	
							}) 
				
			}
		
	   }) .then(data => {
        let testFromApi = this.state.testarr.map(row => {
                          
          return {value: row.te_id, display:row.te_testname}
        });
      
		
        this.setState({
          testarr: [{value: '0', display: 'Select Test'}].concat(testFromApi)
        });
      })
	}
	
	handleErrorclose=(event)=>{
	  
	this.setState({open: false});  
  }
  OnImportSubmit=(event)=>{
	  
	   var formData = new FormData();
	 formData.append('action','marksupload')
	 	formData.append('yearid',localStorage.getItem("MMCyearid"))
	 formData.append('sm_te_id', this.state.testid )
	  formData.append('sm_class_id', this.state.classid )
	   formData.append('sm_batch_id', this.state.batchid )
	      formData.append('sm_branchid', localStorage.getItem("MMCbranchid") )
		   formData.append('Filedata', this.state.imgfile[0])
	formData.append('userid',localStorage.getItem("MMCuserid"))
	this.setState({primarybtntext:'Processing...'});
	axios.post(Serverurl+"ajaxstudentmarks.php", formData, {
	}).then(res => {
		
		this.setState({primarybtntext:'Upload',open:true,errormessage:'Uploaded successfully'});
		
	});
  }
	render() {
if (this.state.redirect === true) {
      return <Navigate to={this.state.pagename} />
    }
	 return (
  
	 <React.Fragment> 
	  <div className="pagebox-col">
	 <div className="">
			<div className="boxheading">
				  <div className="contntpad">
					 <p>  <Collapse in={this.state.open}>
										<Alert
										  action={
											<IconButton
											  aria-label="close"
											  color="inherit"
											  size="small"
											  onClick={() => {
											   this.handleErrorclose();
											  }}
											>
											  <CloseIcon fontSize="inherit" />
											</IconButton>
										  } severity="error"
										>
										{this.state.errormessage}
										</Alert>
								</Collapse></p>
				  <h2><img src={prof} /> Import Bulk Results</h2>
				  </div> </div>
	   <div className="contntpad ">
	   <div className="formview "> 
	
	
	  <ul> 
	  
	    <li><p><span className="validation">*</span>Class</p>
	  <p>
	  <FormControl fullWidth  size="small">
       
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.classlabel, value: this.state.classid }}
          onChange={this.myChangeHandler}
          id="state"
		   value={this.state.classid}
		  name="classid"
		
		
        >
		{this.state.Classarr.map(row => (
            <option  
            
              value={row.value}
            >
              {row.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
	  
	  </li>
	 
	
	   <li><p><span className="validation">*</span> Sylabus Type</p>
	 <p> <Radio
        checked={this.state.sylabus === '2'}
        onChange={this.handleSyllabusChange}
        value="2"
        name="sylabus"
        inputProps={{ 'aria-label': 'B' }}
      />State<Radio
        checked={this.state.sylabus === '1'}
        onChange={this.handleSyllabusChange}
        value="1"
        name="sylabus"
        inputProps={{ 'aria-label': 'A' }}
      />CBSE
	  <Radio
        checked={this.state.sylabus === '3'}
        onChange={this.handleSyllabusChange}
        value="3"
        name="sylabus"
        inputProps={{ 'aria-label': 'A' }}
      />PUC
      </p>

	  
	 </li>
	 
	 <li><p><span className="validation">*</span>Batch</p>
	  <p>
	  <FormControl  fullWidth size="small" >
       
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.batchlabel, value: this.state.batchid }}
          onChange={this.myChangeHandler}
          id="state"
		   value={this.state.batchid}
		  name="batchid"
		
		
        >
		{this.state.Batcharr.map(row => (
            <option  
            
              value={row.value}
            >
              {row.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
	  
	  </li><div className="clear"></div>
	
	  <li><p>Test</p>
	   <p>
	  <FormControl  fullWidth size="small" >
       
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.testlabel, value: this.state.testid }}
          onChange={this.myChangeHandler}
          id="state"
		   value={this.state.testid}
		  name="testid"
		
		
        >
		{this.state.testarr.map(row => (
            <option  
            
              value={row.value}
            >
              {row.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
	 
	 </li> <li><p>&nbsp;</p>
	  <p><a href={Serverurl+"uploads/importmarks.csv"} className="frmsecondarybtn" target="_blank">Download CSV File</a>
	</p>
	  </li>
	 </ul></div><div className="clear"></div>
	    <div className="formviewatt ">
	   
	
	 <p className="fileuploaddrop">
	   <DropzoneArea dropzoneText="" 
 showPreviews={true}
  showPreviewsInDropzone={false}
  useChipsForPreview
  previewGridProps={{container: { spacing: 1, direction: 'row' }}}
dropzoneText={"Drag and drop/Select CSV file here"}
  previewText="Uploaded files"
maxFileSize={5000000} Icon={CloudUploadIcon} dropzoneClass="filesupld" filesLimit={1} acceptedFiles={['text/csv']} 
 
 
  onChange={(files) => {
  var file, img;
  var _URL = window.URL || window.webkitURL;
  this.setState({ imgfile: files })
  
		
 

  }}
  
 
/></p>  <p className="alignright"><Button variant="standard" className="frmPrimarybtn" endIcon={<CheckRoundedIcon />} onClick={this.OnImportSubmit}>
								  {this.state.primarybtntext}
						</Button></p>
	   <div className="clear"></div>
	   </div>  </div>
	   </div>
	   </div>
	 </React.Fragment> 
	 )
	}
}








