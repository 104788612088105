import React, { Component } from "react";
import { BrowserRouter as Router, Route, Routes,NavLink,Outlet } from 'react-router-dom';
import styles from './styles/styles.css';
import logo from './images/weblogo.png';
	import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
	import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
export default class MobileLayout extends Component {
	
	constructor(props) {
    super(props);
	
	 this.state = {title:''}
	 
	}

	
		render() {
		
	
	
		return (
		<React.Fragment>
		
		
		
		<div className="contentbg">
			<div className="main">
				<Outlet />
			</div>
		
		</div>
			<footer className="footer">
			<div className="footerfix"><div className="mobmain">
		<ul>
		<li>
		<a href="#/MobileNotification">
		<NotificationsNoneOutlinedIcon /><p>Notifications</p></a></li>
		<li><a href="#/Mobiledownloads"><CloudDownloadOutlinedIcon /><p>Downloads</p></a></li>
		<li><a href="#/MobileMyprofile"><PersonOutlineOutlinedIcon /><p>Profile</p></a></li>
		<li><a href="#/MobLogout"><LogoutOutlinedIcon /><p>Logout</p></a></li>
		</ul>
			</div></div></footer >
		
		</React.Fragment>
		)
		
		}
}









