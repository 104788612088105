import React, { Component } from "react";
import { Navigate  } from 'react-router-dom';
import Serverurl from './Apiurl';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import registrationhead from './images/registrationhead.png';
import Radio from '@mui/material/Radio';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import ArrowRightAltRoundedIcon from '@mui/icons-material/ArrowRightAltRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@mui/material/IconButton';
import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,DatePicker, 
  KeyboardDatePicker,
  DateTimePicker
} from '@material-ui/pickers';
import { addDays } from "date-fns";
import Moment from 'react-moment';
import moment from 'moment';
export default class StudentRegistration extends Component 
{
	constructor(props) 
	  {
			super();
			
			this.state = {name:'',errormessage:'',dob1:addDays(moment().toDate(),0),open:false,refno:'',address:'',phone:'',father:'',mother:'',whatsappno:'',email:'',alteremail:'',dob:addDays(moment().toDate(),0),profileimg:'',gender:'1',
			
			btndisabled:false,primarybtntext:'Submit',previocn:'',secondarybtntext:'Save and Continue',pagename:'',redirect:false}
	}
		componentWillMount() { 
localStorage.setItem("pageactive","2")
}
	 componentDidMount()
	{
		if(localStorage.getItem("MMCuserid")===null)
		{
			this.setState({redirect: true,
							pagename:'/Login'}) 
		}
		this.onloadGeneratestudCode();
		
	}
	onloadGeneratestudCode(){
		
		
			axios.get(Serverurl+'ajaxstudentregistration.php?action=Studcode&branchid='+localStorage.getItem("MMCbranchid"))
       .then(resp => { 		
			if(resp.data!=""){
				this.setState({
							refno: resp.data.trim(),
							
						  }) 
			}
			this.OnloadStudentGetbyParents();
			this.OnloadStudentEdit();
	   });
	}
	OnloadStudentEdit(){
		
		
	axios.get(Serverurl+'ajaxstudentregistration.php?action=list&sr_id='+localStorage.getItem("MMEdSrId"))
     
	
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({
							
							phone:resp.data[0]["pr_phonenumber"],
							
							name:resp.data[0]["sr_studentname"],
		phone:resp.data[0]["sr_phonenumber"],
		refno:resp.data[0]["sr_studentcode"],father:resp.data[0]["sr_fathername"],
		mother:resp.data[0]["sr_mothername"],
		whatsappno:resp.data[0]["sr_whatsappphoneno"],
		email:resp.data[0]["sr_emailid"],
			alteremail:resp.data[0]["sr_alternateemailid"],
			dob1:resp.data[0]["sr_createddate"],
			gender:resp.data[0]["sr_gender"],
			address:resp.data[0]["sr_address"],
			
			previocn:resp.data[0]["sr_profileimg"],
						  }) 
	}
  });
	}
	
	OnloadStudentGetbyParents()
{
	axios.get(Serverurl+'ajaxparent.php?action=getall&pr_id='+localStorage.getItem("MMCParentid"))
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
							
							phone:resp.data[0]["pr_phonenumber"],
						  }) 
			}
			
	   });

}
	
	myChangeHandler = (event) => {
		
		const re = /^[0-9\b]+$/;
  let nam = event.target.name;
  let val = event.target.value;
if(nam==="phone" || nam==="whatsappno"  )
  {if (event.target.value === '' || re.test(event.target.value)) {
	  this.setState({[nam]: val}); 
  }
  }
  else{
	  
	   this.setState({[nam]: val});
  }
} 
handlegenderChange = (event) => {
	let nam = event.target.name;
  let val = event.target.value;
	  this.setState({[nam]: val}); 
} 
	OnRegistrationSubmit= (event) => 
		{
		event.preventDefault();
		this.SubmitForm(1);
	
			
		
	} 
	OnRegistrationSave= (event) => 
		{
		event.preventDefault();
		this.SubmitForm(2);
	
			
		
	}
	
	
SubmitForm(type)
{
	
	 const remail= /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
		let name = this.state.name;
		let phone = this.state.phone;
		let refno = this.state.refno;let father = this.state.father;
		let mother = this.state.mother;
		let whatsappno = this.state.whatsappno;
		let email = this.state.email;
			let alteremail = this.state.alteremail;
			let dob1 = this.state.dob1;
			let gender = this.state.gender;
			let address = this.state.address;
			let profileimg = this.state.profileimg;
			refno=refno.replace(/\s+/g, '');
			if(refno===''){
						
				this.setState({errormessage:"Please Enter Ref No.",open:true});
		 window.scrollTo({        top: 0     })
	return false; 
				}
			if(name===''){
						
				this.setState({errormessage:"Please Enter Name",open:true});
		 window.scrollTo({        top: 0     })
	return false; 
				}if(phone===''){
						
				this.setState({errormessage:"Please Enter Phone No.",open:true});
		 window.scrollTo({        top: 0     })
	return false; 
				}
				if(father==='' && mother===''){
					this.setState({errormessage:"Please Enter Father/ Mother Name",open:true});
		 window.scrollTo({        top: 0     })
	return false;
				}
				
				
				if(whatsappno.length<10){
						
				this.setState({errormessage:"Please Enter Valid Whatsapp No.",open:true});
		 window.scrollTo({        top: 0     })
	return false; 
				}
				

  if(email!=='')	  
	{

						if (!remail.test(email)) {
				  this.setState({errormessage:"Please Enter Valid Email id",open:true});
				  window.scrollTo({
					  top: 0
				  })
				return false;
			  }else{
				   this.setState({errormessage:"",open:false}); 
			  }
		
	}if(alteremail!=='')	  
	{

						if (!remail.test(alteremail)) {
				  this.setState({errormessage:"Please Enter Valid Alternate  Email id",open:true});
				  window.scrollTo({
					  top: 0
				  })
				return false;
			  }else{
				   this.setState({errormessage:"",open:false}); 
			  }
		
	}
				if(dob1===''){
						
				this.setState({errormessage:"Please Enter Date of Birth",open:true});
		 window.scrollTo({        top: 0     })
	return false; 
				}
				if(gender===''){
						
				this.setState({errormessage:"Please Enter Gender",open:true});
		 window.scrollTo({        top: 0     })
	return false; 
				}if(address===''){
						
				this.setState({errormessage:"Please Enter Address",open:true});
		 window.scrollTo({        top: 0     })
	return false; 
				}
				if(type===1){
				this.setState({primarybtntext:'Processing...'});
				}else{
					this.setState({secondarybtntext:'Processing...'});
				}
				var formData = new FormData();
				if(localStorage.getItem("MMEdSrId")===null){
				formData.append('action','insert')
				}
else{
	formData.append('action','update')
formData.append('sr_id',localStorage.getItem("MMEdSrId"))
}				
 
				formData.append('sr_pr_id',localStorage.getItem("MMCParentid"))
				formData.append('sr_studentname',name)
						formData.append('sr_fathername',father)
	formData.append('sr_mothername',mother)
	formData.append('sr_address',address)
	formData.append('sr_phonenumber',phone)
		formData.append('sr_whatsappphoneno',whatsappno)
			formData.append('sr_emailid',email)
	formData.append('sr_alternateemailid',alteremail)
	formData.append('sr_dob',dob1)
	formData.append('sr_gender',gender)
	formData.append('sr_studentcode',refno)
		formData.append('userid',localStorage.getItem("MMCuserid"))
	if(this.state.profileimg!='')
	  {
		  
		for (const key of Object.keys(this.state.profileimg)) {
            formData.append('Filedata', this.state.profileimg[key])
        } 
 formData.append('sr_profileimg','1')		
	  }else
	  {
		 formData.append('sr_profileimg',this.state.previocn)	  
	  
	  }
	
				axios.post(Serverurl+"ajaxstudentregistration.php", formData, {
				}).then(res => {
					if(res.data!="")
					{
						
					let strreturn=res.data;
					strreturn=strreturn;
					localStorage.setItem("MMCSRId",strreturn);
					if(localStorage.getItem("MMEdSrId")===null){
						if(type===1){
					this.setState({primarybtntext:'Submit',redirect:true,pagename:'/Dashboard'});
					}else{
						this.setState({secondarybtntext:'Save and Continue',redirect:true,pagename:'/Studentadmission'});
					}
					}else{
						
						localStorage.setItem("MMCSRId",localStorage.getItem("MMEdSrId"));	
						localStorage.setItem("MMCsaid",strreturn);
						localStorage.removeItem("MMEdSrId");
						if(type===1){
								this.setState({primarybtntext:'Submit',redirect:true,pagename:'/StudentregistrationReport'});
								}else{
									
									this.setState({secondarybtntext:'Save and Continue',redirect:true,pagename:'/Studentadmission'});
								}
						//this.setState({primarybtntext:'Submit',redirect:true,pagename:'/StudentregistrationReport'});
					}
					 
					}
					
		})
}

	handfrChange = (date) => {
	 var month="",date1="";
	
	 if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)}
	 
	 
	  if((date.getDate() )<10)
	 {
		date1="0"+ (date.getDate() )		 
	 }else{date1=(date.getDate())}
     this.setState({ dob:date,
dob1:date.getFullYear() + '-' + month + '-' +date1
	 })
	
	
  }
	handleErrorclose=(event)=>{
	  
	this.setState({open: false});  
  }
  
  onFileChange=(e) =>{
	   	 
	   
	
	   let imageFile = e.target.files[0];
	 
	  
	   if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {

  this.setState({errormessage:"Please select valid file (jpg,jpeg,png).",open:true});
		 window.scrollTo({        top: 0     })
	
  
    return false;
  }else{
        this.setState({ profileimg: e.target.files })
  }
		
    }
  
  
	 render() {
if (this.state.redirect === true) {
      return <Navigate to={this.state.pagename} />
    }
	 return (
  
	 <React.Fragment> 	 <div className="pagebox-col">
	 <div className="formview">
	 	  <div className="boxheading">
	  <div className="contntpad">
	  	 <p>  <Collapse in={this.state.open}>
							<Alert
							  action={
								<IconButton
								  aria-label="close"
								  color="inherit"
								  size="small"
								  onClick={() => {
								   this.handleErrorclose();
								  }}
								>
								  <CloseIcon fontSize="inherit" />
								</IconButton>
							  } severity="error"
							>
							{this.state.errormessage}
							</Alert>
					</Collapse></p>
	  <h2><img src={registrationhead} /> Enter Student Details</h2>
	  </div> </div>
	   <div className="contntpad">
	 
	 
	 
	 <ul>
	  <li><p><span className="validation">*</span> Reg No.</p>
	 <p><TextField id="txtref" fullWidth type="text" disabled size="small"  placeholder="Ref No."  name="refno" value={this.state.refno}  inputProps={{tabIndex: "2",maxLength:10}} InputLabelProps={{ shrink: true }}  
	  onChange={this.myChangeHandler} /></p>	 
	 </li>
	 <li><p><span className="validation">*</span> Name</p>
	 <p><TextField id="txtName" fullWidth type="text" size="small"  placeholder="Name"  name="name" value={this.state.name}  inputProps={{tabIndex: "1",maxLength:150}} InputLabelProps={{ shrink: true }}  
	  onChange={this.myChangeHandler} /></p>	 
	 </li>
	 
	 	  <li><p><span className="validation">*</span> Phone No.</p>
	 <p><TextField id="txtPhone" fullWidth type="text" disabled size="small"  placeholder="Phone No."  name="phone" value={this.state.phone}  inputProps={{tabIndex: "3",maxLength:13}} InputLabelProps={{ shrink: true }}  
	  onChange={this.myChangeHandler} /></p>	 
	 </li>
	 
	  	  <li><p>Father Name</p>
	 <p><TextField id="txtfathername" fullWidth type="text" size="small"  placeholder="Father Name"  name="father" value={this.state.father}  inputProps={{tabIndex: "4",maxLength:150}} InputLabelProps={{ shrink: true }}  
	  onChange={this.myChangeHandler} /></p>	 
	 </li>
	 	  <li><p>Mother Name</p>
	 <p><TextField id="txtmothername" fullWidth type="text" size="small"  placeholder="Mother Name"  name="mother" value={this.state.mother}  inputProps={{tabIndex: "5",maxLength:150}} InputLabelProps={{ shrink: true }}  
	  onChange={this.myChangeHandler} /></p>	 
	 </li>
	   <li><p> Whatsapp No.</p>
	 <p><TextField id="txtwhatno" fullWidth type="text" size="small"  placeholder="Whatsapp No."  name="whatsappno" value={this.state.whatsappno}  inputProps={{tabIndex: "6",maxLength:13}} InputLabelProps={{ shrink: true }}  
	  onChange={this.myChangeHandler} /></p>	 
	 </li>
	    <li><p> Email id</p>
	 <p><TextField id="txtEmail" fullWidth type="text" size="small"  placeholder="Email id"  name="email" value={this.state.email}  inputProps={{tabIndex: "7",maxLength:150}} InputLabelProps={{ shrink: true }}  
	  onChange={this.myChangeHandler} /></p>	 
	 </li>
	  <li><p>Alternate Email id</p>
	 <p><TextField id="txtaltEmail" fullWidth type="text" size="small"  placeholder="Alternate Email id"  name="alteremail" value={this.state.alteremail}  inputProps={{tabIndex: "8",maxLength:150}} InputLabelProps={{ shrink: true }}  
	  onChange={this.myChangeHandler} /></p>	 
	 </li>
	   <li><p> Date of Birth</p>
	 <p>
		 	<MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-around">
	 
      <DatePicker fullWidth 
        value={this.state.dob || addDays(moment().toDate(),1)}
        onChange={this.handfrChange}
		maxDate={addDays(moment().toDate(),0)}
            format="dd/MM/yyyy"
        autoOk name="dob"
        ampm={false}
	id="borderdate"
      />
	   </Grid>
    </MuiPickersUtilsProvider>
	</p>	 
	 </li>
	 
	  <li><p><span className="validation">*</span> Gender</p>
	 <p> <Radio
        checked={this.state.gender === '1'}
        onChange={this.handlegenderChange}
        value="1"
        name="gender"
        inputProps={{ 'aria-label': 'A' }}
      />Male
      <Radio
        checked={this.state.gender === '2'}
        onChange={this.handlegenderChange}
        value="2"
        name="gender"
        inputProps={{ 'aria-label': 'B' }}
      />Female</p>	 
	 </li>   <li><p><span className="validation">*</span> Address</p>
	 <p><TextField id="txtadres" fullWidth type="text" size="small"  placeholder="Address"  name="address" rows={3} value={this.state.address}  inputProps={{tabIndex: "9"}}    multiline InputLabelProps={{ shrink: true }}  
	  onChange={this.myChangeHandler} /></p>	 
	 </li>
	 
	 <li><p>Profile Image</p>
	 <p className="upload-submitimg"><Button variant="standard" className="upldbtn" endIcon={<PhotoCamera />}>
  Upload
</Button>
<input type="file"  name="profileimg"   onChange={this.onFileChange}  accept="image/*;capture=camera" />
	<br/>{this.state.previocn!=="" &&(
	<p><img src={Serverurl+'uploads/'+this.state.previocn} target='_blank' style={{'width':'90px'}}/></p>
	
	)}
</p>	 
	 </li>
	 </ul>
	 <div className="clear"></div>
	 
	 <div className="formbtns">
	 
	 <ul>
	 <li>
	  <p><Button variant="standard" className="frmPrimarybtn" endIcon={<CheckRoundedIcon />} onClick={this.OnRegistrationSubmit}>
		  {this.state.primarybtntext}
</Button></p></li>


<li>
 <p><Button variant="standard" className="frmsecondarybtn" endIcon={<ArrowRightAltRoundedIcon />} onClick={this.OnRegistrationSave}>
    {this.state.secondarybtntext}
</Button></p></li></ul>
 <div className="clear"></div>
</div>
	  </div>
	 </div>
	  </div>
	  </React.Fragment> 
	  )
	 }
}






