import React ,{Component} from 'react'; 
import { Navigate  } from 'react-router-dom';
import axios from 'axios'; 
import Table from '@mui/material/Table';
import Serverurl from './Apiurl';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow'; 
import { makeStyles } from '@mui/styles'; 
import Paper from '@mui/material/Paper';  
import TableSortLabel from '@mui/material/TableSortLabel';
import TableBody from '@mui/material/TableBody'; 
import TableCell from '@mui/material/TableCell';  
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';  
import TablePagination from '@material-ui/core/TablePagination'; 

import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Select from '@mui/material/Select';


import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import pageimg from './images/attendance.png';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import NotificationPopUp from './NotificationPopUp';


const headCells = [


 
  
  
  ]
  function stableSort(array, cmp) {
	  const stabilizedThis = array.map((el, index) => [el, index]);
	  stabilizedThis.sort((a, b) => {
		const order = cmp(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	  });
	  return stabilizedThis.map(el => el[0]);
}
function desc(a, b, orderBy) {
	  if (b[orderBy] < a[orderBy]) {
		return -1;
	  }
	  if (b[orderBy] > a[orderBy]) {
		return 1;
	  }
	  return 0;
}
function getSorting(order, orderBy) {
	return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

function EnhancedTableHead(props) {
  const { classes,  order, orderBy,  rowCount, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={useStyles.visuallyHidden}>
                  {order === 'desc' ? ' ' : ' '}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
 
  onRequestSort: PropTypes.func.isRequired,
 
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  
  
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  };

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 
 icon: {
   verticalalign:'middle',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
});


export default class Downloads extends Component {
	
    constructor(props) {
    super(props);
   
      this.state = {redirect: false,classlabel:'class',batchlabel:'Batch',at_record_id:'',
      Downloadsdata:[],noid:'',page: 0 ,prevpage:0,totaldata:0,rowsPerPage:50,rowsPaging:[],Classarr:[],Batcharr:[],classid:'',batchid:'',
	 
	  order:'desc' ,orderBy:'',titles:'',type:2,types:'',searchcustomer:'',popuptype:'2',
	          
      }
       this.updateDonenewParent = this.updateDonenewParent;
	   
	}
	
	updateDonenewParent = (text) => {this.apicall(this.state.page,this.state.rowsPerPage);  }
	
	Search(){
	this.setState({  
      page: 0,
      rowsPerPage:50,
	  prevpage:0,Downloadsdata:[]
    });
	
//this.componentDidMount();  
}
 getParameterByName(name, url) {
    if (!url) url = window.location.href;
    url = url.toLowerCase(); 
    name = name.replace(/[\[\]]/g, "\\$&").toLowerCase();
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}	
componentDidUpdate() {
	 
  if(this.state.prevpage !== this.state.page) {
    this.setState({   prevpage: this.state.page});
  this.apicall(this.state.page, localStorage.getItem("page"));  
  }
}


		componentWillMount() { 

			let search = window.location.href;
let strtype = this.getParameterByName('type',search);


if(strtype=="2")
{
localStorage.setItem("pageactive",8)


}
if(strtype=="3")
{
localStorage.setItem("pageactive",9)} 

this.setState({ popuptype: strtype}, () => {
	
  localStorage.setItem("page",this.state.rowsPerPage)
  this.apicall(this.state.page,this.state.rowsPerPage);
});  
this.ClassBind();
	

}
componentWillReceiveProps(props) {
	
  this.setState({refresh : props.refresh });
	let moduletype=this.props.moduletype;
	
 this.setState({popuptype:moduletype});
	
	  localStorage.setItem("page",this.state.rowsPerPage)
  this.apicall(this.state.page,this.state.rowsPerPage);

	}
	

handleClickSearch=()=>{   
  
  this.apicall(this.state.page,this.state.rowsPerPage);	
} 

myChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
  this.setState({[nam]: val},()=>{
	  
	  if(nam==="classid")
	  {
		 this.BatchBind(); 
	  }
  });
 
}

apicall(page,rowsPerPage){
	 let struserid="";
	if(localStorage.getItem("MMCrole")!=="1")
	 
		  { struserid=localStorage.getItem("MMCuserid");
		  }
	 
      axios.get(Serverurl+'ajaxnotifications.php?action=list&class='+this.state.classid+'&no_title='+this.state.titles+'&no_type='+this.state.popuptype+'&no_optiontype='+this.state.types+'&batch='+this.state.batchid+'&StartIndex='+page+'&PageSize='+rowsPerPage+'&userid='+struserid)
	
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  Downloadsdata: resp.data,  
			   totaldata:parseInt(resp.data[0].count),
			   

		    });
		
			
			let copy=[50, 100, 150, 200, 250];
			
	  
	  this.setState({rowsPaging:copy});
	 
     }
	 else{
		   this.setState({Downloadsdata:[],totaldata:0});
	 }
 
    });
	
}





handleChangePage = (event, newPage) => {  
   
    let pageval = localStorage.getItem("page");
    this.setState({  
      page: newPage,
      rowsPerPage:parseInt(pageval)
    });
   
 
    setTimeout(() =>  this.componentDidUpdate() ,500); 
  };  
  
  
   handleChangeRowsPerPage = event => {  
  
    let pageval = event.target.value;
    localStorage.setItem("page",pageval)

    setTimeout(() =>  this.componentDidUpdate() ,500);
    this.setState({  
      page: 0,
      rowsPerPage:parseInt(pageval),
	  prevpage:-1
    }); 
	
	
  }; 
  
  
  handleRequestSort = (event, property) => {
  const isAsc = this.state.orderBy === property && this.state.order === 'asc';
  

  this.setState({   orderBy: property,
    order:isAsc  ? 'desc' : 'asc'
   
  });
};


      downloadsDelete(noid){  
	
let r = window.confirm("Do you really want to Delete?");
 if(r === true){
 var formData = new FormData();
     formData.append('action','delete')
	 formData.append('no_id',noid)   
	 formData.append('userid',localStorage.getItem("MMCuserid"))	
	
	
	 axios.post(Serverurl+"ajaxnotifications.php", formData, {
        }).then(res => {
    
             this.setState({  
      prevpage: -1,
    
    });
   
 
    setTimeout(() =>  this.componentDidUpdate() ,500); 
  
    
            });
 }
      }
      Downloadaddbox(val){
        this.setState({ noid: val });  
  
         setTimeout(() => this.refs.NotificationPopUp.handleClickOpen(),500);

      }






ClassBind()
{
	axios.get(Serverurl+'ajaxtrustandclass.php?action=getall&tc_type=2')
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
						Classarr:resp.data	
							}) 
			}
			
this.BatchBind();
	   }) .then(data => {
        let classFromApi = this.state.Classarr.map(row => {
                          
          return {value: row.tc_id, display:row.tc_title}
        });
      
		
        this.setState({
          Classarr: [{value: '0', display: 'Select Class'}].concat(classFromApi)
        });
      })

}


BatchBind()
{
	axios.get(Serverurl+'ajaxbatch.php?action=list&bc_class_id='+this.state.classid+'&bc_br_id='+localStorage.getItem("MMCbranchid"))
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
						Batcharr:resp.data	
							}) 
			}else{
				
				this.setState({
						Batcharr:[]	
							}) 
			}

	   }).then(data => {
        let batchFromApi = this.state.Batcharr.map(row => {
                          
          return {value: row.bc_id, display:row.bc_title}
        });
      
		
        this.setState({
          Batcharr: [{value: '0', display: 'Select Batch'}].concat(batchFromApi)
        });
      })

}


render() {

if (this.state.redirect === true) {
      return <Navigate to={this.state.pagename} />
    }
let search = window.location.href;
let strtype = this.getParameterByName('type',search);

  return (
  
  
  



 
  <React.Fragment>
   
<div className="containermain">
<div className="pageheader">
<h1><img src={pageimg}/>{this.props.moduletype==="2" ? "Downloads" : "Fee Remainder" }</h1>
</div>

   <div className="searchmaster">


  
  <ul>
  
 <li>
 <p ><TextField
            variant="outlined"
            margin="normal"
            fullWidth value={this.state.titles}
           inputProps={{tabIndex: "1",maxLength:150}}
			placeholder="Title"
            name="titles" onChange={this.myChangeHandler}
		
          /></p></li>
		
			 <li>
	  <p>
	  <FormControl fullWidth  size="small">
       
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.classlabel, value: this.state.classid }}
          onChange={this.myChangeHandler}
          id="state"
		   value={this.state.classid}
		  name="classid"
		
		
        >
		{this.state.Classarr.map(row => (
            <option  
            
              value={row.value}
            >
              {row.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
	  
	  </li>	

 <li>
	  <p>
	  <FormControl  fullWidth size="small" >
       
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.batchlabel, value: this.state.batchid }}
          onChange={this.myChangeHandler}
          id="state"
		   value={this.state.batchid}
		  name="batchid"
		
		
        >
		{this.state.Batcharr.map(row => (
            <option  
            
              value={row.value}
            >
              {row.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
	  
	  </li>
	  
		
		<li><p >
		<Button variant="contained" onClick={this.handleClickSearch} className="frmPrimarybtn" endIcon={<SearchIcon />}>
        Search
      </Button>	
	<Button variant="contained" onClick={()=>{this.Downloadaddbox(0)}} className="frmsecondarybtn" endIcon={<AddIcon />}>
        New
      </Button></p></li> 
		
		
		
		
		</ul>
		
</div>		



<NotificationPopUp ref="NotificationPopUp" id={this.state.noid} notype={this.props.moduletype} updateDonenew={this.updateDonenewParent}/>
<Paper className={useStyles.root}>


  <TableContainer className={useStyles.container}>  
                <Table stickyHeader aria-label="sticky table">  
<TableHead> 
<TableRow>
                                              
            <TableCell>Title</TableCell>           
			{this.props.moduletype==="2" && (  <TableCell>File</TableCell> )}
			{this.props.moduletype==="3" && (  <TableCell>Remainder Date</TableCell> )}
			 <TableCell></TableCell> 
                      </TableRow></TableHead>
					  
					   <EnhancedTableHead           
              order={this.state.order}
              orderBy={this.state.orderBy}
              
              onRequestSort={this.handleRequestSort}
              rowCount={this.state.Downloadsdata.length}
            />
                      <TableBody>
                     {stableSort(this.state.Downloadsdata, 
			getSorting(this.state.order,
			this.state.orderBy)).map((item,index) => {  
           return (
 <React.Fragment>
 <TableRow key={index} >



<TableCell>{item.title}</TableCell>
{strtype==="2" && (
<TableCell><a href={Serverurl+"uploads/"+item.no_file} target="_blank">View</a></TableCell>)}
{strtype==="3" && (
<TableCell>{item.remainderdate}</TableCell>)}

<TableCell align="left">
<div className="actionbtns">

<Button variant="contained" className="frmPrimarybtn" onClick={()=>{this.Downloadaddbox(item.no_id)}} endIcon={<EditIcon />}>
Edit
</Button>

 
<Button variant="contained" className="admsndisablebtn" onClick={()=>{this.downloadsDelete(item.no_id)}} endIcon={<DeleteIcon />}>
  Delete
</Button>

 </div>
 
 </TableCell>
 </TableRow>

 </React.Fragment>	
);
					})}
					
					{this.state.totaldata===0 &&(
					
					<TableRow key={0} >
<TableCell align="center" colSpan="4">No Records</TableCell>
					</TableRow>
					) }
					
                      </TableBody>


</Table>
  </TableContainer> 
  <TablePagination  
        rowsPerPageOptions={[50, 100, 150, 200, 250]}  
        component="div"  
        count={this.state.totaldata}  
        rowsPerPage={this.state.rowsPerPage}  
        page={this.state.page}  
        onPageChange={this.handleChangePage}  
        onChangeRowsPerPage={this.handleChangeRowsPerPage}  
      />   
  </Paper>
    
</div>
 </React.Fragment> 

 
 
  );
}
}

