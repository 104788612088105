import React ,{Component} from 'react'; 
import ReactDOM from "react-dom";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useOutletContext,Navigate  } from 'react-router-dom';
import axios from 'axios';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import Serverurl from './Apiurl';
import { DataGrid } from '@material-ui/data-grid';
import performance from './images/performance.png';
import FormControl from '@mui/material/FormControl';
import pageimg from './images/attendance.png';
import ReactExport from "react-export-excel";
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
export default class TestReports extends Component {
	
	 constructor(props) {
    super(props);	
	
    this.state = {headCells:[],Examdata:[],Classarr:[],classid:'',classlabel:'Select Class',resultdata:[],
	testarr:[],testlabel:'Select Test',testid:'0',branchlabel:'Select Barnch',
	yearid:'',yearlabel:'Select Year',Yeararr:[],batchid:'',Brancharr:[],branchid:'',batchlabel:'Select Batch',Batcharr:[],
	fileName:'Test Result Report',

     fileType:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    fileExtension : '.xlsx', 
	}
	
	 }
	componentDidMount()
	{if(localStorage.getItem("MMCuserid")===null)
	{
		this.setState({
							redirect: true,
							pagename:'/Login'
						  }) 
		

	}
	this.YearBind();
	  if(localStorage.getItem("MMCrole")==="1")
	 
		  { this.BranchBind();
		  }
	}
			componentWillMount() { 
localStorage.setItem("pageactive",10)
}	
YearBind()
{
	axios.get(Serverurl+'ajaxtrustandclass.php?action=getall&tc_type=3')
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
						Yeararr:resp.data,
yearid:	localStorage.getItem("MMCyearid")						
							}) 
			}
		this.ClassBind();
	   }) .then(data => {
        let yearFromApi = this.state.Yeararr.map(row => {
                          
          return {value: row.tc_id, display:row.tc_title}
        });
      
		
        this.setState({
          Yeararr: [{value: '0', display: 'Select Year'}].concat(yearFromApi)
        });
      })

}
	 ClassBind()
{
	axios.get(Serverurl+'ajaxtrustandclass.php?action=getall&tc_type=2')
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
						Classarr:resp.data	
							}) 
			}
		
	   }) .then(data => {
        let classFromApi = this.state.Classarr.map(row => {
                          
          return {value: row.tc_id, display:row.tc_title}
        });
      
		
        this.setState({
          Classarr: [{value: '0', display: 'Select Class'}].concat(classFromApi)
        });
      })

} 
TestApibind()
	{
			let strbranch=this.state.branchid;
	 if(localStorage.getItem("MMCrole")!=="1")
	 
		  { 
			  strbranch=localStorage.getItem("MMCbranchid");
		  }
	axios.get(Serverurl+'ajaxtest.php?action=getall&te_branch_id='+strbranch+'&classid='+this.state.classid)
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
						testarr:resp.data	
							}) 
			}else{
				this.setState({
						testarr:[]	
							}) 
				
			}
		
	   }) .then(data => {
        let testFromApi = this.state.testarr.map(row => {
                          
          return {value: row.te_id, display:row.te_testname}
        });
      
		
        this.setState({
          testarr: [{value: '0', display: 'Select Test'}].concat(testFromApi)
        });
      })
	}
/*
Batchbind(){
	let strurl="";
	
	
  if(localStorage.getItem("MMCrole")==="1")
	 
		  { 

		  strurl='ajaxbatch.php?action=list&bc_class_id='+this.state.classid;
		  }else{
    strurl='ajaxbatch.php?action=droplist&bc_class_id='+this.state.classid+'&bc_id='+localStorage.getItem("MMCbranchid");
	}
     axios.get(Serverurl+strurl)
      .then((response) => {    
     
      this.setState({  Batcharr: response.data,  });
      })   
    
      .then(data => {
        let batchApi = this.state.Batcharr.map(batch => {
                            
          return {value: batch.bc_id, display:batch.bc_title}
        });
      
		
        this.setState({
          Batcharr: [{value: '0', display: 'Select Batch'}].concat(batchApi)
        });
      })
  
  
  }*/
  myChangeHandler = (event) => {
	
	  const re = /^[0-9\b]+$/;
  let nam = event.target.name;
  let val = event.target.value;  
 
 
  this.setState({[nam]: val}, () => {
  
 if(localStorage.getItem("MMCrole")==="1")
	 
		  {
	   if(nam==="branchid")
	   {
		   	this.TestApibind(); 
 
	   }
		  }
		  else{
			 if(nam==="classid")
	   {
		   this.BatchBind();
			this.TestApibind();   
 	
	   }  
		  }
}); 
}
handleClickTest=()=>{   
 
  
  this.TestResultApiCall(); 

	
};


BatchBind()
{
	axios.get(Serverurl+'ajaxbatch.php?action=list&bc_class_id='+this.state.classid+'&bc_br_id='+localStorage.getItem("MMCbranchid"))
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
						Batcharr:resp.data	
							}) 
			}else{
				
				this.setState({
						Batcharr:[]	
							}) 
			}

	   }).then(data => {
        let batchFromApi = this.state.Batcharr.map(row => {
                          
          return {value: row.bc_id, display:row.bc_title}
        });
      
		
        this.setState({
          Batcharr: [{value: '0', display: 'Select Batch'}].concat(batchFromApi)
        });
      })

}
exportToCSV = (event) => {  


	let resultdata=this.state.resultdata;

        const ws = XLSX.utils.json_to_sheet(resultdata);
		
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: this.state.fileType});
		
        FileSaver.saveAs(data, this.state.fileName + this.state.fileExtension);
    }
BranchBind()
{
	axios.get(Serverurl+'ajaxbranch.php?action=getall')
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
						Brancharr:resp.data	
							}) 
			}
	 
	   }) .then(data => {
        let branchFromApi = this.state.Brancharr.map(row => {
                          
          return {value: row.br_id, display:row.br_title}
        });
      
		
        this.setState({
          Brancharr: [{value: '0', display: 'Select Branch'}].concat(branchFromApi)
        });
      })

}
	TestResultApiCall()
	{
			let strbranch=this.state.branchid;
	 if(localStorage.getItem("MMCrole")!=="1")
	 
		  { 
			  strbranch=localStorage.getItem("MMCbranchid");
		  }
		 axios.get(Serverurl+'ajaxtest.php?action=testreport&yearid='+this.state.yearid+'&displaytype=1&classid='+this.state.classid+'&batchid='+this.state.batchid+'&branchid='+strbranch+'&te_id='+this.state.testid )
       .then(resp => {  
		
    if(resp.data.length>0){
		let Ecell=[];
		let hcell=[];
		let drow=[];
		let dcell=[];
		let edrow=[];
		let edcell=[];
		let qtysum=0;
		let uid=0;
		let optionsRep = resp.data;
		let newrow= { field: 'c0', headerName: 'Test' };
		hcell.push(newrow);
		
 let cindex=1;
	let prev= "";
	 let isvalid=0;
	
	
   	for (let eachrep of optionsRep)
	{
		
		if(prev=="")
		{
			prev=eachrep['sr_studentname'];
			
			let newrow={field: "c"+cindex,   headerName: "Max Marks"}
			hcell.push(newrow);
			  cindex=cindex+1
		}
		 
		if(prev==eachrep['sr_studentname'])
		{
		let newrow={field: "c"+cindex,   headerName: eachrep['sb_title']}
	 hcell.push(newrow);	    
	   cindex=cindex+1
	   
		}
		else
		{
			
			isvalid=1;
		}
		
		 if(isvalid===1){
		 
		
			 break;
		 }
		
	}
	  newrow={field: "c"+cindex,   headerName: "Total"}
			hcell.push(newrow);
			 cindex=cindex+1
		
	 let  newrow1={field: "c"+cindex,   headerName: "Percentage"}
			hcell.push({field: "c"+cindex,   headerName: "Percentage"});
			 cindex=cindex+1

	newrow= { field: 'id', headerName: 'sl', hide:true}; 
	  hcell.push(newrow);
	
	prev= "";
	cindex=0; let totalsub=0,percentage=0, colindex=3;
	  optionsRep.forEach(eachrep => {
	  
		if(prev==eachrep['sr_studentname'])
		{
     

		

	   
				if(eachrep['isabsent']=="0")
{
dcell["c"+cindex]=eachrep['sm_marks'];	
edcell[hcell[cindex]["headerName"]]=eachrep['sm_marks'];
}else{
	dcell["c"+cindex]="Absent";	
	edcell[hcell[cindex]["headerName"]]="Absent";
}
	   cindex=cindex+1;
	   
	   
	
		}
		else
		{
			
		
			if(prev!="")
			{
				totalsub=0;
				for(var i=2;i<parseFloat(cindex);i++)
			{
				let strvalmar=0;
				if(dcell["c"+i]=="Absent")
				{
					strvalmar=0;
				}else{
					strvalmar=dcell["c"+i];
				}
				totalsub=parseFloat(totalsub)+ parseFloat(strvalmar)
			 edcell[hcell[cindex]["headerName"]]=totalsub;
			dcell["c"+cindex]=totalsub;
			var subjects=parseFloat(cindex)-2;
			var strpercentage=parseFloat(dcell["c1"])*parseFloat(subjects);
			strpercentage=(parseFloat(totalsub)/parseFloat(strpercentage))*100;
		
			let col=cindex+1
			 edcell[hcell[col]["headerName"]]=strpercentage.toFixed(2)+"%";
			dcell["c"+col]=strpercentage.toFixed(2)+"%";
	  // cindex=cindex+1;
			}
				
				qtysum=0;
				
					dcell["id"]= uid;
					uid=uid+1;
				edrow.push(edcell);
			  drow.push(dcell);	
			  cindex=0;
			  dcell={};
			   edcell={};
			}
		
					  edcell[hcell[cindex]["headerName"]]=eachrep['sr_studentname'];
				dcell["c"+cindex]=eachrep['sr_studentname'];
				
				cindex=cindex+1;
				
				
	  	if(prev=="")
			{

		dcell["c"+cindex]=eachrep['te_maxmarks'];
	  edcell[hcell[cindex]["headerName"]]=eachrep['te_maxmarks'];
				
				cindex=cindex+1;
			}else{
				edcell[hcell[cindex]["headerName"]]=eachrep['te_maxmarks'];
				dcell["c"+cindex]=eachrep['te_maxmarks'];
				cindex=cindex+1;}
				
				
				if(eachrep['isabsent']=="0")
{
dcell["c"+cindex]=eachrep['sm_marks'];
edcell[hcell[cindex]["headerName"]]=eachrep['sm_marks'];
}else{
	dcell["c"+cindex]="Absent";	
	edcell[hcell[cindex]["headerName"]]="Absent";	
}
			
				
				
				cindex=cindex+1;
				 
		}
		 
		prev=eachrep['sr_studentname'] ;
		
	})
	
	totalsub=0;
				for(var i=2;i<parseFloat(cindex);i++)
			{
				totalsub=parseFloat(totalsub)+ parseFloat(dcell["c"+i])
			edcell[hcell[cindex]["headerName"]]=totalsub;
			dcell["c"+cindex]=totalsub.toFixed(2);
	  // cindex=cindex+1;
			}
				var subjects=parseFloat(cindex)-2;
			var strpercentage=parseFloat(dcell["c1"])*parseFloat(subjects);
			strpercentage=parseFloat((parseFloat(totalsub)/parseFloat(strpercentage))*100);
		
			let col=cindex+1
			edcell[hcell[col]["headerName"]]=strpercentage.toFixed(2)+"%";
			dcell["c"+col]=strpercentage.toFixed(2)+"%";
	
	
	  dcell["id"]= uid;
					uid=uid+1;
					drow.push(dcell);
				edrow.push(edcell);
		//let edataset={columns:Ecell,data:edrow}; 
		 
			this.setState({  
			resultdata:edrow,
			  Examdata: drow,  
			 
			   headCells: hcell
			  
			})
		
	  
	 



	}
	else{
		  this.setState({headCells:[],Examdata:[]});
		
	}
	   });
		
	}
	render (){

return(
<React.Fragment>

   
<div className="containermain">
<div className="boxheading">
				  <div className="contntpad">
<h2><img src={pageimg}/>Batch Wise Test Report</h2>
</div></div>
<div className="searchmaster">
<ul><li><p><span className="validation">*</span>Year</p>
	  <p>
	  <FormControl  fullWidth size="small" >
        
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.yearlabel, value: this.state.yearid }}
          onChange={this.myChangeHandler}
          id="state"
		   value={this.state.yearid}
		  name="yearid"
		
		
        >
		{this.state.Yeararr.map(row => (
            <option  
            
              value={row.value}
            >
              {row.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
	  
	  </li>
<li><p><span className="validation">*</span>Class</p>
	  <p>
	  <FormControl fullWidth  size="small">
       
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.classlabel, value: this.state.classid }}
          onChange={this.myChangeHandler}
          id="state"
		   value={this.state.classid}
		  name="classid"
		
		
        >
		{this.state.Classarr.map(row => (
            <option  
            
              value={row.value}
            >
              {row.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
	  
	  </li>	
<li><p><span className="validation">*</span>Batch</p>
	  <p>
	  <FormControl  fullWidth size="small" >
       
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.batchlabel, value: this.state.batchid }}
          onChange={this.myChangeHandler}
          id="state"
		   value={this.state.batchid}
		  name="batchid"
		
		
        >
		{this.state.Batcharr.map(row => (
            <option  
            
              value={row.value}
            >
              {row.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
	  
	  </li>	  
		 {localStorage.getItem("MMCrole")==="1" && (<React.Fragment>
			<li><p><span className="validation">*</span>Branch</p><p> <FormControl fullWidth  size="small">
       
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.branchlabel, value: this.state.branchid }}
          onChange={this.myChangeHandler}
          id="state"
		   value={this.state.branchid}
		  name="branchid"
		
		
        >
		{this.state.Brancharr.map(row => (
            <option  
            
              value={row.value}
            >
              {row.display}
            </option  >
          ))}
		
        </Select>
 </FormControl>
		
				</p>
	
		  </li></React.Fragment> )}<li><p>Test</p>
	   <p>
	  <FormControl  fullWidth size="small" >
       
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.testlabel, value: this.state.testid }}
          onChange={this.myChangeHandler}
          id="state"
		   value={this.state.testid}
		  name="testid"
		
		
        >
		{this.state.testarr.map(row => (
            <option  
            
              value={row.value}
            >
              {row.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
	 
	 </li> 
			  </ul>
			  
			  	<div className="searchnewbtn">
		<p className="searchbutton">
		<Button variant="contained" onClick={this.handleClickTest}  endIcon={<SearchIcon />}>
        Search
      </Button>	&nbsp;&nbsp;
	  <Button className="expbtn" type="submit"
onClick={this.exportToCSV}
>
Export</Button> </p>
	
	  
	
				
</div>
			  </div>	 <div className="testsummary">
			<DataGrid id="purreport" rows={this.state.Examdata} columns={this.state.headCells}  />  
			  </div>	
			  </div>
</React.Fragment>
)
	}

}









