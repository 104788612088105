
import React, { Component } from "react";
import { Navigate  } from 'react-router-dom';
import Serverurl from './Apiurl';
import axios from 'axios';
import call from './images/call-icon.png';
import dob from './images/dob-icon.png';
import gender from './images/gender-female.png';
import payment from './images/payment-history.png';
import remainder from './images/remainder.png';
import basicicon from './images/basic-detail.png';
import downloads from './images/downloads.png';import ReactToPrint from 'react-to-print';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import institute from './images/institute-icon.png';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';  
import Button from '@mui/material/Button';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableBody from '@mui/material/TableBody'; 
import TableCell from '@mui/material/TableCell';  
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';  
import TablePagination from '@material-ui/core/TablePagination'; 
import PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow'; 
import ReplyIcon from '@mui/icons-material/Reply';
import StudentattendanceGraph from './StudentattendanceGraph';
import Studentexamgraph from './Studentexamgraph';
import Studentexamgraphprint from './Studentexamgraphprint';
import Studentexamgraphprint1 from './Studentexamgraphprint1';
import pageimg from './images/attendance.png';
import logo from './images/weblogo.png';
import headerbgimg  from './images/1.png';
import footerbgimg  from './images/2.png';
import { makeStyles } from '@mui/styles'; 
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';

const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 
 icon: {
   verticalalign:'middle',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
});

export default class StudentPage extends Component 
{
	
	constructor(props) 
	{
		super();
		this.state = {
			open:false,errormessage:'',erropen:false,
			sa_year_id:'',yearid:'',isprint: false,
			sa_class_id:'',classid:'',studenetdata:[],Classarr:[],Yeararr:[],yearlabel:'Select Year',
	btnsearchtext:'Serch',page:0 ,prevpage:0,totaldata:0,rowsPerPage:100,rowsPaging:[],
	Batcharr:[],batchid:'',batchlabel:'Select Batch',
		}
		
			this.updateDonenewParent = this.updateDonenewParent;
		
	}
	
	/*
		 updateDonenewParent = (text) => {this.setState({isprint: true}, () => {
	

this.setState({isprint:false });

});  }
	*/
	componentDidMount()
	{
		
		
	if(localStorage.getItem("MMCuserid")===null)
	{
		this.setState({
							redirect: true,
							pagename:'/Login'
						  }) 
	}
	

	this.ClassBind();this.YearBind();
	//this.OnloadStudentadmision();
	
	
	 //this.OnloadStudentGetdetails(this.state.page,this.state.rowsPerPage); 
	
	}


	
	
	
	
		OnloadStudentGetdetails(page,rowsPerPage)
{
	

		///let search = window.location.href;
		//let srid=2;
	 axios.get(Serverurl+'ajaxstudentregistration.php?action=report&sa_bc_id='+this.state.batchid+'&sa_class_id='+this.state.classid+'&sa_year_id='+this.state.yearid+'&StartIndex='+page+'&PageSize='+rowsPerPage)
       .then(resp => { 	

  
			if(resp.data.length>0){
				
				this.setState({
							
							studenetdata:resp.data,isprint:true,
						  }) 
			}
			
			 else{
		   this.setState({studenetdata:[],totaldata:0,btndisabled:false,btnsearchtext:'Search'});
	 }
			
	   });
	   
	   
	  

}
	
	ClassBind()
{
	axios.get(Serverurl+'ajaxtrustandclass.php?action=getall&tc_type=2')
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
						Classarr:resp.data	
							}) 
			}
				this.YearBind();
	
	   }) .then(data => {
        let classFromApi = this.state.Classarr.map(row => {
                          
          return {value: row.tc_id, display:row.tc_title}
        });
      
		
        this.setState({
          Classarr: [{value: '0', display: 'Select Class'}].concat(classFromApi)
        });
      })
}

	YearBind()
{
	axios.get(Serverurl+'ajaxtrustandclass.php?action=getall&tc_type=3')
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
						Yeararr:resp.data,yearid:localStorage.getItem("MMCyearid")	
							}) 
			}
		
	   }) .then(data => {
        let yearFromApi = this.state.Yeararr.map(row => {
                          
          return {value: row.tc_id, display:row.tc_title}
        });
      
		
        this.setState({
          Yeararr: yearFromApi
        });
      })

}BatchBind()
{
	axios.get(Serverurl+'ajaxbatch.php?action=list&bc_class_id='+this.state.classid+'&bc_br_id='+localStorage.getItem("MMCbranchid"))
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
						Batcharr:resp.data	
							}) 
			}else{
				
				this.setState({
						Batcharr:[]	
							}) 
			}

	   }).then(data => {
        let batchFromApi = this.state.Batcharr.map(row => {
                          
          return {value: row.bc_id, display:row.bc_title}
        });
      
		
        this.setState({
          Batcharr: [{value: '', display: 'Select Batch'}].concat(batchFromApi)
        });
      })

}
	OnloadStudentadmision()
{
	
	axios.get(Serverurl+'ajaxstudentadmission.php?action=list')
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({srbranch:resp.data[0]["br_title"],
							srclass:resp.data[0]["tc_title"],srbatch:resp.data[0]["bc_title"],
							studename:resp.data[0]["sr_studentname"],studcode:resp.data[0]["sr_studentcode"] +" - "+resp.data[0]["bc_title"] 
							,schoolname:resp.data[0]["sa_schoolname"],
							
							balamount:parseFloat(resp.data[0]["totalfee"]) -parseFloat( resp.data[0]["balamt"]),totalfee:resp.data[0]["totalfee"]
						  }) 
			}
	   });
	   

}

myChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
 	   this.setState({[nam]: val}, () => {
		   
		   if( this.state.classid!="0")
	   {
		this.BatchBind();      
 
	   }

}); 
 
}

handleClickSearch=()=>{   
	
		if(this.state.yearid===""){
			this.setState({errormessage:"Please Select Year",erropen:true});
	 window.scrollTo({        top: 0     });
	return false;
			
		}
		
		
		
		if(this.state.classid===""){
			this.setState({errormessage:"Please Select Class",erropen:true});
	 window.scrollTo({        top: 0     });
	return false;
			
		}
		
		
			if(this.state.batchid===""){
			this.setState({errormessage:"Please Select Batch",erropen:true});
	 window.scrollTo({        top: 0     });
	return false;
			
		}
	this.setState({studenetdata: []}, () => {

		 this.setState({btnsearchtext:'Processing'});
		 if(this.state.classid!=="" && this.state.yearid!=="" && this.state.batchid!=="")
{
			
  this.OnloadStudentGetdetails(this.state.page,this.state.rowsPerPage); 
  this.setState({erropen:false,open:false,btnsearchtext:'Search'});
  		

}	
		

}); 
  


} 
handlePrint() {
	
	this.setState({isprint: true}, () => {
document.getElementById('print-button').click();	

this.setState({isprint:false });

}); 

}
handleErrorclose=(event)=>{
	  
	this.setState({open: false});  
  }

 render() {
	 return (
		<React.Fragment>
		
		<div className="containermain"><div class="boxheading"><div class="contntpad">
		<h2><img src={pageimg}/> Print Performance </h2>
</div></div>		



 <p>   <Collapse in={this.state.erropen}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
               this.handleErrorclose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          } severity="error"
        >
        {this.state.errormessage}
        </Alert>
      </Collapse></p>
		<div className="searchmaster">


  
  <ul>
<li><p><span className="validation">*</span>Year</p>
	  <p>
	  <FormControl  fullWidth size="small" >
        
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.yearlabel, value: this.state.yearid }}
          onChange={this.myChangeHandler}
          id="state"
		   value={this.state.yearid}
		  name="yearid"
		
		
        >
		{this.state.Yeararr.map(row => (
            <option  
            
              value={row.value}
            >
              {row.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
	  
	  </li>
  
   
	   <li><p><span className="validation">*</span>Class</p>
	  <p>
	  <FormControl fullWidth  size="small">
       
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.classlabel, value: this.state.classid }}
          onChange={this.myChangeHandler}
          id="state"
		   value={this.state.classid}
		  name="classid"
		
		
        >
		{this.state.Classarr.map(row => (
            <option  
            
              value={row.value}
            >
              {row.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
	  
	  </li>
	  <li><p><span className="validation">*</span>Batch</p>
	  <p>
	  <FormControl  fullWidth size="small" >
       
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.batchlabel, value: this.state.batchid }}
          onChange={this.myChangeHandler}
          id="state"
		   value={this.state.batchid}
		  name="batchid"
		
		
        >
		{this.state.Batcharr.map(row => (
            <option  
            
              value={row.value}
            >
              {row.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
	  
	  </li>
	  
	  

	  

		
	 
 
		
	
			
		</ul>
	
	<div className="searchnewbtn">
		<p className="searchbutton">
		<Button variant="contained" disabled={this.state.btndisabled} onClick={this.handleClickSearch} className={useStyles.submit} >
			{this.state.btnsearchtext}
      </Button></p>
	  
	
				
</div>	</div><div className="clear"></div>
		
			
			<div ref={el => (this.componentRef = el)} >
{this.state.studenetdata.map((item, index) => (
  <div className="yourClassName" key={index}>
    <div className="pinkbg">
      <div className="headerbgimage">
        <img src={headerbgimg} alt="Header Background" />
      </div>

      <div className="aligncenter">
        <h1>STUDENT ACADEMIC PROFILE</h1>
      </div>

      <div className="studentdiv">
        <div className="leftboxclass">
          <p><span className="colorprint">Class:</span> &nbsp;<span className="classbox">{item.tc_title}</span></p>
        </div>

        <div className="leftboxbatch">
          <p><span className="colorprint">Batch:</span> &nbsp;<span className="classbox">{item.bc_title}</span></p>
        </div>

        <div className="clear"></div>

        <div className="printimg">
          {item.sr_profileimg !== "" && (
            <img src={Serverurl + "uploads/" + item.sr_profileimg} alt="Profile" />
          )}
        </div>




        <div className="printcontnt">
          <div className="leftbox colorprint">
            <p>Name of the Student:</p>
          </div>
          <div className="rightbox">
            {item.sr_studentname}
          </div>

          <div className="clear"></div>

          <div className="leftbox colorprint">
            <p>Name of the School:</p>
          </div>
          <div className="rightbox">
            {item.sa_schoolname}
          </div>

          <div className="clear"></div>

          <div className="leftbox colorprint">
            <p>Father's Name:</p>
          </div>
          <div className="rightbox">
            {item.sr_fathername}
          </div>

          <div className="clear"></div>
        </div>
        
        <div className="clear"></div>
      </div>
	<Studentexamgraphprint1 ref="Studentexamgraphprint1"  said={item.sa_id}/>

	

    </div>
	
	

	
  </div>
  




  
))}

{this.state.isprint===true &&(
<div className="printstudentbtn">
 <ReactToPrint 
            trigger={() => <button  id="print-button" className="exportbtn">Print </button>}
			content={() =>  this.componentRef} /> 
</div>
)}

</div>


		
		</div>
		</React.Fragment> 
		
		
		
		)
 }
}






