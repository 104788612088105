import React ,{Component} from 'react'; 
import { Navigate  } from 'react-router-dom';
import axios from 'axios'; 
import Table from '@mui/material/Table';
import Serverurl from './Apiurl';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow'; 
import { makeStyles } from '@mui/styles'; 
import Paper from '@mui/material/Paper';  
import TableSortLabel from '@mui/material/TableSortLabel';
import TableBody from '@mui/material/TableBody'; 
import TableCell from '@mui/material/TableCell';  
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';  
import TablePagination from '@material-ui/core/TablePagination'; 
import Box from '@mui/material/Box';
import CreateIcon from '@mui/icons-material/Create';
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import pageimg from './images/attendance.png';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DoneIcon from '@mui/icons-material/Done';
import ReactToPrint from 'react-to-print';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,DatePicker, 
  KeyboardDatePicker,
  DateTimePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { addDays } from "date-fns";
import Moment from 'react-moment';
import moment from 'moment';
import GolfCourseIcon from '@mui/icons-material/GolfCourse';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
const headCells = [


    { id: '', numeric: false, label: 'Sl No.' },
  { id: 'sr_studentcode', numeric: false, label: 'Student Code' },
  { id: 'sr_studentname', numeric: false, label: 'Name' },
    { id: 'sr_fathername', numeric: false, label: 'Father Name' },
  { id: 'sr_mothername', numeric: false, label: 'Mother Name' },
  { id: 'pr_phonenumber', numeric: false, label: 'Parent Phone No.' },
  
 
   { id: 'sr_emailid', numeric: false, label: 'Email Id' },
    { id: 'tc_title', numeric: false, label: 'Class' },
	 { id: 'bc_title', numeric: false, label: 'Batch' },
	  {id: 'sacomplete', numeric: false, label: 'Course Status' },
  { id: '',  label: '' },
  
  ]
function stableSort(array, cmp) {
	  const stabilizedThis = array.map((el, index) => [el, index]);
	  stabilizedThis.sort((a, b) => {
		const order = cmp(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	  });
	  return stabilizedThis.map(el => el[0]);
}
function desc(a, b, orderBy) {
	  if (b[orderBy] < a[orderBy]) {
		return -1;
	  }
	  if (b[orderBy] > a[orderBy]) {
		return 1;
	  }
	  return 0;
}
function getSorting(order, orderBy) {
	return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

function EnhancedTableHead(props) {
		const { classes,  order, orderBy,  rowCount, onRequestSort } = props;
		const createSortHandler = property => event => {
		onRequestSort(event, property);
		};

	  return (
		<TableHead>
		  <TableRow>
			
			{headCells.map(headCell => (
			  <TableCell
				key={headCell.id}
				align={headCell.numeric ? 'right' : 'left'}
				padding={headCell.disablePadding ? 'none' : 'default'}
				sortDirection={orderBy === headCell.id ? order : false}
			  >
				<TableSortLabel
				  active={orderBy === headCell.id}
				  direction={orderBy === headCell.id ? order : 'asc'}
				  onClick={createSortHandler(headCell.id)}
				>
				  {headCell.label}
				  {orderBy === headCell.id ? (
					<span className={useStyles.visuallyHidden}>
					  {order === 'desc' ? ' ' : ' '}
					</span>
				  ) : null}
				</TableSortLabel>
			  </TableCell>
			))}
		  </TableRow>
		</TableHead>
	  );
}


EnhancedTableHead.propTypes = {
	  classes: PropTypes.object.isRequired,
	 
	  onRequestSort: PropTypes.func.isRequired,
	 
	  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	  orderBy: PropTypes.string.isRequired,
	  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 
 icon: {
   verticalalign:'middle',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
});


export default class Adminstudentregistration extends Component {
	
    constructor(props) {
    super(props);
   
      this.state = {redirect: false,sr_profileimg:'',
      Studentgegdata:[],srid:'',page: 0 ,exportdata:[],btndisabled:false,btnsearchtext:'Search',prevpage:0,totaldata:0,rowsPerPage:100,rowsPaging:[],srprid:0,Brancharr:[],branchid:'',branchlabel:'',yearid:localStorage.getItem("MMCyearid"),Yeararr:[],yearlabel:'Select Year',
	 
	  order:'desc' ,orderBy:'',studentname:'',fathername:'',stcode:'',parents:'',displaytype:'',rolelabel:'Select Phone No.',searchcustomer:'',
	  Batcharr:[],batchid:'',batchlabel:'Select Batch',Classarr:[],classid:'',classlabel:'Select Class',strsearch:'',
	          
      }
       this.updateDonenewParent = this.updateDonenewParent;
	   
	}
	
	updateDonenewParent = (text) => {this.apicall(this.state.page,this.state.rowsPerPage);	}
	
	Search(){
	this.setState({  
      page: 0,
      rowsPerPage:50,
	  prevpage:0,Studentgegdata:[]
    });
	
//this.componentDidMount();  
}
			componentWillMount() { 
localStorage.setItem("pageactive",10)
}	




componentDidMount() {


	document.title = "MM Coching Center - Student Management";
	this.onloadsearchAssign();
	if(localStorage.getItem("MMCuserid")==null)
		  {

		   this.setState({
            redirect: true,
			pagename:'/Login'
          })
		  }
	if(localStorage.getItem("MMCrole")==="1")
	 
		  {
	this.BranchBind();
		  }
	this.ClassBind();
	
	  localStorage.setItem("page",this.state.rowsPerPage)
  
 
} 



onloadsearchAssign()
{
	if(localStorage.getItem("studsch")!==null)
		{
			let strarr=localStorage.getItem("studsch").split('~');
			
			 this.setState({studentname:strarr[0],parents:strarr[1],batchid:strarr[2],stcode:strarr[3],classid:strarr[4]},()=>{
				 this.apicall(this.state.page,this.state.rowsPerPage);
				 
			 });
		}else{
		this.apicall(this.state.page,this.state.rowsPerPage);	
		}
}

handleClickSearch=()=>{   
	let strbranch=localStorage.getItem("MMCbranchid");
	 if(localStorage.getItem("MMCrole")=="1")
	 
		  {
			  strbranch=this.state.branchid;
		  }

			let strsearch=this.state.studentname+"~"+this.state.parents+"~"+this.state.batchid+"~"+this.state.stcode+"~"+this.state.classid;
			
			localStorage.setItem("studsch",strsearch)
		
  this.apicall(this.state.page,this.state.rowsPerPage); 

	
} 

myChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
 	   this.setState({[nam]: val}, () => {
if( this.state.classid!="0")
	   {
		this.BatchBind();      
 
	   }
}); 
 
}
ClassBind()
{
	axios.get(Serverurl+'ajaxtrustandclass.php?action=getall&tc_type=2')
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
						Classarr:resp.data	
							}) 
			}
				this.YearBind();
	
	   }) .then(data => {
        let classFromApi = this.state.Classarr.map(row => {
                          
          return {value: row.tc_id, display:row.tc_title}
        });
      
		
        this.setState({
          Classarr: [{value: '0', display: 'Select Class'}].concat(classFromApi)
        });
      })

}


YearBind()
{
	axios.get(Serverurl+'ajaxtrustandclass.php?action=getall&tc_type=3')
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
						Yeararr:resp.data,yearid:localStorage.getItem("MMCyearid")	
							}) 
			}
		
		this.BatchBind();
	   }) .then(data => {
        let yearFromApi = this.state.Yeararr.map(row => {
                          
          return {value: row.tc_id, display:row.tc_title}
        });
      
		
        this.setState({
          Yeararr: yearFromApi
        });
      })

}
BatchBind()
{
	axios.get(Serverurl+'ajaxbatch.php?action=list&bc_class_id='+this.state.classid+'&bc_br_id='+localStorage.getItem("MMCbranchid"))
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
						Batcharr:resp.data	
							}) 
			}else{
				
				this.setState({
						Batcharr:[]	
							}) 
			}

	   }).then(data => {
        let batchFromApi = this.state.Batcharr.map(row => {
                          
          return {value: row.bc_id, display:row.bc_title}
        });
      
		
        this.setState({
          Batcharr: [{value: '0', display: 'Select Batch'}].concat(batchFromApi)
        });
      })

}

exportcall(){
	
	let strbranch=localStorage.getItem("MMCbranchid");
	 if(localStorage.getItem("MMCrole")=="1")
	 
		  {
			  strbranch=this.state.branchid;
		  }
	 axios.get(Serverurl+'ajaxstudentregistration.php?action=report&branchid='+strbranch+'&sr_pr_id='+this.state.parents+'&sa_bc_id='+this.state.batchid+'&sa_class_id='+this.state.classid+'&sr_studentname='+this.state.studentname+'&sr_fathername='+this.state.fathername+'&sr_studentcode='+this.state.stcode+'&sa_year_id='+this.state.yearid)
     
	
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  exportdata: resp.data,  
			 
			   

		    });	
	 
     }
	 else{
		   this.setState({exportdata:[]});
	 }
 
    });
}
apicall(page,rowsPerPage){
	this.setState({btndisabled:true,btnsearchtext:'Processing...'  });  
	 let strbranch=localStorage.getItem("MMCbranchid");
	 if(localStorage.getItem("MMCrole")=="1")
	 
		  {
			  strbranch=this.state.branchid;
		  }
		  
	
		  
	 axios.get(Serverurl+'ajaxstudentregistration.php?action=report&sr_issupend=0&branchid='+strbranch+'&sr_pr_id='+this.state.parents+'&sa_bc_id='+this.state.batchid+'&sa_class_id='+this.state.classid+'&sr_studentname='+this.state.studentname+'&sr_fathername='+this.state.fathername+'&sr_studentcode='+this.state.stcode+'&StartIndex='+page+'&PageSize='+rowsPerPage+'&sa_year_id='+this.state.yearid)
     
	
  .then(resp => {  
		
    if(resp.data.length>0){
		let copy=[ 100,  200, 300, 400, 500];
			this.setState({  
			  Studentgegdata: resp.data,  
			   totaldata:parseInt(resp.data[0].count),
			   rowsPaging:copy,btndisabled:false,btnsearchtext:'Search'

		    });
			
			
	 
			
	  
	 this.exportcall();
	 
     }
	 else{
		   this.setState({Studentgegdata:[],totaldata:0,btndisabled:false,btnsearchtext:'Search'});
	 }
 	
			
    });
}


BranchBind()
{
	axios.get(Serverurl+'ajaxbranch.php?action=getall')
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
						Brancharr:resp.data	
							}) 
			}
	
	   }) .then(data => {
        let branchFromApi = this.state.Brancharr.map(row => {
                          
          return {value: row.br_id, display:row.br_title}
        });
      
		
        this.setState({
          Brancharr: [{value: '0', display: 'Select Branch'}].concat(branchFromApi)
        });
      })

}



handleChangePage = (event, newPage) => {  
   
    let pageval = localStorage.getItem("page");
    this.setState({  
      page: newPage,
      rowsPerPage:parseInt(pageval)
    },()=>{this.apicall(this.state.page,this.state.rowsPerPage);});
   
 
  
  };  
  		componentWillMount() { 
localStorage.setItem("pageactive",10)
}	
  
   handleChangeRowsPerPage = event => {  
  
    let pageval = event.target.value;
    localStorage.setItem("page",pageval)


    this.setState({  
      page: 0,
      rowsPerPage:parseInt(pageval),
	  prevpage:-1
    },()=>{this.apicall(this.state.page,this.state.rowsPerPage);}); 
	
	
  }; 
  
  
  handleRequestSort = (event, property) => {
  const isAsc = this.state.orderBy === property && this.state.order === 'asc';
  

  this.setState({   orderBy: property,
    order:isAsc  ? 'desc' : 'asc'
   
  });
};


      studentcomplete(said){  
	
let r = window.confirm("Do you really want to Update the Status as Completed?");
 if(r == true){ //sign Out logic
 var formData = new FormData();
     formData.append('action','complete')
	 formData.append('sa_id',said)
	 	   
formData.append('userid',localStorage.getItem("MMCuserid"))	
	
	
	 axios.post(Serverurl+"ajaxstudentregistration.php", formData, {
        }).then(res => {
    
             this.setState({  
      prevpage: -1,
    
    });
   
 
   this.apicall(this.state.page,this.state.rowsPerPage);
  
    
            });
 }
}

	
  studentIdDelete(srid){  
	
	let r = window.confirm("Do you really want to Delete?");
 if(r == true){ //sign Out logic
 var formData = new FormData();
     formData.append('action','delete')
	 formData.append('sr_id',srid)
	 	  
	formData.append('userid',localStorage.getItem("MMCuserid"))
	
	
	 axios.post(Serverurl+"ajaxstudentregistration.php", formData, {
        })
		
		
		.then(res => {
    
             this.setState({  
      prevpage: -1,
    
    });
   
 
    setTimeout(() =>  this.handleClickSearch() ,500); 
  
    
            });
 }
      }	

    Parentaddbox(val){
		
        this.setState({ srprid: val });  
  
         setTimeout(() => this.refs.AddParentbox.handleClickOpen(),500);

      } 
	   Completeaddbox(val){
        this.setState({ said: val });  
  
         setTimeout(() => this.refs.AddCompletebox.handleClickOpen(),500);

      } 
onClickEditStud(val){
	localStorage.setItem("MMEdSrId",val);
        this.setState({ redirect:true,pagename:'/StudentRegistration' });  
  
        

      } 
render() {

if (this.state.redirect === true) {
      return <Navigate to={this.state.pagename} />
    }


  return (
  
  
  



 
  <React.Fragment>
   
<div className="containermain">
<div className="boxheading">
	<div className="contntpad">
<h2><img src={pageimg}/>Students Report</h2>
</div></div>
<div className="searchmaster">


  
  <ul>

  
   {localStorage.getItem("MMCrole")==="1" && (
	  <li><p>Branch</p>
	  <p>
	  <FormControl fullWidth  size="small">
       
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.branchlabel, value: this.state.branchid }}
          onChange={this.myChangeHandler}
          id="state"
		   value={this.state.branchid}
		  name="branchid"
		
		
        >
		{this.state.Brancharr.map(row => (
            <option  
            
              value={row.value}
            >
              {row.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
	  
	  </li>)}
	   <li><p><span className="validation">*</span>Class</p>
	  <p>
	  <FormControl fullWidth  size="small">
       
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.classlabel, value: this.state.classid }}
          onChange={this.myChangeHandler}
          id="state"
		   value={this.state.classid}
		  name="classid"
		
		
        >
		{this.state.Classarr.map(row => (
            <option  
            
              value={row.value}
            >
              {row.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
	  
	  </li><li><p><span className="validation">*</span>Year</p>
	  <p>
	  <FormControl  fullWidth size="small" >
        
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.yearlabel, value: this.state.yearid }}
          onChange={this.myChangeHandler}
          id="state"
		   value={this.state.yearid}
		  name="yearid"
		
		
        >
		{this.state.Yeararr.map(row => (
            <option  
            
              value={row.value}
            >
              {row.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
	  
	  </li>
	  <li><p><span className="validation">*</span>Batch</p>
	  <p>
	  <FormControl  fullWidth size="small" >
       
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.batchlabel, value: this.state.batchid }}
          onChange={this.myChangeHandler}
          id="state"
		   value={this.state.batchid}
		  name="batchid"
		
		
        >
		{this.state.Batcharr.map(row => (
            <option  
            
              value={row.value}
            >
              {row.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
	  
	  </li>
	  
	  
	  <li><p>Student Name</p>
 <p ><TextField
            variant="outlined"
            margin="normal"
            fullWidth value={this.state.studentname}
           inputProps={{tabIndex: "2",maxLength:150}}
			placeholder="Student Name"
            name="studentname" onChange={this.myChangeHandler}
		
          /></p></li>
	  
	  
     <li><p>Student Code</p>
 <p ><TextField
            variant="outlined"
            margin="normal"
            fullWidth value={this.state.stcode}
           inputProps={{tabIndex: "4",maxLength:150}}
			placeholder="Student Code"
            name="stcode" onChange={this.myChangeHandler}
		
          /></p></li>
 <li><p>Phone No.</p>
 <p ><TextField
            variant="outlined"
            margin="normal"
            fullWidth value={this.state.parents}
           inputProps={{tabIndex: "2",maxLength:150}}
			placeholder="Phone No."
            name="parents" onChange={this.myChangeHandler}
		
          /></p></li>
		
	 
 
		
	
			
		</ul>
	
	<div className="searchnewbtn">
		<p className="searchbutton">
		<Button variant="contained" disabled={this.state.btndisabled} onClick={this.handleClickSearch} className={useStyles.submit} endIcon={<SearchIcon />}>
			{this.state.btnsearchtext}
      </Button>&nbsp;&nbsp;<ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="exportbtn"
                    table="purreport"
                    filename="Student Report"
                    sheet="Student Report"
                    buttonText="Export"/>&nbsp;&nbsp;<ReactToPrint 
            trigger={() => <p id="print-button" className="exportbtn">Print </p>}
			content={() =>  this.componentRef} /></p>
	
	  
	
				
</div>	
		
		
		
</div>		

   

<AddParentbox ref="AddParentbox" id={this.state.srprid}  updateDonenew={this.updateDonenewParent}/>
<AddCompletebox ref="AddCompletebox" said={this.state.said}  updateDonenew={this.updateDonenewParent}/>
 <div ref={el => (this.componentRef = el)} >
<Paper className={useStyles.root}>


  <TableContainer className={useStyles.container}>  
                <Table stickyHeader aria-label="sticky table" >  
<TableHead> 
<TableRow>
                                              
                       
						
                      </TableRow></TableHead>
					  
					   <EnhancedTableHead           
              order={this.state.orderBy}
              orderBy={this.state.order}
              
              onRequestSort={this.handleRequestSort}
              rowCount={this.state.Studentgegdata.length}
            />
                      <TableBody>
                     {stableSort(this.state.Studentgegdata, 
			getSorting(this.state.order,
			this.state.orderBy)).map((student,index) => {
let amount=parseFloat(student.totalfee	)-parseFloat(student.balamt)	
let i= parseFloat(((this.state.page)+1)-1)*parseFloat(this.state.rowsPerPage)+(parseFloat(index)+1);
           return (
 <React.Fragment>
 <TableRow key={i} >

<TableCell>{i}</TableCell>
<TableCell>{student.sr_studentcode}</TableCell>
<TableCell>
<div className="container">

<span className="hover-text">{student.sr_studentname}</span>
<span className="profileimg">
{student.sr_profileimg!=="" && (
<img src={Serverurl+'uploads/'+student.sr_profileimg} />
)}
{student.sr_profileimg==="" && (
<img src={Serverurl+'uploads/user.jpg'} />
)}
</span></div>
</TableCell>
<TableCell>{student.sr_fathername}</TableCell>
<TableCell>{student.sr_mothername}</TableCell>

<TableCell>{student.pr_phonenumber}</TableCell>


<TableCell>{student.sr_emailid}</TableCell>
<TableCell>{student.tc_title}</TableCell>
<TableCell>{student.bc_title}</TableCell>
<TableCell>{student.sacomplete}</TableCell>

 <TableCell>


<Button variant="contained"  className="frmPrimarybtn" onClick={()=>{this.Parentaddbox(student.sr_pr_id)}} endIcon={<EditIcon />}>
P
</Button>



<a href={'#/Studentviewpage?srid='+student.sr_id} className="frmsecondarybtn" >

<VisibilityIcon />
</a>
 {(localStorage.getItem("MMCrole")==="1" ) &&(<React.Fragment>
<Button variant="contained"  className="frmPrimarybtn" onClick={()=>{this.studentIdDelete(student.sr_id)}}  endIcon={<DeleteIcon />}>
  D
</Button>

   </React.Fragment>)} 
    {(localStorage.getItem("MMCrole")==="2" || localStorage.getItem("MMCrole")==="1") &&(<React.Fragment>
 <Button variant="contained"  className="frmsecondarybtn" onClick={()=>{this.onClickEditStud(student.sr_id)}} endIcon={<CreateIcon />}>
S
</Button>
 </React.Fragment>)}

 {(student.sa_iscomplete !=="1" && amount=="0") &&(<React.Fragment> 


 <Button variant="contained"  className="admsndisablebtn" onClick={()=>{this.Completeaddbox(student.sa_id)}} endIcon={<GolfCourseIcon />}>
CC
</Button>


 

  </React.Fragment>)} 
</TableCell>




 </TableRow>

 </React.Fragment>	
);
					})}
					
					{this.state.totaldata===0 &&(
					
					<TableRow key={0} >
<TableCell align="center" colSpan="6">No Records</TableCell>
					</TableRow>
					) }
					
                      </TableBody>


</Table>
  </TableContainer> 
  <TablePagination  
        rowsPerPageOptions={[100,  200, 300, 400, 500]}  
        component="div"  
        count={this.state.totaldata}  
        rowsPerPage={this.state.rowsPerPage}  
        page={this.state.page}  
        onPageChange={this.handleChangePage}  
        onChangeRowsPerPage={this.handleChangeRowsPerPage}  
      />   
  </Paper>
  
  
  
   <div className="exporttab">
  <Paper className={useStyles.root}>


  <TableContainer className={useStyles.container}>  
                <Table stickyHeader aria-label="sticky table" id="purreport">  
<TableHead> 
<TableRow>
                                              
       <TableCell>Student Code</TableCell> <TableCell>Name </TableCell>                      
		<TableCell>Father </TableCell> <TableCell>Mother </TableCell> 
<TableCell>Parent Phone No.</TableCell> <TableCell>Email Id </TableCell> 
<TableCell>Alternate Email Id </TableCell> 	<TableCell>Class </TableCell> <TableCell>Batch </TableCell> <TableCell>Address </TableCell> 		
<TableCell>WhatsApp No. </TableCell><TableCell>Gender</TableCell><TableCell>Fee</TableCell><TableCell>Paid Amount</TableCell>
<TableCell>Balance</TableCell><TableCell>Refund Amount</TableCell><TableCell>Subjects</TableCell><TableCell>joining date</TableCell><TableCell>Course Complete</TableCell>
                      </TableRow></TableHead>
					  
					  
                      <TableBody>
                     {stableSort(this.state.exportdata, 
			getSorting(this.state.order,
			this.state.orderBy)).map((student,index) => {
let amount=parseFloat(student.totalfee	)-parseFloat(student.balamt)	
let i= parseFloat(((this.state.page)+1)-1)*parseFloat(this.state.rowsPerPage)+(parseFloat(index)+1);
           return (
 <React.Fragment>
 <TableRow key={i} >


<TableCell>{student.sr_studentcode}</TableCell>
<TableCell>{student.sr_studentname}</TableCell>
<TableCell>{student.sr_fathername}</TableCell>
<TableCell>{student.sr_mothername}</TableCell>
<TableCell>{student.pr_phonenumber}</TableCell>


<TableCell>{student.sr_emailid}</TableCell> <TableCell>{student.sr_alternateemailid}</TableCell>
<TableCell>{student.tc_title}</TableCell>
<TableCell>{student.bc_title}</TableCell>
<TableCell>{student.sr_address}</TableCell>

 <TableCell>{student.sr_whatsappphoneno}</TableCell>

 <TableCell>{student.srgender}</TableCell>
 <TableCell>{student.totalfee}</TableCell><TableCell>{student.balamt}</TableCell>
 
 
 <TableCell>{amount}</TableCell>
 <TableCell>{student.sa_refund}</TableCell>
 <TableCell>{student.subjects}</TableCell> <TableCell>{student.sa_joiningdate}</TableCell>
 <TableCell>{student.sacomplete}</TableCell>
 </TableRow>

 </React.Fragment>	
);
					})}
					
					
					
                      </TableBody>


</Table>
  </TableContainer> 
    
  </Paper>
   </div>
  </div>	  </div>  

 </React.Fragment> 

 
 
  );
}
}
export  class  AddParentbox extends Component {

   constructor(props) {
      super(props)
	  
      this.state = {
		  
		   open:false,type:'',pr_id:0,phoneno:'',password:'',errormessage:'',erropen:false,
	  }
      
  }
  
  
  

  
 Parentgetdata() {
	
  if(this.props.id!==0)
  {
	  
	   axios.get(Serverurl+'ajaxparent.php?action=getall&pr_id='+this.props.id)
  .then(resp => {  
		
    if(resp.data.length>0){
    this.setState({ 
     
      pr_id:resp.data[0].pr_id,
	  phoneno: resp.data[0].pr_phonenumber,
	 password:resp.data[0].pr_password,
    });
	
	
	
	
  }
  
});
	  
  }else{
	 this.setState({  
	 
	 
	 phoneno:'',
	 
	 sr_pr_id:0
	
	 
	  }); 
 }
  
    }

   
handleClickOpen = (e) => {
 
  this.setState({open:!this.state.open,errormessage:'',erropen:false,
	  });
		this.Parentgetdata();
		


};
myChangeHandler = (event) => {
	
	  const re = /^[0-9\b]+$/;
  let nam = event.target.name;
  let val = event.target.value;  
 
  this.setState({[nam]: val});
  

}

 handleErrorclose=(event)=>{
	  
	this.setState({open: false});  
  }


handleClose = (event) => {
	
	
     
	
	
    event.preventDefault();
	
	
	let phoneno = this.state.phoneno;	
	let password = this.state.password;
	
	 
 
 if(phoneno==="")
{
	this.setState({errormessage:"Please Enter Phone No.",open:true});
		 window.scrollTo({
          top: 0
      })
		 return false;
}

if(phoneno.length < 10)
{
	this.setState({errormessage:"Please Enter 10 digit Phone Number",open:true});
		 window.scrollTo({
          top: 0
      })
		 return false;
}

	 
 if(password === "")
	 {
		
		 this.setState({errormessage:"Please Enter Password",open:true});
		 window.scrollTo({
          top: 0
      })
		 return false;
	 }	 
	


   var formData = new FormData();
  
  
  	  
	   formData.append('pr_id',this.state.pr_id)
	   formData.append('action','update')	
		formData.append('pr_phonenumber',phoneno)		
		formData.append('pr_password',password)		
		formData.append('userid',localStorage.getItem("MMCuserid"))		
		//formData.append('userid',localStorage.getItem("MMCuserid"))
		  
		axios.post(Serverurl+"ajaxparent.php", formData, {
        }).then(res => {
    
	
	
	if(res.data == -1)
{ 
	 

		this.setState({errormessage:"Phone Number Already Exist.",erropen:true,open:true
		});
		window.scrollTo({
          top: 0
      })
		 return false;

	}
	
	
            this.props.updateDonenew();
    
        });
  
	
	this.setState({ open:false });
};

	 handleErrorclose=(event)=>{
	  
	this.setState({erropen: false});  
  } 

	


render (){

return(
<React.Fragment>





    <Dialog open={this.state.open} onClose={this.handleClose}  maxWidth="lg" aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title"> Parent Management</DialogTitle>
      <DialogContent>
	  
<div className="formlist">

	<p>  <Collapse in={this.state.erropen}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
               this.handleErrorclose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          } severity="error"
        >
        {this.state.errormessage}
        </Alert>
      </Collapse></p>
						  
		
  <p>Phone Number<span className="validation">*</span> </p>
   <p><TextField name="phoneno" 
	   onChange={this.myChangeHandler} 
	   placeholder="Phoneno"
	    variant="outlined"
	   fullWidth
	   value={this.state.phoneno}
			 InputLabelProps={{  shrink: true,  }}
			 inputProps={{ tabIndex: "3",maxLength:10 }}   
			  //autofocus="true"
			  ref={this.usernameInput}
                          /></p>
   
	<p> Password<span className="validation">*</span></p>
   <p><TextField name="password" 
	   onChange={this.myChangeHandler}
placeholder="Password"
	    variant="outlined"	   
	   fullWidth type="password"
	   value={this.state.password}
			 InputLabelProps={{  shrink: true,  }}
			  //autofocus="true"
			  ref={this.usernameInput}
			   inputProps={{ tabIndex: "5",maxLength:15 }}  
                          /></p>
   				  
		

			  
		
 
		
       <div className="actionbtns">

<div className="cancelbtn">	  
        <Button onClick={this.handleClickOpen} color="secondary">
          Cancel
        </Button></div> 
<div className="savebtn">
        <Button onClick={this.handleClose} color="secondary">
          Save
        </Button>
		</div>
<div className="clear"></div>

 </div>     
	</div>		 
						    </DialogContent>
      
    </Dialog>
</React.Fragment>

) }
 
} 
  
export  class  AddCompletebox extends Component {

   constructor(props) {
      super(props)
	  var strdob=new Date();
	 var month="";
	
	 if((strdob.getMonth() + 1)<10)
	 {
		month="0"+ (strdob.getMonth() + 1)		 
	 }else{month=(strdob.getMonth() + 1)}
	
	
	var strldob=new Date();
	 var month="";
	
	 if((strldob.getMonth() + 1)<10)
	 {
		month="0"+ (strldob.getMonth() + 1)		 
	 }else{month=(strldob.getMonth() + 1)}
      this.state = {
		  
		   open:false,type:'',sr_pr_id:0,tdate:'',errormessage:'',erropen:false,compdate:Date.now(),compdate1:strdob.getFullYear() + '-' + month + '-' +strdob.getDate(),
	  }
      
  }
  
  
 handfrChanges = (date) => {
	
	  var month="";
	 
	 if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)}   
	 
     this.setState({ dmdate:date,
dmdate1:date.getFullYear() + '-' + month + '-' +date.getDate()
	 })
	
	
  }

  
 

   
handleClickOpen = (e) => {

  this.setState({open:!this.state.open,errormessage:'',erropen:false,said:this.props.said
	  });
	 
};
myChangeHandler = (event) => {
	
	  const re = /^[0-9\b]+$/;
  let nam = event.target.name;
  let val = event.target.value;  
 
  this.setState({[nam]: val});
  

}

 handleErrorclose=(event)=>{
	  
	this.setState({open: false});  
  }


handleClose = (event) => {
	
	
     
	
	
    event.preventDefault();
	
	let said=this.state.said;
	let dmdate1 = this.state.dmdate1;	
	
	
	 
 
 if(dmdate1==="")
{
	this.setState({errormessage:"Please Select Date",open:true});
		 window.scrollTo({
          top: 0
      })
		 return false;
}


 
	


   var formData = new FormData();
  
  
  	  formData.append('action','complete')
	   formData.append('sa_id',this.state.said) 
	   formData.append('sa_coursecompleteddate',dmdate1)
		
		formData.append('userid',localStorage.getItem("MMCuserid"))		
		//formData.append('userid',localStorage.getItem("MMCuserid"))
		  
		axios.post(Serverurl+"ajaxstudentregistration.php", formData, {
        }).then(res => {
    		
	
            this.props.updateDonenew();
    
        });
  
	
	this.setState({ open:false });
};

	 handleErrorclose=(event)=>{
	  
	this.setState({erropen: false});  
  } 

	


render (){

return(
<React.Fragment>





    <Dialog open={this.state.open} onClose={this.handleClose} maxWidth="lg" aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title"> Status Update</DialogTitle>
      <DialogContent>
	  
<div className="formlist">

	<p>  <Collapse in={this.state.erropen}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
               this.handleErrorclose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          } severity="error"
        >
        {this.state.errormessage}
        </Alert>
      </Collapse></p>
						  
		
		  <p>Date
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-around">	 
      <DatePicker fullWidth
        value={this.state.dmdate || Date.now()}
        onChange={this.handfrChanges}
		variant="inline"
		inputVariant="outlined"
		 margin="normal"            
            format="dd/MM/yyyy"
			minutesStep={5}
        autoOk name="dmdate"
        ampm={false}
      />
	   </Grid>
    </MuiPickersUtilsProvider>
		</p>
   
  
   
    
  
				  
		

			  
		
 
		
       <div className="actionbtns">

<div className="cancelbtn">	  
        <Button onClick={this.handleClickOpen} color="secondary">
          Cancel
        </Button></div> 
<div className="savebtn">
        <Button onClick={this.handleClose} color="secondary">
          Save
        </Button>
		</div>
<div className="clear"></div>

 </div>     
	</div>		 
						    </DialogContent>
     
    </Dialog>
</React.Fragment>

) }
 
} 