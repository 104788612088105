import React ,{Component} from 'react'; 
import ReactDOM from "react-dom";
import { Bar } from "react-chartjs-2";
import {Chart, Filler} from 'chart.js';
import { useOutletContext,Navigate  } from 'react-router-dom';
import axios from 'axios';
import Serverurl from './Apiurl';
import { DataGrid } from '@material-ui/data-grid';
import ReactToPrint from 'react-to-print';
import performance from './images/performance.png';
import headerbgimg  from './images/1.png';
import footerbgimg  from './images/2.png';
export default class Studentdetails extends Component {
  constructor(props) {
    super(props);
	
	
    this.state = { studentarray:[], studentsumarydata:[],drpcoretype:"1",drpoptype:"1",
	studentarray1:[], studentsumarydata1:[],attanData:[],maxmarks:0,
	dataarr:[],Examdata:[],headCells:[],dataarr1:[],Examdata1:[],headCells1:[],
      
    };
	
	
	
  }
  componentWillMount() {
	 let said=this.props.said;
	
this.StudentPerformancesecondApicall(2,said);
this.StudentPerformancesumaryApicall(1,said);
//this.AttendanceGraphApi(said); 
  }

componentWillReceiveProps(props) {
	
  this.setState({refresh : props.refresh });
	let said=this.props.said;
	
this.StudentPerformancesecondApicall(2,said);
this.StudentPerformancesumaryApicall(1,said);
//this.AttendanceGraphApi(said);
	}
	
	
	
	StudentPerformancesecondApicall(strobj,said){
		var me=this;
		 axios.get(Serverurl+'ajaxstudentmarks.php?action=perfmgraphlist&sm_sa_id='+said+'&displaytype='+strobj)
       .then(resp => {  
		
    if(resp.data.length>0){
		this.setState({studentsumarydata1:resp.data,studentarray1:resp.data});
		let Ecell=[];
		let hcell=[];
		let drow=[];
		let dcell=[];
		let edrow=[];
		
		let qtysum=0;
		let uid=0;
		let optionsRep = resp.data;
		let newrow= { field: 'c0', headerName: 'Test',sortable: false };
		hcell.push(newrow);
		
 let cindex=1;
	let prev= "";
	 let isvalid=0;
	
	
   	for (let eachrep of optionsRep)
	{
		
		if(prev=="")
		{
			prev=eachrep['te_testname'];
			
			let newrow={field: "c"+cindex,   headerName: "Max.",sortable: false}
			hcell.push(newrow);
			  cindex=cindex+1
		}
		 
		if(prev==eachrep['te_testname'])
		{
		let newrow={field: "c"+cindex,   headerName: eachrep['sb_title'],sortable: false}
	 hcell.push(newrow);	    
	   cindex=cindex+1
	   
		}
		else
		{
			
			isvalid=1;
		}
		
		 if(isvalid===1){
		 
		
			 break;
		 }
		
	}
	  newrow={field: "c"+cindex,   headerName: "Total",sortable: false }
			hcell.push(newrow);
			 cindex=cindex+1
		
	 let  newrow1={field: "c"+cindex,   headerName: "Percentage"}
			hcell.push({field: "c"+cindex,   headerName: "Percentage",sortable: false });
			 cindex=cindex+1

	newrow= { field: 'id', headerName: 'sl', hide:true}; 
	  hcell.push(newrow);
	
	prev= "";
	cindex=0; let totalsub=0,percentage=0, colindex=3;
	  optionsRep.forEach(eachrep => {
	  
		if(prev==eachrep['te_testname'])
		{
     
	  	

		
if(eachrep['isabsent']=="0")
{
dcell["c"+cindex]=eachrep['sm_marks'];	
}else{
	dcell["c"+cindex]="Absent";	
}
	  
		
	   cindex=cindex+1;
	   
	   
	
		}
		else
		{
			
		
			if(prev!="")
			{
				totalsub=0;
				for(var i=2;i<parseFloat(cindex);i++)
			{
				let strvalmar=0;
				if(dcell["c"+i]=="Absent")
				{
					strvalmar=0;
				}else{
					strvalmar=dcell["c"+i];
				}
				totalsub=parseFloat(totalsub)+ parseFloat(strvalmar)
			
			dcell["c"+cindex]=totalsub;
			var subjects=parseFloat(cindex)-2;
			var strpercentage=parseFloat(dcell["c1"])*parseFloat(subjects);
			strpercentage=(parseFloat(totalsub)/parseFloat(strpercentage))*100;
		
			let col=cindex+1
			dcell["c"+col]=strpercentage.toFixed(2)+"%";
	  // cindex=cindex+1;
			}
				
				qtysum=0;
				
					dcell["id"]= uid;
					uid=uid+1;
				
			  drow.push(dcell);	
			  cindex=0;
			  dcell={};
			  
			}
		
				
				dcell["c"+cindex]=eachrep['te_testname'];
				
				cindex=cindex+1;
				
				
	  	if(prev=="")
			{

		dcell["c"+cindex]=eachrep['te_maxmarks'];
	
				
				cindex=cindex+1;
			}else{
				
				dcell["c"+cindex]=eachrep['te_maxmarks'];
				cindex=cindex+1;}
					
if(eachrep['isabsent']=="0")
{
				 dcell["c"+cindex]=eachrep['sm_marks'];
}else{dcell["c"+cindex]="Absent";}
				
				cindex=cindex+1;
				 
		}
		 
		prev=eachrep['te_testname'] ;
		
	})
	
	totalsub=0;
				for(var i=2;i<parseFloat(cindex);i++)
			{
				
				let strvalmar=0;
				if(dcell["c"+i]=="Absent")
				{
					strvalmar=0;
				}else{
					strvalmar=dcell["c"+i];
				}
				totalsub=parseFloat(totalsub)+ parseFloat(strvalmar)
			
			dcell["c"+cindex]=totalsub;
	  // cindex=cindex+1;
			}
				var subjects=parseFloat(cindex)-2;
			var strpercentage=parseFloat(dcell["c1"])*parseFloat(subjects);
			strpercentage=(parseFloat(totalsub)/parseFloat(strpercentage))*100;
		
			let col=cindex+1
			dcell["c"+col]=strpercentage.toFixed(2)+"%";
	
	
	  dcell["id"]= uid;
					uid=uid+1;
					drow.push(dcell);
				
		//let edataset={columns:Ecell,data:edrow}; 
		this.StudentPerformancesecondGraphApicall();
			this.setState({  
			
			  Examdata1: drow,  
			 
			   headCells1: hcell
			  
			})
		
	  
	 


	  
	}else{
		
	}
	
	   });
	}
	StudentPerformancesecondGraphApicall(){
		var me=this;
	
		
    if(this.state.studentarray1.length>0){
		let optionsRep1 = this.state.studentarray1;
		let prev= "",prevsub="",testtitle="";
		let hlabel1=[],subjectsarr=[];
		let hsub1=[];
		//this.setState({ studentarray:resp.data});
		 
		 
		 	for (let eachrep of optionsRep1)
	{		
		
		if(prev!=eachrep['te_testname'])
		{
			prev=eachrep['te_testname'];
			testtitle=eachrep['te_testname']+" Max:"+eachrep['te_maxmarks'];
		hlabel1.push(testtitle);
		}
		
				
	}
	
	

	 let sorted1 = optionsRep1.sort((a, b) => b.sb_sort - a.sb_sort);

	//let sorted1 = optionsRep1;
	 
	 let edcell1=[], cellsubject1=[];
	let edrow1=[];
		  {sorted1.map((row, index) => {
		 var strdataset="";
		
		
			let j=parseInt(index)-1;
			
			
		if(prevsub!=row.sb_id)
		{
			
			if(index!==0){
			 edcell1["label"]=sorted1[j]["sb_title"];
			 edcell1["backgroundColor"]=sorted1[j]["sb_color"];
			
			 edcell1["fill"]=true;
            /*edcell["strokeColor"]= "rgba(220,220,220,0.8)"; 
            edcell["highlightFill"]=  "rgba(220,220,220,0.75)";
            edcell["highlightStroke"]=  "rgba(220,220,220,1)";*/
			  edcell1["borderColor"]=sorted1[j]["sb_color"];
			   edcell1["borderWidth"]=1;
			    edcell1["hoverBackgroundColor"]=sorted1[j]["sb_color"];
				 edcell1["hoverBorderColor"]=sorted1[j]["sb_color"];
				 edcell1["data"]=cellsubject1;
			edrow1.push(edcell1);
		cellsubject1=[];
		edcell1=[];
		
			}
			cellsubject1.push(row.sm_marks);
			 prevsub=row.sb_id;
		}
		else{
			
			cellsubject1.push(row.sm_marks);
			 prevsub=row.sb_id;
		}
		

		  })};
		  let  lastindex=parseInt(sorted1.length)-1;
		  edcell1["label"]=sorted1[lastindex]["sb_title"];
			 edcell1["backgroundColor"]=sorted1[lastindex]["sb_color"];
			  edcell1["borderColor"]=sorted1[lastindex]["sb_color"];
			   edcell1["borderWidth"]=1; edcell1["fill"]=true;
			    edcell1["hoverBackgroundColor"]=sorted1[lastindex]["sb_color"];
				 edcell1["hoverBorderColor"]=sorted1[lastindex]["sb_color"];
				 edcell1["data"]=cellsubject1;
			edrow1.push(edcell1);
		const dataperformance1 = {
         datasets:edrow1,labels:hlabel1,
         
       
        };
			
	 this.setState({
        dataarr1: dataperformance1,
    });
	
	

	
	}else{ this.setState({
        dataarr1: [],
    });}
	   
	};
	
 
	
	
	StudentPerformancesumaryApicall(strobj,said){
		var me=this;
		 axios.get(Serverurl+'ajaxstudentmarks.php?action=perfmgraphlist&sm_sa_id='+said+'&displaytype='+strobj)
       .then(resp => {  
		
    if(resp.data.length>0){
			let studarry=resp.data;
		let maxval=Math.max(...studarry.map(o => o.te_maxmarks))
		this.setState({studentsumarydata:resp.data,studentarray:resp.data,maxmarks:maxval});
		let Ecell=[];
		let hcell=[];
		let drow=[];
		let dcell=[];
		let edrow=[];
		
		let qtysum=0;
		let uid=0;
		let optionsRep = resp.data;
		let newrow= { field: 'c0', headerName: 'Test' ,sortable: false};
		hcell.push(newrow);
		
 let cindex=1;
	let prev= "";
	 let isvalid=0;
	
	
   	for (let eachrep of optionsRep)
	{
		
		if(prev=="")
		{
			prev=eachrep['te_testname'];
			
			let newrow={field: "c"+cindex,   headerName: "Max.",sortable: false}
			hcell.push(newrow);
			  cindex=cindex+1
		}
		 
		if(prev==eachrep['te_testname'])
		{
		let newrow={field: "c"+cindex,   headerName: eachrep['sb_title'],sortable: false}
	 hcell.push(newrow);	    
	   cindex=cindex+1
	   
		}
		else
		{
			
			isvalid=1;
		}
		
		 if(isvalid===1){
		 
		
			 break;
		 }
		
	}
	  newrow={field: "c"+cindex,   headerName: "Total",sortable: false}
			hcell.push(newrow);
			 cindex=cindex+1
		
	 let  newrow1={field: "c"+cindex,   headerName: "%"}
			hcell.push({field: "c"+cindex,   headerName: "%",sortable: false});
			 cindex=cindex+1

	newrow= { field: 'id', headerName: 'sl', hide:true}; 
	  hcell.push(newrow);
	
	prev= "";
	cindex=0; let totalsub=0,percentage=0, colindex=3;
	  optionsRep.forEach(eachrep => {
	  
		if(prev==eachrep['te_testname'])
		{
     

		

	  
		if(eachrep['isabsent']=="0")
{
dcell["c"+cindex]=eachrep['sm_marks'];	
}else{
	dcell["c"+cindex]="Absent";	
}
	   cindex=cindex+1;
	   
	   
	
		}
		else
		{
			
		
			if(prev!="")
			{
				totalsub=0;
				for(var i=2;i<parseFloat(cindex);i++)
			{
				let strvalmar=0;
				if(dcell["c"+i]=="Absent")
				{
					strvalmar=0;
				}else{
					strvalmar=dcell["c"+i];
				}
				totalsub=parseFloat(totalsub)+ parseFloat(strvalmar)
			
			dcell["c"+cindex]=totalsub;
			var subjects=parseFloat(cindex)-2;
			var strpercentage=parseFloat(dcell["c1"])*parseFloat(subjects);
			strpercentage=(parseFloat(totalsub)/parseFloat(strpercentage))*100;
		
			let col=cindex+1
			dcell["c"+col]=strpercentage.toFixed(2)+"%";
	  // cindex=cindex+1;
			}
				
				qtysum=0;
				
					dcell["id"]= uid;
					uid=uid+1;
				
			  drow.push(dcell);	
			  cindex=0;
			  dcell={};
			  
			}
		
				
				dcell["c"+cindex]=eachrep['te_testname'];
				
				cindex=cindex+1;
				
				
	  	if(prev=="")
			{

		dcell["c"+cindex]=eachrep['te_maxmarks'];
	
				
				cindex=cindex+1;
			}else{
				
				dcell["c"+cindex]=eachrep['te_maxmarks'];
				cindex=cindex+1;}
			
				if(eachrep['isabsent']=="0")
{
dcell["c"+cindex]=eachrep['sm_marks'];	
}else{
	dcell["c"+cindex]="Absent";	
}
				
				cindex=cindex+1;
				 
		}
		 
		prev=eachrep['te_testname'] ;
		
	})
	
	totalsub=0;
				for(var i=2;i<parseFloat(cindex);i++)
			{let strvalmar=0;
				if(dcell["c"+i]=="Absent")
				{
					strvalmar=0;
				}else{
					strvalmar=dcell["c"+i];
				}
				totalsub=parseFloat(totalsub)+ parseFloat(strvalmar)
			
			dcell["c"+cindex]=totalsub;
	  // cindex=cindex+1;
			}
				var subjects=parseFloat(cindex)-2;
			var strpercentage=parseFloat(dcell["c1"])*parseFloat(subjects);
			strpercentage=(parseFloat(totalsub)/parseFloat(strpercentage))*100;
		
			let col=cindex+1
			dcell["c"+col]=strpercentage.toFixed(2)+"%";
	
	
	  dcell["id"]= uid;
					uid=uid+1;
					drow.push(dcell);
				
		//let edataset={columns:Ecell,data:edrow}; 
		this.StudentPerformanceGraphApicall();
			this.setState({  
			
			  Examdata: drow,  
			 
			   headCells: hcell
			  
			})
		
	  
	 


	  
	}
	
	   });
	}
	StudentPerformanceGraphApicall(){
		var me=this;
	

    if(this.state.studentarray.length>0){
		let optionsRep = this.state.studentarray;
		let prev= "",prevsub="",testtitle="";
		let hlabel=[],subjectsarr=[];
		let hsub1=[];
		//this.setState({ studentarray:resp.data});
		 
		 
		 	for (let eachrep of optionsRep)
	{		
		
		if(prev!=eachrep['te_testname'])
		{
			prev=eachrep['te_testname'];
			testtitle=eachrep['te_testname']+" Max:"+eachrep['te_maxmarks'];
		hlabel.push(testtitle);
		}
		
				
	}
	
	

	 let sorted = optionsRep.sort((a, b) => b.sb_sort - a.sb_sort);

	
	 
	 let edcell=[], cellsubject=[];
	let edrow=[];
		  {sorted.map((row, index) => {
		 var strdataset="";
		
		
			let j=parseInt(index)-1;
			
			
		if(prevsub!=row.sb_id)
		{
			
			if(index!==0){
			 edcell["label"]=sorted[j]["sb_title"];
			 edcell["backgroundColor"]=sorted[j]["sb_color"];
			
			 edcell["fill"]=true;
            /*edcell["strokeColor"]= "rgba(220,220,220,0.8)"; 
            edcell["highlightFill"]=  "rgba(220,220,220,0.75)";
            edcell["highlightStroke"]=  "rgba(220,220,220,1)";*/
			  edcell["borderColor"]=sorted[j]["sb_color"];
			   edcell["borderWidth"]=1;
			    edcell["hoverBackgroundColor"]=sorted[j]["sb_color"];
				 edcell["hoverBorderColor"]=sorted[j]["sb_color"];
				 edcell["data"]=cellsubject;
			edrow.push(edcell);
		cellsubject=[];
		edcell=[];
		
			}
			cellsubject.push(row.sm_marks);
			 prevsub=row.sb_id;
		}
		else{
			
			cellsubject.push(row.sm_marks);
			 prevsub=row.sb_id;
		}
		

		  })};
		  let  lastindex=parseInt(sorted.length)-1;
		  edcell["label"]=sorted[lastindex]["sb_title"];
			 edcell["backgroundColor"]=sorted[lastindex]["sb_color"];
			  edcell["borderColor"]=sorted[lastindex]["sb_color"];
			   edcell["borderWidth"]=1; edcell["fill"]=true;
			    edcell["hoverBackgroundColor"]=sorted[lastindex]["sb_color"];
				 edcell["hoverBorderColor"]=sorted[lastindex]["sb_color"];
				 edcell["data"]=cellsubject;
			edrow.push(edcell);
		const dataperformance = {
         datasets:edrow,labels:hlabel,
         
       
        };
			
	 this.setState({
        dataarr: dataperformance,
    });
	
	

	
	}else{ this.setState({
        dataarr: [],
    });}
	   
	};
	myChangeHandler = (event) => {		
		const re = /^[0-9\b]+$/;
  let nam = event.target.name;
  let val = event.target.value;	 
this.setState({[nam]: val}); 

}
  render() {
	  
	
	
    const options = {
		
		 tooltips: {
    mode: 'label'
  }, 
      responsive: true,
      legend: {
        display: true,
		 position:'bottom'
      },
	  title:{
                    display:true,
                    text:'Performance',
                    fontSize:14
                    },
      type: "bar",
	  scales: {xAxes: [{
            barPercentage: 0.4,
			
        }],
      yAxes: [{
         ticks: {
            beginAtZero: true,
			max: parseFloat(this.state.maxmarks),
                min: 0
         }
      }]
   }
      //   scales: {
      //     xAxes: [{
      //         stacked: true
      //     }],
      //     yAxes: [{
      //         stacked: true
      //     }]
      // }
    };
	
    return (
		<React.Fragment>
 

 <table border="0px" cellPadding="0" cellSpacing="0" width="83%" style={{"fontSize":"13px","fontFamily":"Arial"}}>


<tr>

<td colSpan={10} align="left">
<div className="persummaryprint">
	<DataGrid hideFooter={true} rows={this.state.Examdata} columns={this.state.headCells}  />
	
	 

	</div>
	
	<div className="remainde-col"> 
		{this.state.dataarr.length!==0 && (
		
		
		<Bar id="mychart"
	  
	  
	  
        data={this.state.dataarr}
         width={850}
        height={260}
       options={options}
      />
	  
	  
	  )}
	   </div> 

 </td>
</tr>

</table>



<br/>
   <div className="footerbgimage">
<div  className="parentdiv">
<ul>
<li><p>Parent's Signature</p>
</li>
<li><p>MMC</p>
</li>
</ul>
</div>
<img src={footerbgimg}/>

</div>
	 

	 
	
<div className="page-break"></div>
	
   
	 
	
	  	</React.Fragment>
    );
  
  }
}


