import React, { Component } from "react";
import { Navigate  } from 'react-router-dom';
import Serverurl from './Apiurl';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@mui/material/IconButton';
import registrationhead from './images/registrationhead.png';
export default class Parentpassword extends Component 
{
	constructor(props) 
	{
		super();
		this.state = {errormessage:'',open:false,redirect:false,pagename:'',primarybtntext:'Submit',Password:''}
		
	}
	myChangeHandler = (event) => {
		
		const re = /^[0-9\b]+$/;
  let nam = event.target.name;
  let val = event.target.value;	  

	  
	   this.setState({[nam]: val});

}
	OnParentRegister= (event) => 
		{
		event.preventDefault();
		let strpassword = this.state.Password;
		let phone = this.props.phone;
			if(strpassword==''){
						
				this.setState({errormessage:"Please Enter Password",open:true});
		 window.scrollTo({        top: 0     })
	return false; 
				}
				
			if(strpassword.length<4){
				this.setState({errormessage:"Please Enter Strong Password",open:true});
		 window.scrollTo({        top: 0     })
	return false;
			}	this.setState({primarybtntext:'Processing...'});
				var formData = new FormData();
				
				formData.append('action','insert') 
				formData.append('userid',localStorage.getItem("MMCuserid")) 
				formData.append('pr_password',strpassword) 
				formData.append('pr_phonenumber',phone) 
				axios.post(Serverurl+"ajaxparent.php", formData, {
				}).then(res => {
				var strreturn=res.data;
					if(res.data!=="-1")
					{
					localStorage.setItem("MMCParentid",strreturn)
					this.setState({primarybtntext:'Submit',redirect:true,pagename:'/StudentRegistration'});
					}else{
					this.setState({primarybtntext:'Submit',redirect:true,pagename:'/Dashboard'});
					
					}
				});
		}
	
	 render() {
if (this.state.redirect === true) {
      return <Navigate to={this.state.pagename} />
    }
	 return (
   <div className="pagebox-col">
	 <div className="formview">
			<div className="boxheading">
				  <div className="contntpad">
					 <p>  <Collapse in={this.state.open}>
										<Alert
										  action={
											<IconButton
											  aria-label="close"
											  color="inherit"
											  size="small"
											  onClick={() => {
											   this.handleErrorclose();
											  }}
											>
											  <CloseIcon fontSize="inherit" />
											</IconButton>
										  } severity="error"
										>
										{this.state.errormessage}
										</Alert>
								</Collapse></p>
				  <h2><img src={registrationhead} />Registration</h2>
				  </div> </div>
	   <div className="contntpad">
	
	<p><span className="validation">*</span>Password</p>
	 <p><TextField id="txtpassword"  type="password" size="small"  placeholder="Password"  name="Password" value={this.state.Password}  inputProps={{tabIndex: "1",maxLength:150}} InputLabelProps={{ shrink: true }}  
	  onChange={this.myChangeHandler} /></p>
	<p><Button variant="standard" className="frmPrimarybtn" endIcon={<CheckRoundedIcon />} onClick={this.OnParentRegister}>
								  {this.state.primarybtntext}
						</Button></p>
	</div>
	 </div>
	  </div>
	  )
	 }
}








