import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Navigate  } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Serverurl from './Apiurl';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import logo from './images/weblogo.png';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@material-ui/lab/Alert';
import axios from 'axios';
import vector from './images/vector.png';
import { askForPermissioToReceiveNotifications } from '../firebase';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { getMessaging, getToken, onMessage } from "firebase/messaging";

export default class ParentLogin extends Component {
	
	 constructor(props) {
    super(props);
	 this.state = {Username:'',Password:'',btndisabled:false,pagename:'',errormessage:'',open:false,checked:true,showPassword:false}
	 }
myChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
  this.setState({[nam]: val}); 
} 
handleChange = (event) => 
{
	this.setState({checked: !this.state.checked});
};
handleClickShowPassword = (event) => 
{
	this.setState({showPassword: !this.state.showPassword});
};
componentDidMount() {

	if(localStorage.getItem("MMCuserid")!==null)
	{
		this.setState({
							redirect: true,
							pagename:'/MobStudentDashbaord'
						  }) 
	}else{
		
		this.setState({
							Username: localStorage.getItem("MMCUsername"),
							Password: localStorage.getItem("MMCpwd"),
						  }) 
		}

}
tokenupdate= (prid) =>  {
		   var formData = new FormData();
	  formData.append('action','deviceupdate') 
	  formData.append('pr_deviceid',localStorage.getItem("MMCtoken"))
	   formData.append('pr_id',prid)
	    axios.post(Serverurl+"ajaxparent.php", formData, {
        }).then(res => {
			this.setState({
							redirect: true,open:false,
							pagename:'/MobStudentDashbaord'
						  }) 
		});
	 }	
handleClickLogin= () => 
		{
			var self = this;
		
				
				let Username = this.state.Username;
				let Password = this.state.Password;
				let ischeck = this.state.checked;	
				if(Username===''){
						
				this.setState({errormessage:"Please Enter Username",open:true});
		 window.scrollTo({        top: 0     })
	return false; 
				}
			
				if(Password==''){
						this.setState({errormessage:"Please Enter Password",open:true});
						 window.scrollTo({        top: 0     })
						 return false; 
					
				}
var formData = new FormData();
				formData.append('action','Login') 	
 
				formData.append('username',Username)
				formData.append('password',Password)
				
	
	
				axios.post(Serverurl+"ajaxparent.php", formData, {
				}).then(res => {
				
				
				if(res.data.length>0){
					
					if(ischeck==true)
					  {
						  
						   localStorage.setItem("MMCUsername",Username);
						  localStorage.setItem("MMCpwd",Password);
						
					  }
					  else{
							 localStorage.removeItem("MMCUsername");
							  localStorage.removeItem("MMCpwd"); 
						  }
					localStorage.setItem("MMCPrid",res.data[0].pr_id)
				localStorage.setItem("MMCstudreid",res.data[0].sr_id)
				localStorage.setItem("MMCstudid",res.data[0].sa_id)
				  setTimeout(function(){
 
         
 self.tokenupdate(res.data[0].pr_id);
    }, 1000);
		
				
				}
				else
				{
					this.setState({errormessage:"Not a valid login details",open:true});
		 window.scrollTo({        top: 0     })
				}
				
				});
				
				
			
		}
		
			
	 handleErrorclose=(event)=>{
	  
	this.setState({open: false});  
  }
	render() {
		if (this.state.redirect === true) {
      return <Navigate to={this.state.pagename} />
    }
return (
<React.Fragment>

<div className="mobscreenrow"><div className="main">
	
	<div className="mobloginfrm">
	
	<div className="logodiv">
			<img src={logo}/>
		</div>
		<div className="logindiv1">
		<h1>Sign In</h1>
			 <p>  <Collapse in={this.state.open}>
							<Alert
							  action={
								<IconButton
								  aria-label="close"
								  color="inherit"
								  size="small"
								  onClick={() => {
								   this.handleErrorclose();
								  }}
								>
								  <CloseIcon fontSize="inherit" />
								</IconButton>
							  } severity="error"
							>
							{this.state.errormessage}
							</Alert>
					</Collapse></p>
			<p><TextField id="txtUsername" fullWidth  label="Phone No." variant="standard" name="Username" value={this.state.Username}  inputProps={{tabIndex: "1",maxLength:150}}    InputLabelProps={{ shrink: true }} onChange={this.myChangeHandler}/></p>
			<p>
			  <TextField
			    type={this.state.showPassword ? 'text' : 'password'}
          label="Password"
		   inputProps={{tabIndex: "2",maxLength:15}} onChange={this.myChangeHandler}  InputLabelProps={{ shrink: true }} 
          id="standard-start-adornment"  name="Password" fullWidth value={this.state.Password} 
          InputProps={{
            endAdornment:   <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={this.handleClickShowPassword}
                
                >
                  {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>,
          }}
          variant="standard"
        />
			
</p>
			<p> <FormControlLabel
        label="Remember Password"
        control={ <Checkbox  checked={this.state.checked}  onChange={this.handleChange}  inputProps={{ 'aria-label': 'controlled' }} />}
      /></p>
			<p><Button
            type="submit"
            fullWidth
            variant="text" 
            color="primary"
            className="btnmobSubmit"
		
			onClick={()=>{this.handleClickLogin();askForPermissioToReceiveNotifications();}}
          >
            Sign In
          </Button></p>
		</div>
	</div>
	<div className="loginvector">
		
		<img src={vector}/>
		</div>
	<div className="clear"></div>
</div>
</div>
</React.Fragment>
)

	}		
}







