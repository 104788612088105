import React ,{Component} from 'react'; 
import { Navigate  } from 'react-router-dom';
import axios from 'axios'; 
import Table from '@mui/material/Table';
import Serverurl from './Apiurl';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow'; 
import { makeStyles } from '@mui/styles'; 
import Paper from '@mui/material/Paper';  
import TableSortLabel from '@mui/material/TableSortLabel';
import TableBody from '@mui/material/TableBody'; 
import TableCell from '@mui/material/TableCell';  
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';  
import TablePagination from '@material-ui/core/TablePagination'; 
import Box from '@mui/material/Box';
import CreateIcon from '@mui/icons-material/Create';
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import pageimg from './images/attendance.png';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DoneIcon from '@mui/icons-material/Done';
import ReactToPrint from 'react-to-print';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,DatePicker, 
  KeyboardDatePicker,
  DateTimePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { addDays } from "date-fns";
import Moment from 'react-moment';
import moment from 'moment';
import GolfCourseIcon from '@mui/icons-material/GolfCourse';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
const headCells = [


    { id: '', numeric: false, label: 'Sl No.' },
  { id: 'sr_studentcode', numeric: false, label: 'Student Code' },
  { id: 'sr_studentname', numeric: false, label: 'Name' },
    { id: 'sr_fathername', numeric: false, label: 'Parents' },

  { id: 'pr_phonenumber', numeric: false, label: 'Parent Phone No.' },
  
 
   { id: 'sr_emailid', numeric: false, label: 'Email Id' },
    { id: 'tc_title', numeric: false, label: 'Class' },
	 { id: 'bc_title', numeric: false, label: 'Batch' },
	  {id: 'sacomplete', numeric: false, label: 'Course Status' },
	  {id: '', numeric: false, label: '' },

  
  ]
function stableSort(array, cmp) {
	  const stabilizedThis = array.map((el, index) => [el, index]);
	  stabilizedThis.sort((a, b) => {
		const order = cmp(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	  });
	  return stabilizedThis.map(el => el[0]);
}
function desc(a, b, orderBy) {
	  if (b[orderBy] < a[orderBy]) {
		return -1;
	  }
	  if (b[orderBy] > a[orderBy]) {
		return 1;
	  }
	  return 0;
}
function getSorting(order, orderBy) {
	return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

function EnhancedTableHead(props) {
		const { classes,  order, orderBy,  rowCount, onRequestSort } = props;
		const createSortHandler = property => event => {
		onRequestSort(event, property);
		};

	  return (
		<TableHead>
		  <TableRow>
			
			{headCells.map(headCell => (
			  <TableCell
				key={headCell.id}
				align={headCell.numeric ? 'right' : 'left'}
				padding={headCell.disablePadding ? 'none' : 'default'}
				sortDirection={orderBy === headCell.id ? order : false}
			  >
				<TableSortLabel
				  active={orderBy === headCell.id}
				  direction={orderBy === headCell.id ? order : 'asc'}
				  onClick={createSortHandler(headCell.id)}
				>
				  {headCell.label}
				  {orderBy === headCell.id ? (
					<span className={useStyles.visuallyHidden}>
					  {order === 'desc' ? ' ' : ' '}
					</span>
				  ) : null}
				</TableSortLabel>
			  </TableCell>
			))}
		  </TableRow>
		</TableHead>
	  );
}


EnhancedTableHead.propTypes = {
	  classes: PropTypes.object.isRequired,
	 
	  onRequestSort: PropTypes.func.isRequired,
	 
	  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	  orderBy: PropTypes.string.isRequired,
	  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 
 icon: {
   verticalalign:'middle',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
});


export default class Adminstudentregistration extends Component {
	
    constructor(props) {
    super(props);
   
      this.state = {sr_issupend:'',redirect: false,sr_profileimg:'',
      Studentgegdata:[],srid:'',page: 0 ,exportdata:[],btndisabled:false,btnsearchtext:'Search',prevpage:0,totaldata:0,rowsPerPage:100,rowsPaging:[],srprid:0,Brancharr:[],branchid:'',branchlabel:'',yearid:localStorage.getItem("MMCyearid"),Yeararr:[],yearlabel:'Select Year',
	 
	  order:'desc' ,orderBy:'',studentname:'',fathername:'',stcode:'',parents:'',displaytype:'',rolelabel:'Select Phone No.',searchcustomer:'',
	  Batcharr:[],batchid:'',batchlabel:'Select Batch',Classarr:[],classid:'',classlabel:'Select Class',strsearch:'',
	          
      }
       this.updateDonenewParent = this.updateDonenewParent;
	   
	}
	
	updateDonenewParent = (text) => {this.apicall(this.state.page,this.state.rowsPerPage); }
	
	Search(){
	this.setState({  
      page: 0,
      rowsPerPage:50,
	  prevpage:0,Studentgegdata:[]
    });
	
//this.componentDidMount();  
}
			componentWillMount() { 
localStorage.setItem("pageactive",10)
}	




componentDidMount() {


	document.title = "MM Coching Center - Student Management";
	
	if(localStorage.getItem("MMCuserid")==null)
		  {

		   this.setState({
            redirect: true,
			pagename:'/Login'
          })
		  }
	if(localStorage.getItem("MMCrole")==="1")
	 
		  {
	this.BranchBind();
		  }
	this.ClassBind();
	
	  localStorage.setItem("page",this.state.rowsPerPage)
  this.apicall(this.state.page,this.state.rowsPerPage);
 
} 





handleClickSearch=()=>{   
	
		
  this.apicall(this.state.page,this.state.rowsPerPage); 

	
} 

myChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
 	   this.setState({[nam]: val}, () => {
if( this.state.classid!="0")
	   {
		this.BatchBind();      
 
	   }
}); 
 
}
ClassBind()
{
	axios.get(Serverurl+'ajaxtrustandclass.php?action=getall&tc_type=2')
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
						Classarr:resp.data	
							}) 
			}
	
	this.YearBind();
	   }) .then(data => {
        let classFromApi = this.state.Classarr.map(row => {
                          
          return {value: row.tc_id, display:row.tc_title}
        });
      
		
        this.setState({
          Classarr: [{value: '0', display: 'Select Class'}].concat(classFromApi)
        });
      })

}

YearBind()
{
	axios.get(Serverurl+'ajaxtrustandclass.php?action=getall&tc_type=3')
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
						Yeararr:resp.data,yearid:localStorage.getItem("MMCyearid")	
							}) 
			}
		this.BatchBind();
	   }) .then(data => {
        let yearFromApi = this.state.Yeararr.map(row => {
                          
          return {value: row.tc_id, display:row.tc_title}
        });
      
		
        this.setState({
          Yeararr: yearFromApi
        });
      })

}
BatchBind()
{
	axios.get(Serverurl+'ajaxbatch.php?action=list&bc_class_id='+this.state.classid+'&bc_br_id='+localStorage.getItem("MMCbranchid"))
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
						Batcharr:resp.data	
							}) 
			}else{
				
				this.setState({
						Batcharr:[]	
							}) 
			}

	   }).then(data => {
        let batchFromApi = this.state.Batcharr.map(row => {
                          
          return {value: row.bc_id, display:row.bc_title}
        });
      
		
        this.setState({
          Batcharr: [{value: '0', display: 'Select Batch'}].concat(batchFromApi)
        });
      })

}

apicall(page,rowsPerPage){
	this.setState({btndisabled:true,btnsearchtext:'Processing...'  });  
	 let strbranch=localStorage.getItem("MMCbranchid");
	 if(localStorage.getItem("MMCrole")=="1")
	 
		  {
			  strbranch=this.state.branchid;
		  }
		  
	
		  
	 axios.get(Serverurl+'ajaxstudentregistration.php?action=report&branchid='+strbranch+'&sr_pr_id='+this.state.parents+'&sa_bc_id='+this.state.batchid+'&sa_class_id='+this.state.classid+'&sr_studentname='+this.state.studentname+'&sr_fathername='+this.state.fathername+'&sr_studentcode='+this.state.stcode+'&StartIndex='+page+'&PageSize='+rowsPerPage+'&sa_year_id='+this.state.yearid)
     
	
  .then(resp => {  
		
    if(resp.data.length>0){
		let copy=[ 100,  200, 300, 400, 500];
			this.setState({  
			  Studentgegdata: resp.data,  
			   totaldata:parseInt(resp.data[0].count),
			   rowsPaging:copy,btndisabled:false,btnsearchtext:'Search'

		    });
			
			
	 
			
	  
	 
	 
     }
	 else{
		   this.setState({Studentgegdata:[],totaldata:0,btndisabled:false,btnsearchtext:'Search'});
	 }
 	
			
    });
}


BranchBind()
{
	axios.get(Serverurl+'ajaxbranch.php?action=getall')
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
						Brancharr:resp.data	
							}) 
			}
	
	   }) .then(data => {
        let branchFromApi = this.state.Brancharr.map(row => {
                          
          return {value: row.br_id, display:row.br_title}
        });
      
		
        this.setState({
          Brancharr: [{value: '0', display: 'Select Branch'}].concat(branchFromApi)
        });
      })

}



handleChangePage = (event, newPage) => {  
   
    let pageval = localStorage.getItem("page");
    this.setState({  
      page: newPage,
      rowsPerPage:parseInt(pageval)
    },()=>{this.apicall(this.state.page,this.state.rowsPerPage);});
   
 
  
  };  
  		componentWillMount() { 
localStorage.setItem("pageactive",10)
}	
  
   handleChangeRowsPerPage = event => {  
  
    let pageval = event.target.value;
    localStorage.setItem("page",pageval)

  
    this.setState({  
      page: 0,
      rowsPerPage:parseInt(pageval),
	  prevpage:-1
    },()=>{this.apicall(this.state.page,this.state.rowsPerPage);}); 
	
	
  }; 
  
  
  handleRequestSort = (event, property) => {
  const isAsc = this.state.orderBy === property && this.state.order === 'asc';
  

  this.setState({   orderBy: property,
    order:isAsc  ? 'desc' : 'asc'
   
  });
};


   
	
 	

    Parentaddbox(val){
		
        this.setState({ srprid: val });  
  
         setTimeout(() => this.refs.AddParentbox.handleClickOpen(),500);

      } 
	   Completeaddbox(val){
        this.setState({ said: val });  
  
         setTimeout(() => this.refs.AddCompletebox.handleClickOpen(),500);

      } 
onClickEditStud(val){
	localStorage.setItem("MMEdSrId",val);
        this.setState({ redirect:true,pagename:'/StudentRegistration' });  
  
        

      } 
	  
	  
	  

Studentissuspend(srid,val){
	let strmsg="Active";
	 if(val===0)
	 {
		strmsg="In Active"; 
	 }
	 let r = window.confirm("Do you really want to "+strmsg+" ?");
		 if(r == true){ //sign Out logic
				 var formData = new FormData(); 
					 formData.append('action','issuspend')
					 formData.append('sr_id',srid)
					 formData.append('sr_issupend',val)
	formData.append('userid',localStorage.getItem("MMCuserid"))
					 
					  axios.post(Serverurl+"ajaxstudentregistration.php", formData, {
						}).then(res => {
					
							  this.apicall(this.state.page,this.state.rowsPerPage); 

				  
					
					
							});
		 }
 
 } 

 
	  
	  
	  
	  
render() {

if (this.state.redirect === true) {
      return <Navigate to={this.state.pagename} />
    }


  return (
  
  
  



 
  <React.Fragment>
   
<div className="containermain">
<div className="boxheading">
				  <div className="contntpad">
<h2><img src={pageimg}/>Student Management</h2>
</div></div>
<div className="searchmaster">


  
  <ul>

  
   {localStorage.getItem("MMCrole")==="1" && (
	  <li><p>Branch</p>
	  <p>
	  <FormControl fullWidth  size="small">
       
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.branchlabel, value: this.state.branchid }}
          onChange={this.myChangeHandler}
          id="state"
		   value={this.state.branchid}
		  name="branchid"
		
		
        >
		{this.state.Brancharr.map(row => (
            <option  
            
              value={row.value}
            >
              {row.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
	  
	  </li>)}
	   <li><p><span className="validation">*</span>Class</p>
	  <p>
	  <FormControl fullWidth  size="small">
       
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.classlabel, value: this.state.classid }}
          onChange={this.myChangeHandler}
          id="state"
		   value={this.state.classid}
		  name="classid"
		
		
        >
		{this.state.Classarr.map(row => (
            <option  
            
              value={row.value}
            >
              {row.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
	  
	  </li>
	  <li><p><span className="validation">*</span>Year</p>
	  <p>
	  <FormControl  fullWidth size="small" >
        
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.yearlabel, value: this.state.yearid }}
          onChange={this.myChangeHandler}
          id="state"
		   value={this.state.yearid}
		  name="yearid"
		
		
        >
		{this.state.Yeararr.map(row => (
            <option  
            
              value={row.value}
            >
              {row.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
	  
	  </li>
	  <li><p><span className="validation">*</span>Batch</p>
	  <p>
	  <FormControl  fullWidth size="small" >
       
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.batchlabel, value: this.state.batchid }}
          onChange={this.myChangeHandler}
          id="state"
		   value={this.state.batchid}
		  name="batchid"
		
		
        >
		{this.state.Batcharr.map(row => (
            <option  
            
              value={row.value}
            >
              {row.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
	  
	  </li>
	  
	  
	  <li><p>Student Name</p>
 <p ><TextField
            variant="outlined"
            margin="normal"
            fullWidth value={this.state.studentname}
           inputProps={{tabIndex: "2",maxLength:150}}
			placeholder="Student Name"
            name="studentname" onChange={this.myChangeHandler}
		
          /></p></li>
	  
	  
     <li><p>Student Code</p>
 <p ><TextField
            variant="outlined"
            margin="normal"
            fullWidth value={this.state.stcode}
           inputProps={{tabIndex: "4",maxLength:150}}
			placeholder="Student Code"
            name="stcode" onChange={this.myChangeHandler}
		
          /></p></li>
 <li><p>Phone No.</p>
 <p ><TextField
            variant="outlined"
            margin="normal"
            fullWidth value={this.state.parents}
           inputProps={{tabIndex: "2",maxLength:150}}
			placeholder="Phone No."
            name="parents" onChange={this.myChangeHandler}
		
          /></p></li>
		
	 
 
		
	
			
		</ul>
	
	<div className="searchnewbtn">
		<p className="searchbutton">
		<Button variant="contained" disabled={this.state.btndisabled} onClick={this.handleClickSearch} className={useStyles.submit} endIcon={<SearchIcon />}>
			{this.state.btnsearchtext}
      </Button></p>
	
	  
	
				
</div>	
		
		
		
</div>		

   


 <div ref={el => (this.componentRef = el)} >
<Paper className={useStyles.root}>


  <TableContainer className={useStyles.container}>  
                <Table stickyHeader aria-label="sticky table" >  
<TableHead> 
<TableRow>
                                              
                       
						
                      </TableRow></TableHead>
					  
					   <EnhancedTableHead           
              order={this.state.orderBy}
              orderBy={this.state.order}
              
              onRequestSort={this.handleRequestSort}
              rowCount={this.state.Studentgegdata.length}
            />
                      <TableBody>
                     {stableSort(this.state.Studentgegdata, 
			getSorting(this.state.order,
			this.state.orderBy)).map((student,index) => {
let amount=parseFloat(student.totalfee	)-parseFloat(student.balamt)	
let i= parseFloat(((this.state.page)+1)-1)*parseFloat(this.state.rowsPerPage)+(parseFloat(index)+1);
           return (
 <React.Fragment>
 <TableRow key={i} >

<TableCell>{i}</TableCell>
<TableCell>{student.sr_studentcode}</TableCell>
<TableCell>
<div className="container">

<span className="hover-text">{student.sr_studentname}</span>
<span className="profileimg"><img src={Serverurl+'uploads/'+student.sr_profileimg} /></span></div>
</TableCell>
<TableCell>{student.sr_fathername}
	{student.sr_mothername!="" && ( <React.Fragment>
<br/>Mother:{student.sr_mothername}
	 </React.Fragment>)}
</TableCell>


<TableCell>{student.pr_phonenumber}</TableCell>


<TableCell>{student.sr_emailid}</TableCell>
<TableCell>{student.tc_title}</TableCell>
<TableCell>{student.bc_title}</TableCell>
<TableCell>{student.sacomplete}</TableCell>

 
<TableCell>
 <React.Fragment>
 <a href={'#/Studentviewpage?srid='+student.sr_id} className="frmsecondarybtn" >

<VisibilityIcon />
</a>
 {student.sr_issupend ==="0" &&(
<Button variant="contained" className="frmPrimarybtn" onClick={()=>{this.Studentissuspend(student.sr_id,1)}}  >
In Active
</Button>

 )}

	
{student.sr_issupend ==="1" &&(

	<Button variant="contained" className="admsndisablebtn" onClick={()=>{this.Studentissuspend(student.sr_id,0)}} >
Active
</Button>

)}
 </React.Fragment>

</TableCell>




 </TableRow>

 </React.Fragment>	
);
					})}
					
					{this.state.totaldata===0 &&(
					
					<TableRow key={0} >
<TableCell align="center" colSpan="6">No Records</TableCell>
					</TableRow>
					) }
					
                      </TableBody>


</Table>
  </TableContainer> 
  <TablePagination  
        rowsPerPageOptions={[100,  200, 300, 400, 500]}  
        component="div"  
        count={this.state.totaldata}  
        rowsPerPage={this.state.rowsPerPage}  
        page={this.state.page}  
        onPageChange={this.handleChangePage}  
        onChangeRowsPerPage={this.handleChangeRowsPerPage}  
      />   
  </Paper>
  
  
  
   <div className="exporttab">
  
   </div>
  </div>	  </div>  

 </React.Fragment> 

 
 
  );
}
}