import React ,{Component,Children} from 'react'; 
import ReactDOM from "react-dom";
import { Bar } from "react-chartjs-2";
import {Chart, Filler} from 'chart.js';
import { useOutletContext,Navigate  } from 'react-router-dom';
import axios from 'axios';
import Serverurl from './Apiurl';
import { Calendar, momentLocalizer } from "react-big-calendar";
import prof from './images/test-result.png';
import "react-big-calendar/lib/css/react-big-calendar.css";
import notification from './images/notification-icon.png';
import moment from "moment";
moment.locale("en-GB");
const localizer = momentLocalizer(moment);
export default class StudentAttendance extends Component {
	
	constructor(props) {
    super(props);
	
	
    this.state = { studentarray:[],refresh:'',attanData:[],drpacoretype:'1'}
	
	}
		myChangeHandler = (event) => {		
		const re = /^[0-9\b]+$/;
  let nam = event.target.name;
  let val = event.target.value;	 
this.setState({[nam]: val}); 

}
	componentWillReceiveProps(props) {
		this.setState({refresh : props.refresh });
	let said=this.props.said;
	
	this.AttendanceGraphApi(said);
this.AttenAPIcall(said);	
	}
	
	 handleSelect=(start, end)=>{
	/*
    const title = window.prompt("New Event name");

     const updatedArray = [...this.state.eventsData];
	 updatedArray.title=title;
	 	 updatedArray.start=start;
		 updatedArray.end=end;
	 this.setState({eventsData:updatedArray});*/
 }

AttenAPIcall(said)
{
	
axios.get(Serverurl+'ajaxattendance.php?action=Attdcinsumary&ad_sa_id='+said)
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({attanData:resp.data});
			}else{
				this.setState({attanData:[]});
			}
	   });
}
customDayPropGetter = (date) => {

  let data = this.state.attanData
  
   var month="",date1="";
	
	 if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)}
	 
	 
	  if((date.getDate() )<10)
	 {
		date1="0"+ (date.getDate() )		 
	 }else{date1=(date.getDate())}
  
  date=date.getFullYear()+"-"+month+"-"+date1
   var obj = data.find((item, index) => {
	  
	   
	   
	   
	     if ((item.logtim === date && item.title == 0) ||(item.logtim === date && item.title == 1) )
		 {
			 
			   return data[index]
		 }
   });
  
    if(obj !== undefined && obj.logtim === date &&  obj.title === '0')
	{
	 
        return { style: { backgroundColor: "#fc595a", color:'white' } ,className: 'tuesday', }
	}
        else if(obj !== undefined && obj.logtim === date && obj.title === '1')
		{
        return { style: { backgroundColor: "#fff", color:'white' },className: 'tuesday', }
}
        else {return { style: { backgroundColor: "#007aff", color:'white' } }}
   
	/*return {
	  style: {
		backgroundColor: '#fff', //this works
		color: '#ffffff' //but why doesn't this work?
	  }
	}*/
}
 eventStyleGetter=(event,start, end,isSelected)=>{
var backgroundColor = '#fff' ;
var color='#ff5e3d';
  if(event.title=="0")
  {
	 backgroundColor = '#fc595a' ; 
	 color='#fff';
  }
    else if(event.title=="1")
  {
	 backgroundColor = '#007aff' ;
color='white';
  } else{
	  
	 backgroundColor = '#007aff' ;
color='#fff';
	  
  }
  /*
    var style = {
        backgroundColor: backgroundColor,
        borderRadius: '0px',
        opacity: 0.8,
        color: color,
        border: '0px',
        display: 'block',
		textalign:'Center',
    };
    return {
        style: style
    };
	*/
}
	AttendanceGraphApi(said){
		
		axios.get(Serverurl+'ajaxattendance.php?action=attbystud&ad_sa_id='+said)
       .then(resp => { 		
			if(resp.data.length>0){
				
				
				let optionsRep1 = resp.data;
		let prev= "",prevsub="",testtitle="";
	
		let hsub1=[];
		//this.setState({ studentarray:resp.data});
			let monthlist="",strsep="",monarr=[];
	monarr[0] = [];
	 let edcell1=[], cellattper=[];
	let edrow1=[];
		  {optionsRep1.map((atrow, index) => {
		 var strdataset="";
		
		
			let j=parseInt(index)-1;
			
			
		
			cellattper.push(atrow.per);
		
 monarr[0].push(atrow.month);
		  })};
		 
		
		  edcell1["label"]="";
			 edcell1["backgroundColor"]="#c0a2ec";
			  edcell1["borderColor"]="#c0a2ec";
			   edcell1["borderWidth"]=1; edcell1["fill"]=true;
			    edcell1["hoverBackgroundColor"]="#c0a2ec";
				 edcell1["hoverBorderColor"]="#c0a2ec";
				 edcell1["data"]=cellattper;
			edrow1.push(edcell1);
		const dataperformance1 = {
         datasets:edrow1,labels:monarr[0]
         
       
        };
			
	 this.setState({
        studentarray: dataperformance1,
    });
	
	
				
			}else{this.setState({studentarray:[]});}
	   });
							
	}
	
	render() {
		
	
 const options = {
		
		 tooltips: {
    mode: 'label'
  }, 
      responsive: true,
      legend: {
        display: false,
		 position:'right'
      },
	  title:{
                    display:true,
                   
                    fontSize:20
                    },
      type: "bar",
	  scales: {
      yAxes: [{
         ticks: {
            beginAtZero: true,
			max: 100,
                min: 0
         }
      }]
   }
     
    };
	 return (
  
	 <React.Fragment> 
	 
	 <div className="studentpanel">
		<div className="boxheading">
		<div className="admitpad"> <h2><img src={prof} />Attendance
		<select id="drpacoretype" name="drpacoretype" className="dropalign"  onChange={this.myChangeHandler}  value={this.state.drpoptype}>
		<option value="1" >Brief</option>		<option value="2" >Summary</option>
		</select>
		</h2></div></div><div className="admitpad studeattend">
	  {this.state.drpacoretype=="1" && (<React.Fragment> <Bar id="myattchart" 
	  
	  data={this.state.studentarray}
        width={null}
        height={null}
       options={options}
      />
	  </React.Fragment>)} {this.state.drpacoretype=="2" && (<React.Fragment> 
	  
	  <Calendar
        views={[ "month"]}
        selectable
        localizer={localizer}
        defaultDate={new Date()}
        defaultView="month"
        events={this.state.attanData}
        style={{ height: "40vh" }}
   
    
		 eventPropGetter={(this.eventStyleGetter)}
		 
		components={{
        month: {
          dateHeader: ({ date, label }) => {
           let data = this.state.attanData
  
   var month="",date1="";
	
	 if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)}
	 
	 
	  if((date.getDate() )<10)
	 {
		date1="0"+ (date.getDate() )		 
	 }else{date1=(date.getDate())}
  
  date=date.getFullYear()+"-"+month+"-"+date1
   var obj = data.find((item, index) => {
	   
	   
   if ((item.logtim === date && item.title == 0) ||(item.logtim === date && item.title == 1) )
		 {
			 
			   return data[index]
		 }
   });
  
    if(obj !== undefined && obj.logtim === date &&  obj.title === '0')
	{
	 
        return (   <h5 style={ { backgroundColor: "#fc595a",color: "white" } }>{label}</h5>)
	}
        else if(obj !== undefined && obj.logtim === date && obj.title === '1')
		{
        return (   <h5 style={ { backgroundColor: "#007aff", color: "white" } }>{label}</h5> )
}
        else {return (  <h5 style={ { backgroundColor: "#fbfbfb", color: "black" } }>{label}</h5> )}
		  
          }
        }
      }}
        
      />  
	  <ul className="colorindication">
	  <li><span></span> Class Not Taken</li>
	   <li><span></span> Absent</li>
	    <li><span></span> Present</li>
	  </ul>
	  
	  
	  </React.Fragment>)} 
	   </div>
	  </div>
	 </React.Fragment> 
	 
	 )
	 
	}
}