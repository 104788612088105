import React, { Component } from "react";
import {params, BrowserRouter as Router, Navigate, Routes,NavLink,Outlet } from 'react-router-dom';
import styles from './styles/styles.css';
import logo from './images/weblogo.png';
import axios from 'axios';
import { slideDown, slideUp } from './anim';
import Serverurl from './Apiurl';
	const UserContext = React.createContext()
export default class MobileLayout extends Component {
	
	constructor(props) {
    super(props);
	
	 this.state = {heading:'',redirect:false,pagename:'',expanded:false,menustudlist:[],displacn:'',studentname:'',studeprof:'',studsaid:'',ismenuopen:false}
	 
	}

	
	componentDidMount() {

	this.StudentApicall();
  }
   MainMenuClick  ()  {
	this.setState({ismenuopen:!this.state.ismenuopen});	   
   }
  
  MenuClick  (said,srid)  {
	localStorage.setItem("MMCstudreid",srid)
	localStorage.setItem("MMCstudid",said)
	
	window.location.reload();
  }
  
  
toggleExpander  (val)  {


  if (!this.state.expanded) { 
 
   this.setState(
      { expanded: val },
      () => {
		   slideDown(this.refs.expanderBody);
        /*if (this.refs.expanderBody) {
          slideDown(this.refs.expanderBody);
        }*/
      }
    );
  } else {
	  this.setState({ expanded: false });
	  /*
    slideUp(this.refs.expanderBody, {
      onComplete: () => { this.setState({ expanded: false }); }
    });*/
  }
} 
  StudentApicall()
  {
	  
	  axios.get(Serverurl+'menu.php?sr_pr_id='+localStorage.getItem("MMCPrid"))
       .then(resp => { 		
			if(resp.data.length>0){
				let found=[];
				if(localStorage.getItem("MMCstudreid")!==null){
				 found = resp.data.find(obj => {
    return obj.sr_id === localStorage.getItem("MMCstudreid");
  });
}


				this.setState({studentname:resp.data[0]["sr_studentname"],
				studeprof:Serverurl+'uploads/'+resp.data[0]["sr_profileimg"],studsaid:resp.data[0]["subcat"][0]["sa_id"],
        menustudlist: resp.data,
   }, () => {
	   
	   this.setState({studentname:found.sr_studentname,
   studeprof:Serverurl+'uploads/'+found.sr_profileimg});
   
   });
				
			}
	
	   });
  }
  
  
		render() {
			
	if (this.state.redirect === true) {
      return <Navigate to={this.state.pagename} />
	  
	  
    }

		return (
		<React.Fragment>
		
		<div className="headertopbg">
			<div className="main">
				<div className="moblogo">
				<img src={logo} />
				</div>
				<div className="userprofile">
				<div className="profileselection" onClick={()=>{this.MainMenuClick()}}>
				<p><img src={this.state.studeprof} />{this.state.studentname}</p>
				</div>
				<div className="clear"></div>
				{this.state.ismenuopen===true && (
				<div className="profiledrop">
				  {this.state.menustudlist.length>0 &&(
			 <ul>
	{this.state.menustudlist.map((item,index) => { return (
	
	 <li key={index}><p onClick={() => {this.toggleExpander(index)}}>
	{item.sr_profileimg==="" && (
	<img src={Serverurl+'uploads/user.jpg'} />
	)}
	{item.sr_profileimg!=="" && (
	<img src={Serverurl+'uploads/'+item.sr_profileimg} />
	)}
	{item.sr_studentname}</p>
	 {this.state.expanded===index && (<React.Fragment>
	<ul className="expandable" ref={"expanderBody"} key={"tr-expander"}>
	&#x1F4C5;{item.subcat.map((roweach,i) => {
		  return (  <React.Fragment>
		  <li key={i} onClick={()=>{this.MenuClick(roweach.sa_id,item.sr_id)}}>
	
	{roweach.tc_title}
	</li>
		  </React.Fragment> );
	 })}
				</ul>
	</React.Fragment>			
	 )}
</li>

				);
				 
			 
				 
				 
				 })}
	</ul>
	 )}
			</div>)}	</div>
			
				<div className="clear"></div>
			</div>
		</div>
		
		<div className="headingbg">
			<div className="main">
				<div className="headingalign">
					<h2>  <Outlet />  </h2>
					<p><a href="#/MobStudentDashbaord">Home</a> :: <Outlet /></p>
				
				</div>
			</div>
		</div>
		</React.Fragment>
		)
		
		}
}









