import React ,{Component} from 'react'; 
import { Navigate  } from 'react-router-dom';
import axios from 'axios'; 
import Table from '@mui/material/Table';
import Serverurl from './Apiurl';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow'; 
import { makeStyles } from '@mui/styles'; 
import Paper from '@mui/material/Paper';  
import TableSortLabel from '@mui/material/TableSortLabel';
import TableBody from '@mui/material/TableBody'; 
import TableCell from '@mui/material/TableCell';  
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';  
import TablePagination from '@material-ui/core/TablePagination'; 
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';

import DialogContent from '@mui/material/DialogContent';

import DialogTitle from '@mui/material/DialogTitle';

import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import pageimg from './images/attendance.png';
import GolfCourseIcon from '@mui/icons-material/GolfCourse';

const headCells = [
  
  { id: 'te_testname', numeric: false, label: 'Test Name' },
  { id: 'te_maxmarks', numeric: false, label: 'Max Marks' },
  { id: 'br_title', numeric: false, label: 'Branch' },
  { id: 'tc_title', numeric: false, label: 'Class' },
    { id: 'yeartitle', numeric: false, label: 'Year' },
    { id: 'status', numeric: false, label: 'Status' },
	
	
   { id: '',  label: '' },
  ]
  function stableSort(array, cmp) {
	  const stabilizedThis = array.map((el, index) => [el, index]);
	  stabilizedThis.sort((a, b) => {
		const order = cmp(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	  });
	  return stabilizedThis.map(el => el[0]);
}
function desc(a, b, orderBy) {
	  if (b[orderBy] < a[orderBy]) {
		return -1;
	  }
	  if (b[orderBy] > a[orderBy]) {
		return 1;
	  }
	  return 0;
}
function getSorting(order, orderBy) {
	return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

function EnhancedTableHead(props) {
  const { classes,  order, orderBy,  rowCount, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={useStyles.visuallyHidden}>
                  {order === 'desc' ? ' ' : ' '}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
 
  onRequestSort: PropTypes.func.isRequired,
 
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  
  
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  };

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 
 icon: {
   verticalalign:'middle',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
});


export default class testmaster extends Component {
	
    constructor(props) {
    super(props);
   
      this.state = {redirect: false,
      Testdata:[],teid:'',page: 0 ,prevpage:0,totaldata:0,rowsPerPage:50,rowsPaging:[],
	 
	  order:'desc' ,orderBy:'',testnames:'',Brancharr:[],branchid:'',Classarr:[],classlabel:'Select Class', classid:'',branchlabel:'Select Branch',searchcustomer:'',teststatus:'',
	          
      }
       this.updateDonenewParent = this.updateDonenewParent;
	   
	}
	
	updateDonenewParent = (text) => {this.componentDidMount();  }
	
	Search(){
	this.setState({  
      page: 0,
      rowsPerPage:50,
	  prevpage:0,Testdata:[]
    });
	
//this.componentDidMount();  
}
		componentWillMount() { 
localStorage.setItem("pageactive",5)
}	
componentDidUpdate() {
	
  if(this.state.prevpage !== this.state.page) {
    this.setState({   prevpage: this.state.page});
  this.apicall(this.state.page, localStorage.getItem("page"));  
  }
}




	componentDidMount() {
	
	
	document.title = "MM Coching Center - Test Management";
	
	if(localStorage.getItem("MMCuserid")==null)
		  {

		   this.setState({
            redirect: true,
			pagename:'/Login'
          })
		  }
	
	
	
	  localStorage.setItem("page",this.state.rowsPerPage)
  this.apicall(this.state.page,this.state.rowsPerPage);
  //this.onloadbatchbind();
  if(localStorage.getItem("MMCrole")==="1")
	 
		  { this.BranchBind();
		  }
  this.ClassBind();
} 

/*
 onloadbatchbind(){

    axios.get(Serverurl+'ajaxbatch.php?action=list')
	
    
      .then((response) => {    
     
      this.setState({  batcharray: response.data,  });
      })   
    
      .then(data => {
        let roleFromApi = this.state.batcharray.map(batch => {
                            
          return {value: batch.bc_id, display:batch.bc_title}
        });
      
		
        this.setState({
          batcharray: [{value: '', display: 'Select Batch'}].concat(roleFromApi)
        });
      })


} 
*/
ClassBind()
{
axios.get(Serverurl+'ajaxtrustandclass.php?action=getall&tc_type=2')
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
						Classarr:resp.data	
							}) 
			}
		
	   }) .then(data => {
        let classFromApi = this.state.Classarr.map(row => {
                          
          return {value: row.tc_id, display:row.tc_title}
        });
      
		
        this.setState({
          Classarr: [{value: '0', display: 'Select Class'}].concat(classFromApi)
        });
      })

} 
BranchBind()
{
axios.get(Serverurl+'ajaxbranch.php?action=getall')
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
						Brancharr:resp.data	
							}) 
			}
	 
	   }) .then(data => {
        let branchFromApi = this.state.Brancharr.map(row => {
                          
          return {value: row.br_id, display:row.br_title}
        });
      
		
        this.setState({
          Brancharr: [{value: '0', display: 'Select Branch'}].concat(branchFromApi)
        });
      })

}
handleClickSearch=()=>{   
 
  
  this.apicall(this.state.page,this.state.rowsPerPage); 

	
} 

myChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
  this.setState({[nam]: val});
 
}

apicall(page,rowsPerPage){
	let strbranch=this.state.branchid;
	 if(localStorage.getItem("MMCrole")!=="1")
	 
		  { 
			  strbranch=localStorage.getItem("MMCbranchid");
		  }
	 axios.get(Serverurl+'ajaxtest.php?action=getall&te_testname='+this.state.testnames+'&te_branch_id='+strbranch+'&te_status='+this.state.teststatus+'&classid='+this.state.classid+'&StartIndex='+page+'&PageSize='+rowsPerPage)
     
	
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  Testdata: resp.data,  
			   totaldata:parseInt(resp.data[0].count),
			   

		    });
		
			
			let copy=[50, 100, 150, 200, 250];
			
	  
	  this.setState({rowsPaging:copy});
	 
     }
	 else{
		   this.setState({Testdata:[],totaldata:0});
	 }
 
    });
}





handleChangePage = (event, newPage) => {  
   
    let pageval = localStorage.getItem("page");
    this.setState({  
      page: newPage,
      rowsPerPage:parseInt(pageval)
    });
   
 
    setTimeout(() =>  this.componentDidUpdate() ,500); 
  };  
  
  
   handleChangeRowsPerPage = event => {  
  
    let pageval = event.target.value;
    localStorage.setItem("page",pageval)

    setTimeout(() =>  this.componentDidUpdate() ,500);
    this.setState({  
      page: 0,
      rowsPerPage:parseInt(pageval),
	  prevpage:-1
    }); 
	
	
  }; 
  
  
  handleRequestSort = (event, property) => {
  const isAsc = this.state.orderBy === property && this.state.order === 'asc';
  

  this.setState({   orderBy: property,
    order:isAsc  ? 'desc' : 'asc'
   
  });
};

 testStatus(teid){  
	
let r = window.confirm("Do you really want to Announce?");
 if(r === true){ //sign Out logic
 var formData = new FormData();
     formData.append('action','status')
	 formData.append('te_id',teid)
	 	    formData.append('te_status',1)

	
	
	 axios.post(Serverurl+"ajaxtest.php", formData, {
        }).then(res => {
    
             this.setState({  
      prevpage: -1,
    
    });
   
 
    setTimeout(() =>  this.componentDidUpdate() ,500); 
  
    
            });
 }
      }
      testDelete(teid){  
	
let r = window.confirm("Do you really want to Delete?");
 if(r === true){ //sign Out logic
 var formData = new FormData();
     formData.append('action','delete')
	 formData.append('te_id',teid)
	 	   
formData.append('userid',localStorage.getItem("MMCuserid"))	
	
	
	 axios.post(Serverurl+"ajaxtest.php", formData, {
        }).then(res => {
    
             this.setState({  
      prevpage: -1,
    
    });
   
 
    setTimeout(() =>  this.componentDidUpdate() ,500); 
  
    
            });
 }
      }
      Testaddbox(val){
        this.setState({ teid: val });  
  
         setTimeout(() => this.refs.AddTestbox.handleClickOpen(),500);

      }

render() {

if (this.state.redirect === true) {
      return <Navigate to={this.state.pagename} />
    }


  return (
  
  
  



 
  <React.Fragment>
   
<div className="containermain">
<div className="boxheading">
				  <div className="contntpad">
<h2><img src={pageimg}/>Test Management</h2>
</div></div>

   <div className="searchmaster">


  
  <ul>
  
 <li>
 <p ><TextField
            variant="outlined"
            margin="normal"
            fullWidth value={this.state.testnames}
           inputProps={{tabIndex: "1",maxLength:150}}
			placeholder="Test Name"
            name="testnames" onChange={this.myChangeHandler}
		
          /></p></li>
		
		  {localStorage.getItem("MMCrole")==="1" && (<React.Fragment>
			<li><p> <FormControl fullWidth  size="small">
       
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.branchlabel, value: this.state.branchid }}
          onChange={this.myChangeHandler}
          id="state"
		   value={this.state.branchid}
		  name="branchid"
		
		
        >
		{this.state.Brancharr.map(row => (
            <option  
            
              value={row.value}
            >
              {row.display}
            </option  >
          ))}
		
        </Select>
 </FormControl>
		
				</p>
	
		  </li></React.Fragment> )}
	<li>
	 <p>
	  <FormControl fullWidth  size="small">
       
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.classlabel, value: this.state.classid }}
          onChange={this.myChangeHandler}
          id="state"
		   value={this.state.classid}
		  name="classid"
		
		
        >
		{this.state.Classarr.map(row => (
            <option  
            
              value={row.value}
            >
              {row.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
	</li>
	<li>	
	
	<p>
		<FormControl  className="stadrp">			   
		<Select
		variant="outlined"
		native
		value={this.state.teststatus}
		onChange={this.myChangeHandler}
		name='teststatus'
		id='teststatus'
		InputLabelProps={{
		shrink: true,
		}}
		>
		<option value="">Select Status</option>
		<option value={1}>Result Announced</option>
		<option value={0}>Waiting</option>		
		</Select>
		</FormControl>
	</p>
	</li>
		</ul>
		<div className="searchnewbtn">
		<p className="searchbutton">
		<Button variant="contained" onClick={this.handleClickSearch} className={useStyles.submit} endIcon={<SearchIcon />}>
        Search
      </Button>	</p>
	  <p className="addnewbtn"><Button variant="contained" onClick={()=>{this.Testaddbox(0)}} className="pointerbtn" endIcon={<AddIcon />}>
        New
      </Button></p>
	  
		</div>	
		
		
		
		
		
</div>		



<AddTestbox ref="AddTestbox" id={this.state.teid}  updateDonenew={this.updateDonenewParent}/>
<Paper className={useStyles.root}>


  <TableContainer className={useStyles.container}>  
                <Table stickyHeader aria-label="sticky table">  
<TableHead> 
<TableRow>
                                              
                       
						
                      </TableRow></TableHead>
					  
					   <EnhancedTableHead           
              order={this.state.order}
              orderBy={this.state.orderBy}
              
              onRequestSort={this.handleRequestSort}
              rowCount={this.state.Testdata.length}
            />
                      <TableBody>
                     {stableSort(this.state.Testdata, 
			getSorting(this.state.order,
			this.state.orderBy)).map((item,index) => {  
           return (
 <React.Fragment>
 <TableRow key={index} >



<TableCell>{item.te_testname}</TableCell>
<TableCell>{item.te_maxmarks}</TableCell>
<TableCell>{item.br_title}</TableCell><TableCell>{item.tc_title}</TableCell>
<TableCell>{item.yeartitle}</TableCell>
<TableCell>{item.status}</TableCell>

<TableCell align="left">
<div className="actionbtns">

{localStorage.getItem("MMCrole")==="1" && (<React.Fragment>
<Button variant="contained" className="frmPrimarybtn" onClick={()=>{this.Testaddbox(item.te_id)}} endIcon={<EditIcon />}>
Edit
</Button></React.Fragment>
)}
{(localStorage.getItem("MMCrole")!=="1" && item.te_status==="0" )&&  (<React.Fragment>
<Button variant="contained" className="frmPrimarybtn" onClick={()=>{this.Testaddbox(item.te_id)}} endIcon={<EditIcon />}>
Edit
</Button></React.Fragment>
)}

{item.te_status==="0" && (<React.Fragment>

<Button variant="contained" className="frmPrimarybtn" onClick={()=>{this.testStatus(item.te_id)}} endIcon={<GolfCourseIcon />}>
  Not Announced
</Button>
<Button variant="contained" className="admsndisablebtn" onClick={()=>{this.testDelete(item.te_id)}} endIcon={<DeleteIcon />}>
  Delete
</Button></React.Fragment>
)}



 </div>
 
 </TableCell>
 </TableRow>

 </React.Fragment>	
);
					})}
					
					{this.state.totaldata===0 &&(
					
					<TableRow key={0} >
<TableCell align="center" colSpan="3">No Records</TableCell>
					</TableRow>
					) }
					
                      </TableBody>


</Table>
  </TableContainer> 
  <TablePagination  
        rowsPerPageOptions={[50, 100, 150, 200, 250]}  
        component="div"  
        count={this.state.totaldata}  
        rowsPerPage={this.state.rowsPerPage}  
        page={this.state.page}  
        onPageChange={this.handleChangePage}  
        onChangeRowsPerPage={this.handleChangeRowsPerPage}  
      />   
  </Paper>
    
</div>
 </React.Fragment> 

 
 
  );
}
}


export  class  AddTestbox extends Component {

   constructor(props) {
      super(props)
	  
      this.state = {
		  
		   open:false,testname:'',te_id:0,Brancharr:[],branch:'0',Classarr:[],classid:'0',classlabel:'Select Class',batcharray:[],batch:'',rolelabel:'Select Batch',maxmarks:'',errormessage:'',erropen:false,
	  }
      
  }
  
  
  
 onloadbatchbind(){

    axios.get(Serverurl+'ajaxbatch.php?action=list&bc_class_id='+this.state.classid)
	
    
      .then((response) => {    
     
      this.setState({  batcharray: response.data,  });
      })   
    
      .then(data => {
        let roleFromApi = this.state.batcharray.map(batch => {
                            
          return {value: batch.bc_id, display:batch.bc_title}
        });
      
		
        this.setState({
          batcharray: [{value: '0', display: 'Select Batch'}].concat(roleFromApi)
        });
      })
  
  
  }
  
  
 Testgetdata() {
	
  if(this.props.id!==0)
  {
	  
	   axios.get(Serverurl+'ajaxtest.php?action=getall&te_id='+this.props.id)
  .then(resp => {  
		
    if(resp.data.length>0){
    this.setState({ 
     
      te_id:resp.data[0].te_id,
	  testname: resp.data[0].te_testname,
      maxmarks: resp.data[0].te_maxmarks, 
    branch: resp.data[0].te_branch_id,  
      
    classid:resp.data[0].te_class_id, 
    });
	//this.onloadbatchbind();
  }
  
});
	  
  }else{
	 this.setState({  
	  testname:'',	 
	 maxmarks:'',
	te_id:0,
	 batch:''
	 
	  }); 
 }
  
    }

  ClassBind()
{
	axios.get(Serverurl+'ajaxtrustandclass.php?action=getall&tc_type=2')
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
						Classarr:resp.data	
							}) 
			}
		this.Testgetdata();
	   }) .then(data => {
        let classFromApi = this.state.Classarr.map(row => {
                          
          return {value: row.tc_id, display:row.tc_title}
        });
      
		
        this.setState({
          Classarr: [{value: '0', display: 'Select Class'}].concat(classFromApi)
        });
      })

} 
handleClickOpen = (e) => {
 
  this.setState({open:!this.state.open,Brancharr:[], branch:'0', testname:'',maxmarks:'',batcharray:[],batch:'',classid:'', 
     errormessage:'',erropen:false,
	  });this.ClassBind()
		
		


};
myChangeHandler = (event) => {
	
	  const re = /^[0-9\b]+$/;
  let nam = event.target.name;
  let val = event.target.value;  
 
 
  this.setState({[nam]: val}, () => {
  if(nam==="classid")
	   {
		   
 	//this.onloadbatchbind();
	   }
}); 
}
BranchBind()
{
axios.get(Serverurl+'ajaxbranch.php?action=getall')
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
						Brancharr:resp.data	
							}) 
			}
	 
	   }) .then(data => {
        let branchFromApi = this.state.Brancharr.map(row => {
                          
          return {value: row.br_id, display:row.br_title}
        });
      
		
        this.setState({
          Brancharr: [{value: '0', display: 'Select Branch'}].concat(branchFromApi)
        });
      })

}
 handleErrorclose=(event)=>{
	  
	this.setState({open: false});  
  }


handleClose = (e) => {
	
	
 let testname = this.state.testname;
 let maxmarks = this.state.maxmarks;
  let branch = this.state.branch;
   let classid = this.state.classid;

if(testname==="")
{
	
	this.setState({errormessage:"Please Enter Test Name",erropen:true});
		 window.scrollTo({
          top: 0
      })
		 return false;
	
	
}



if(maxmarks==="")
{
	
	this.setState({errormessage:"Please Enter Marks",erropen:true});
		 window.scrollTo({
          top: 0
      })
		 return false;
	
	
}
if(classid==="0")
{
	
	this.setState({errormessage:"Please Select Class",erropen:true});
		 window.scrollTo({
          top: 0
      })
		 return false;
	
	
}
if(localStorage.getItem("MMCrole")==="1")
{
	if(branch==="0")
	{
		this.setState({errormessage:"Please Select Branch",erropen:true});
		window.scrollTo({  top: 0 })
		return false;		
	}
}
   var formData = new FormData();
  
  
   if(this.state.te_id===0)
	  {
		  formData.append('te_id',0) 
		  
	  }
	  else
	  {
		  
	   formData.append('te_id',this.state.te_id)
		
          }
	  
	  
	  
	  
	 
        formData.append('action','insertorupdate') 
		
		formData.append('te_testname',testname)
		formData.append('te_maxmarks',maxmarks)
			formData.append('classid',classid)
		
		formData.append('te_yearid',localStorage.getItem("MMCyearid"))
		if(localStorage.getItem("MMCrole")==="1")
	 
		  {
					formData.append('te_branch_id',branch)  
		  }else{
		formData.append('te_branch_id',localStorage.getItem("MMCbranchid"))
		  }		
		formData.append('userid',localStorage.getItem("MMCuserid"))
		
		  
		axios.post(Serverurl+"ajaxtest.php", formData, {
        }).then(res => {
    
	
	
	if(res.data === -1)
{ 
	 

		this.setState({errormessage:"Test Name Already Exist.",erropen:true,open:true
		});
		window.scrollTo({
          top: 0
      })
		 return false;

	}
	
	
            this.props.updateDonenew();
    
        });
  
	
	this.setState({ open:false });
};

	 handleErrorclose=(event)=>{
	  
	this.setState({erropen: false});  
  } 

	


render (){

return(
<React.Fragment>





    <Dialog open={this.state.open} maxWidth="xs" fullWidth="true" onClose={this.handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title"> Test Management</DialogTitle>
      <DialogContent>
	  
<div className="formlist">

	<p>  <Collapse in={this.state.erropen}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
               this.handleErrorclose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          } severity="error"
        >
        {this.state.errormessage}
        </Alert>
      </Collapse></p>
						  
						  
	
						  
	<p> <span className="validation">*</span> Test Name</p>
	
	<p><TextField name="testname" 
	   onChange={this.myChangeHandler} 
	   fullWidth
	   value={this.state.testname}
			 size="small"
			  ref={this.usernameInput}
			  inputProps={{tabIndex: "1",maxLength:150}} 
	   InputLabelProps={{ shrink: true }}
                          /></p>	<p> <span className="validation">*</span> Max. Marks</p>
	
	<p><TextField name="maxmarks" 
	   onChange={this.myChangeHandler} 
	   fullWidth
	   value={this.state.maxmarks}
			size="small" 
			  ref={this.usernameInput}
			  inputProps={{tabIndex: "2",maxLength:3}} 
	   InputLabelProps={{ shrink: true }}
                          /></p>		<p><span className="validation">*</span>Class</p>
	  <p>
	  <FormControl fullWidth  size="small">
       
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.classlabel, value: this.state.classid }}
          onChange={this.myChangeHandler}
          id="state"
		   value={this.state.classid}
		  name="classid"
		
		
        >
		{this.state.Classarr.map(row => (
            <option  
            
              value={row.value}
            >
              {row.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
	  
	 <p style={{"display":"none"}}> <span className="validation">*</span> Batch</p>
	

	
		<p style={{"display":"none"}}><FormControl  className="stadrp">
         
        <Select
         native  fullWidth
		 
		 
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.rolelabel, value: this.state.batch }}
          onChange={this.myChangeHandler}
          id="batch"
		   value={this.state.batch}
		  name="batch"
		
		
        >
		{this.state.batcharray.map(batch => (
            <option  
            
              value={batch.value}
            >
              {batch.display}
            </option  >
          ))}
		
        </Select></FormControl>
		
				</p>
	
			  {localStorage.getItem("MMCrole")==="1" && (<React.Fragment>
	 <p><span className="validation">*</span>Branch</p>
	  <p>
	  <FormControl fullWidth  size="small">
       
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.branchlabel, value: this.state.branchid }}
          onChange={this.myChangeHandler}
          id="state"
		   value={this.state.branchid}
		  name="branchid"
		
		
        >
		{this.state.Brancharr.map(row => (
            <option  
            
              value={row.value}
            >
              {row.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
	  
	 </React.Fragment> )}
		
       <div className="actionbtns">
<div className="cancelbtn">	  
        <Button onClick={this.handleClickOpen} color="secondary">
          Cancel
        </Button></div> 
<div className="savebtn">
        <Button onClick={this.handleClose} color="secondary">
          Save
        </Button>
		</div>
<div className="clear"></div>

 </div>     
	</div>		 
</DialogContent>
      
    </Dialog>
</React.Fragment>

) }
 
} 
  