import React, { Component } from "react";
import { Navigate  } from 'react-router-dom';
import Serverurl from './Apiurl';
import axios from 'axios';
import ReactToPrint from 'react-to-print';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import ReplyIcon from '@mui/icons-material/Reply';
export default class StudentFees extends Component 
{
constructor(props) 
	{
		super();
		this.state = {feedata:[],Address:'',amount:'',mode:'',studname:'',phone:'',gstin:'',school:'',studphone:'',tc_title:'',date:'',batch:'',rcptno:'',std:'',}
	}

getParameterByName(name, url) {
    if (!url) url = window.location.href;
    url = url.toLowerCase(); 
    name = name.replace(/[\[\]]/g, "\\$&").toLowerCase();
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}
	componentDidMount()
	{
		if(localStorage.getItem("MMCuserid")===null)
		{
			this.setState({redirect: true,
							pagename:'/Login'}) 
		}
		this.Studentefeedetails();
	}
	
	
	
	Studentefeedetails(page,rowsPerPage)
{
		let search = window.location.href;
let feid = this.getParameterByName('feid',search);
axios.get(Serverurl+'ajaxfeepayments.php?action=studfee&fp_id='+feid)
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
							tc_title:resp.data[0]["trust"],studname:resp.data[0]["sr_studentname"],
							Address:resp.data[0]["td_address"],phone:resp.data[0]["td_phone"],gstin:resp.data[0]["td_gstno"],
							date:resp.data[0]["feedate"],rcptno:resp.data[0]["fp_receiptnumber"],
							std:resp.data[0]["class"],batch:resp.data[0]["bc_title"],school:resp.data[0]["sa_schoolname"],
							studphone:resp.data[0]["sr_phonenumber"],amount:resp.data[0]["fp_amount"],
							mode:resp.data[0]["paymentmode"]
							
						  }) 
			}
		this.printcall();
	   });
}

   printcall = (e) => {
	
document.getElementById('print-button').click();	
}
render (){

return(
<React.Fragment>

 <div className="pagebox-col">
 <div className="boxheading">
				  <div className="contntpad">
				<a href="#/Dashboard" title="Back"><ReplyIcon className="alignright"/></a>
				  </div> </div>
 <div ref={el => (this.componentRef = el)} >
 <table cellpadding="5" cellspacing="5" border="0" className="tabfee" style={{"width":"80%","margin":" 20px auto"}}>
 <tr><td colSpan="2" align="center"><h2>{this.state.tc_title}</h2>

 </td></tr>
  <tr><td colSpan="2" align="center"><p style={{"text-align":"center"}}>{this.state.Address} <PhoneInTalkIcon className="iconvertical"/>{this.state.phone}</p>

 </td></tr>
 
 <tr><td >
 {this.state.gstin!=="" && (
 <h3>GSTIN: {this.state.gstin}</h3>)}
 Composition taxable person not eligible to collets tax on supplies. <br/>Paying tax in tenns of notification no 02/2019-Central tax
 </td><td ><b>Date:</b> {this.state.date} </td></tr>
 
  <tr><td colSpan="2" align="center"> <h3>Bill Supply</h3> </td></tr>
    <tr><td > <b>Receipt No.:</b> {this.state.rcptno}</td><td >
	<b>Std:</b> {this.state.std}  &nbsp;<b>Batch:</b> {this.state.batch}
	
	
	</td></tr>
  

    <tr><td > <b>School:</b> {this.state.school}</td><td >
	<b>Phone:</b> {this.state.studphone}  
	
	
	</td></tr>
	  <tr><td colSpan="2" align="left"> <b>SAC Code:</b>999294</td></tr>
	    <tr><td colSpan="2" align="left"><p>Received with thanks from  <span>&nbsp;&nbsp;{this.state.studname}&nbsp;&nbsp;</span> the sum of rupees <span>&nbsp;&nbsp;{this.state.amount}/-&nbsp;&nbsp;</span>
by mode of payment <span>&nbsp;&nbsp;{this.state.mode}&nbsp;&nbsp;</span> dated <span>&nbsp;&nbsp;{this.state.date}&nbsp;&nbsp;</span> towards advance/full payment of annual fees.
		</p></td></tr>
		
		  <tr><td colSpan="2" align="right"> <h5>for Male Mahadeshwara Educational Trust (R)</h5> </td></tr>
		  		  <tr><td colSpan="2" align="right">  </td></tr>
				  		  <tr><td colSpan="2" align="right">  </td></tr>
						  		  <tr><td colSpan="2" >
<table cellpadding="5" cellspacing="5" border="0" style={{"width":"100%"}}>

<tr><td align="left" ><span style={{"width":"200px","border-bottom":"1px solid #000","display":"block"}}></span><br/>(*Subject to realisation)</td>
<td  align="center"></td><td  align="right">

								  <h5>Authorised Signature </h5> </td></tr>
								  </table>
								 </td></tr> 
								  
 </table>
 </div>
   <p className="aligncenter">  <ReactToPrint 
            trigger={() => <p id="print-button" className="frmPrimarybtn">Print </p>}
			content={() =>  this.componentRef} />
            
         
		 
		 
		 
		 
		 
		 
		 
		 
		 
		 </p>
 </div>
</React.Fragment>
)
}
}










