import React, { Component } from "react";
import { Navigate  } from 'react-router-dom';
import Serverurl from './Apiurl';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import registrationhead from './images/enter-student-detail.png';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import ArrowRightAltRoundedIcon from '@mui/icons-material/ArrowRightAltRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@mui/material/IconButton';
import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import Radio from '@mui/material/Radio';
import Switch from '@mui/material/Switch';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,DatePicker, 
  KeyboardDatePicker,
  DateTimePicker
} from '@material-ui/pickers';
import { addDays } from "date-fns";
import Moment from 'react-moment';
import moment from 'moment';
import Checkbox from '@mui/material/Checkbox';
export default class StudentAdmission extends Component 
{
	constructor(props) 
	  {
			super();
			var date = addDays(moment().toDate(),0);
				 var month="",date1="";
	
	 if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)}
	 
	 
	  if((date.getDate() )<10)
	 {
		date1="0"+ (date.getDate() )		 
	 }else{date1=(date.getDate())}
			this.state = {studename:'',year:date.getFullYear(),open:false,sabranchid:0,errormessage:'',studcode:'',primarybtntext:'Submit',secondarybtntext:'Save and Continue',refno:'',
			batchid:'',batchlabel:'Select Batch',classid:'0',classlabel:'Select Class',yearid:'',yearlabel:'Select Year',school:'',
			fee:'0',subjectarr:[],subjectoparr:[],subjectid:'',optinalsubject:false,sbtype:'1',sylabus:"2",isItemSelected:false,Optional:'0',otheramount:'0',Classarr:[],Yeararr:[],Batcharr:[],nextduedate:addDays(moment().toDate(),0),date:date.getFullYear() + '-' + month + '-' +date1,joiningdate:addDays(moment().toDate(),0),joiningdate1:date.getFullYear() + '-' + month + '-' +date1,
			yeardisabled:false,subjectsdisabled:false,sylabusdisabled:false,refund:"0", refChecked:false,display:false,feedisabled:false,gstamtdisabled:false
			}
			
	  }		componentWillMount() { 
localStorage.setItem("pageactive","2")
} 
handleChangeRefund=()=>{
 this.setState({
        refChecked: !this.state.refChecked,
		
		
    });
}	
	  componentDidMount()
	{
		if(localStorage.getItem("MMCuserid")===null)
		{
			this.setState({redirect: true,
							pagename:'/Login'}) 
		}
		if(localStorage.getItem("MMCSRId")===null)
		{
			this.setState({redirect: true,
							pagename:'/Dashboard?msg=1'}) 
		}
		
		this.OnloadStudentGetdetails();
	}	
	handleSyllabusChange = (event) => {
 
	 
  let nam = event.target.name;
  let val = event.target.value;

	  
	   this.setState({[nam]: val}, () => {
if(nam==="sylabus" && this.state.classid!="0")
	   {
		   this.BatchBind();
 	this.SubjectsBind();
	   }
});  
}

handlesubjectChange = (event) => {
	 
	  this.setState({optinalsubject:!this.state.optinalsubject}, () => {

if(this.state.optinalsubject===true)
{
	
	
	this.setState({sbtype:'1,2'}, () => {


	 	this.SubjectsBind();

}); 
}if(this.state.optinalsubject===false)
{
	
	
	this.setState({sbtype:'1'}, () => {


	 	this.SubjectsBind();

}); 
}
}); 
	}
	
myChangeHandler = (event) => {
		
		const re = /^[0-9\b]+$/;
  let nam = event.target.name;
  let val = event.target.value;

	  
	   this.setState({[nam]: val}, () => {
if(nam==="classid")
	   {
		this.BatchBind();	   
 	
	   }
	   if(nam==="batchid")
	   {
		this.SubjectsBind();
 	
	   }
}); 
	   
	   	
}

OnloadStudentGetdetails()
{if(localStorage.getItem("MMCsaid")!==null)
	{
		
		this.ClassBind();
		
		
	}
	
	else if(localStorage.getItem("MMCSRId")!==null)
	{
	axios.get(Serverurl+'ajaxstudentregistration.php?action=list&sr_id='+localStorage.getItem("MMCSRId"))
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
							
							studename:resp.data[0]["sr_studentname"],studcode:resp.data[0]["sr_studentcode"]
							
							,refno:resp.data[0]["sr_studentcode"]+"/"+this.state.year
						  }) 
			}
			
			this.ClassBind();
	   });
	}
}
OnloadStudentadmisiondetails(said)
{
	axios.get(Serverurl+'ajaxstudentadmission.php?action=list&sa_id='+localStorage.getItem("MMCsaid"))
       .then(resp => { 		
			if(resp.data.length>0){
				
				let strrefcheck=false;
				if(resp.data[0]["sa_refund"]!=="0")
				{
					strrefcheck=true;
				}
				
				this.setState({
							
							studename:resp.data[0]["sr_studentname"],studcode:resp.data[0]["sr_studentcode"] +" - "+resp.data[0]["bc_title"] 
							
							,refno:resp.data[0]["sa_referenceno"],
							classid:resp.data[0]["sa_class_id"],
							batchid:resp.data[0]["sa_bc_id"],
							sabranchid:resp.data[0]["sa_br_id"],
							yeardisabled:true,sylabusdisabled:true,sylabus:resp.data[0]["bc_syllabustype"],
							subjectsdisabled:true,
								display:true,
							yearid:resp.data[0]["sa_year_id"],
							school:resp.data[0]["sa_schoolname"],
							fee:resp.data[0]["sa_fee"],
							Optional:resp.data[0]["sa_optional"],refChecked:strrefcheck,
							otheramount:resp.data[0]["sa_otheramt"],refund:resp.data[0]["sa_refund"],
							nextduedate:resp.data[0]["sa_nextduedate"],
						 }, () => {this.BatchBind();})
						 
						 if(localStorage.getItem("MMCrole")==="2")
	{
	this.setState({feedisabled:true,gstamtdisabled:true})
	}
			}
		
	   });

}
ClassBind()
{
	axios.get(Serverurl+'ajaxtrustandclass.php?action=getall&tc_type=2')
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
						Classarr:resp.data	
							}) 
			}
		this.YearBind();	
	   }) .then(data => {
        let classFromApi = this.state.Classarr.map(row => {
                          
          return {value: row.tc_id, display:row.tc_title}
        });
      
		
        this.setState({
          Classarr: [{value: '0', display: 'Select Class'}].concat(classFromApi)
        });
      })

}
YearBind()
{
	axios.get(Serverurl+'ajaxtrustandclass.php?action=getall&tc_type=3')
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
						Yeararr:resp.data,yearid:localStorage.getItem("MMCRegyearid")	
							}) 
			}
		
	   }) .then(data => {
        let yearFromApi = this.state.Yeararr.map(row => {
                          
          return {value: row.tc_id, display:row.tc_title}
        });
      
		
        this.setState({
          Yeararr: [{value: '0', display: 'Select Year'}].concat(yearFromApi)
        });
			if(localStorage.getItem("MMCsaid")!==null)
	{
		
		this.OnloadStudentadmisiondetails();
		
	
	}
      })

}

BatchBind()
{let strbranchid=localStorage.getItem("MMCbranchid");
	
	if(localStorage.getItem("MMCrole")==="1")
	{
		strbranchid=this.state.sabranchid
	}
	axios.get(Serverurl+'ajaxbatch.php?action=list&bc_class_id='+this.state.classid+'&bc_syllbustype='+this.state.sylabus+'&bc_br_id='+strbranchid)
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
						Batcharr:resp.data	
							}) 
			}else{
				
				this.setState({
						Batcharr:[]	
							}) 
			}
	this.SubjectsBind();
	   }).then(data => {
        let batchFromApi = this.state.Batcharr.map(row => {
                          
          return {value: row.bc_id, display:row.bc_title}
        });
      
		
        this.setState({
          Batcharr: [{value: '0', display: 'Select Batch'}].concat(batchFromApi)
        });
      })

}


SubjectsBind()
{
	let strapi="";
if(localStorage.getItem("MMCsaid")!==null)
	{
		strapi="&sb_id="+localStorage.getItem("MMCsaid")
	}
	
	axios.get(Serverurl+'ajaxsubjects.php?action=Subjectbystud&sb_class_id='+this.state.classid+'&sb_syllabustype='+this.state.sylabus+strapi)
       .then(resp => { 		
			if(resp.data.length>0){
				
				 const subarr1 = resp.data.filter(sub => {
    return sub.sb_type === '1';
  });
  
  const suboparr1 = resp.data.filter(sub => {
    return sub.sb_type === '2';
  });

				this.setState({
						subjectarr:subarr1,subjectoparr:suboparr1	
							},()=>{console.log(this.state.subjectarr)}) 
			}else{
				this.setState({
						subjectarr:[]	,subjectoparr:[]
							}) 
			}
			
	   })

}
OnAdmissionSubmit= (event) => 
		{
		event.preventDefault();
		this.SubmitForm(2);
	
			
		
	}
	
OnAdmissionSave= (event) => 
		{
		event.preventDefault();
		this.SubmitForm(2);
	
			
		
	}
SubmitForm(type){
	
	let refno = this.state.refno;
	let classid = this.state.classid;
	let batchid = this.state.batchid;
	let yearid = this.state.yearid;
	let school = this.state.school;
	let fee = this.state.fee;
	let Optional = this.state.Optional;
	let otheramount = this.state.otheramount;
		let refund =this.state.refund;
	let date = this.state.date;
	
	let joiningdate1 = this.state.joiningdate1;
 	var subrowsArray = this.state.subjectarr;
	var subrowsArrayop = this.state.subjectoparr;
	refno=refno.replace(/\s+/g, '');
	let strsubdata = "",strsubuser="";
	
	if(localStorage.getItem("MMCsaid")===null){
 var subnewRow = subrowsArray.map((rowssub, j) => { 
 if(rowssub.selected=="1")
 {
	 
strsubdata=strsubdata+strsubuser+rowssub.sb_id;
strsubuser="~";
 }
 })
	}
 let strsubdata1 = "",strsubuser1="";
 
 var subnewRowop = subrowsArrayop.map((rowssub, j) => { 
 if(rowssub.selected=="1")
 {
strsubdata1=rowssub.sb_id;	 
strsubdata=strsubdata+strsubuser+rowssub.sb_id;
strsubuser="~";
 }
 })
 this.setState({errormessage:"",open:false});
	if(refno==''){
						
				this.setState({errormessage:"Please Enter Ref No.",open:true});
		 window.scrollTo({        top: 0     })
	return false; 
				}
					if(classid=='0'){
						
				this.setState({errormessage:"Please Select Class",open:true});
		 window.scrollTo({        top: 0     })
	return false; 
				}		if(batchid=='0'){
						
				this.setState({errormessage:"Please Select Batch",open:true});
		 window.scrollTo({        top: 0     })
	return false; 
				}
					if(school==''){
						
				this.setState({errormessage:"Please Enter School/College",open:true});
		 window.scrollTo({        top: 0     })
	return false; 
				}
				if(yearid=='0'){
						
				this.setState({errormessage:"Please Select Year",open:true});
		 window.scrollTo({        top: 0     })
	return false; 
				}
				if(fee=='' || fee=='0'){
						
				this.setState({errormessage:"Please Enter Fee",open:true});
		 window.scrollTo({        top: 0     })
	return false; 
				}
				if(this.state.sylabus!=="3"){
				if(strsubdata1!=='' && (Optional==="0" || Optional==="")){
						
				this.setState({errormessage:"Please Enter Optional Fee",open:true});
		 window.scrollTo({        top: 0     })
	return false; 
				}
				}
					
				if(otheramount=='' || otheramount=='0'){
						
				this.setState({errormessage:"Please Enter Other Amount",open:true});
		 window.scrollTo({        top: 0     })
	return false; 
				}
					if(localStorage.getItem("MMCsaid")===null){
				if(strsubdata==='' ){
						
				this.setState({errormessage:"Please Select Subjects",open:true});
		 window.scrollTo({        top: 0     })
	return false; 
				}
				
				if(joiningdate1==='' ){
						
				this.setState({errormessage:"Please Select Joining Date",open:true});
		 window.scrollTo({        top: 0     })
	return false; 
				}
					}
				if(this.state.refChecked===true)
				{
					if(refund==='' || refund==='0'){
						
				this.setState({errormessage:"Please Enter Refund amound",open:true});
		 window.scrollTo({        top: 0     })
	return false; 
				}
				}
				else{
					refund=0;
				}
				if(type===1){
				this.setState({primarybtntext:'Processing...'});
				}else{
					this.setState({secondarybtntext:'Processing...'});
				}
				var formData = new FormData();
				if(localStorage.getItem("MMCsaid")===null){
			    formData.append('action','insert')
					formData.append('sa_joiningdate',joiningdate1)
				
				}
				
				else{
					formData.append('action','update')
					formData.append('sa_id',localStorage.getItem("MMCsaid"))
				}	
 
				formData.append('sa_br_id',localStorage.getItem("MMCbranchid"))
				formData.append('sa_sr_id',localStorage.getItem("MMCSRId"))
					formData.append('sa_year_id',yearid)
					formData.append('sa_class_id',classid)
					formData.append('sa_schoolname',school)
					formData.append('sa_nextduedate',date)
					formData.append('sa_referenceno',refno)
					formData.append('sa_fee',fee)
					formData.append('sa_optional',Optional)
					formData.append('sa_subjects',strsubdata)
					formData.append('sa_refund',refund)
					formData.append('sa_bc_id',batchid)
					formData.append('sa_otheramt',otheramount)
					formData.append('userid',localStorage.getItem("MMCuserid"))
					axios.post(Serverurl+"ajaxstudentadmission.php", formData, {
				}).then(res => {
					
					if(res.data!==-1)
					{
					let strreturn=res.data;
					
					if(localStorage.getItem("MMCsaid")===null){
						if(type===1){
					this.setState({primarybtntext:'Submit',redirect:true,pagename:'/Dashboard'});
					}else{
						this.setState({secondarybtntext:'Save and Continue',redirect:true,pagename:'/StudentFees?said='+strreturn});
					}
					}else{
						localStorage.removeItem("MMCsaid");
						if(type===1){
									
								this.setState({primarybtntext:'Submit',redirect:true,pagename:'/StudentregistrationReport'});
								}else{
								
									this.setState({secondarybtntext:'Save and Continue',redirect:true,pagename:'/StudentregistrationReport'});
								}
					}
					 
					}else{
						this.setState({primarybtntext:'Submit',open:true,errormessage:'This Student already admitted for given details'});
					}
				});
	
}	


handleErrorclose=(event)=>{
	  
	this.setState({open: false});  
  }	
   isSelected = id => this.state.subjectarr.indexOf(id) !== -1; 
    isSelected1 = id => this.state.subjectoparr.indexOf(id) !== -1; 
	handleChangeSubject = (id,rowsd) => {
		
		//let departmentdata="";
		//let strsep="";

		  const updatednewdepArray = [...this.state.subjectarr];

		    
		  if(updatednewdepArray[rowsd].selected==="0")
		  {
					  updatednewdepArray[rowsd].selected = "1" ;
					  
					// departmentdata = departmentdata+strsep+updateddepArray[rowsd].dp_id;  
				 	  
		  }
else{

			  updatednewdepArray[rowsd].selected = "0" ;
			// updateddepArray[rowsd].selected = "0" ;	
			 
}




 this.setState({
        subjectarr: updatednewdepArray,
		//departmentdata: updateddepArray,
		
    });


	
  }; 
  handleChangeopSubject = (id,rowsd) => {
		
		
		  const updatedArray = [...this.state.subjectoparr];

		
		  if(updatedArray[rowsd].selected==="0")
		  {
					  updatedArray[rowsd].selected = "1" ;
					  
					
				 	  
		  }
else{

			  updatedArray[rowsd].selected = "0" ;
			
			  
}




 this.setState({
        subjectoparr: updatedArray,
		
		
    });


	
  }; 
	handjoinChange = (date) => {
	 var month="",date1="";
	
	 if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)}
	 
	 
	  if((date.getDate() )<10)
	 {
		date1="0"+ (date.getDate() )		 
	 }else{date1=(date.getDate())}
     this.setState({ joiningdate:date,
joiningdate1:date.getFullYear() + '-' + month + '-' +date1
	 })
	
	
  }
	handfrChange = (date) => {
	 var month="",date1="";
	
	 if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)}
	 
	 
	  if((date.getDate() )<10)
	 {
		date1="0"+ (date.getDate() )		 
	 }else{date1=(date.getDate())}
     this.setState({ nextduedate:date,
date:date.getFullYear() + '-' + month + '-' +date1
	 })
	
	
  }
 render() {
if (this.state.redirect === true) {
      return <Navigate to={this.state.pagename} />
    }
	
	 return (
  
	 <React.Fragment> 
	 
	 <div className="pagebox-col">
	 <div className="formview">
			<div className="boxheading">
				  <div className="contntpad">
					 <p>  <Collapse in={this.state.open}>
										<Alert
										  action={
											<IconButton
											  aria-label="close"
											  color="inherit"
											  size="small"
											  onClick={() => {
											   this.handleErrorclose();
											  }}
											>
											  <CloseIcon fontSize="inherit" />
											</IconButton>
										  } severity="error"
										>
										{this.state.errormessage}
										</Alert>
								</Collapse></p>
				  <h2><img src={registrationhead} /> Admission of <span className="headingfont">{this.state.studename} - {this.state.studcode}</span></h2>
				  </div> </div>
	   <div className="contntpad">
	 
	 
	   
	 <ul>
	  
	 <li>
	 
	 <p><span className="validation">*</span> Admission No.</p>
	 <p><TextField id="txtref" fullWidth type="text" size="small"  placeholder="Ref No."  name="refno" value={this.state.refno.trim()}  inputProps={{tabIndex: "1",maxLength:15}} InputLabelProps={{ shrink: true }}  
	  onChange={this.myChangeHandler} disabled /></p>
	  </li>
	  <li><p><span className="validation">*</span> Sylabus Type</p>
	  <Radio
        checked={this.state.sylabus === '2'}
        onChange={this.handleSyllabusChange}
        value="2" disabled={this.state.sylabusdisabled}
        name="sylabus"
        inputProps={{ 'aria-label': 'B' }}
      />State<Radio
        checked={this.state.sylabus === '1'}
        onChange={this.handleSyllabusChange}
        value="1" disabled={this.state.sylabusdisabled}
        name="sylabus"
        inputProps={{ 'aria-label': 'A' }}
      />CBSE
    <Radio
        checked={this.state.sylabus === '3'}
        onChange={this.handleSyllabusChange}
        value="3" disabled={this.state.sylabusdisabled}
        name="sylabus"
        inputProps={{ 'aria-label': 'A' }}
      />PUC

	  
	 </li>
	   <li><p><span className="validation">*</span>Year</p>
	  <p>
	  <FormControl  fullWidth size="small" >
        
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.yearlabel, value: this.state.yearid }}
          onChange={this.myChangeHandler}
          id="state"
		   value={this.state.yearid}
		  name="yearid"
		
		 disabled={this.state.yeardisabled}
        >
		{this.state.Yeararr.map(row => (
            <option  
            
              value={row.value}
            >
              {row.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
	  
	  </li>
	  <li><p><span className="validation">*</span>Class</p>
	  <p>
	  <FormControl fullWidth  size="small">
       
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.classlabel, value: this.state.classid }}
          onChange={this.myChangeHandler}
          id="state"
		   value={this.state.classid}
		  name="classid"
		
		
        >
		{this.state.Classarr.map(row => (
            <option  
            
              value={row.value}
            >
              {row.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
	  
	  </li>
	  
	  <li><p><span className="validation">*</span>Batch</p>
	  <p>
	  <FormControl  fullWidth size="small" >
       
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.batchlabel, value: this.state.batchid }}
          onChange={this.myChangeHandler}
          id="state"
		   value={this.state.batchid}
		  name="batchid"
		
		
        >
		{this.state.Batcharr.map(row => (
            <option  
            
              value={row.value}
            >
              {row.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
	  
	  </li>
	 
	   <li>
	 
	 <p><span className="validation">*</span> School/College</p>
	 <p><TextField id="txtschool" fullWidth type="text" size="small"  placeholder="Schoool"  name="school" value={this.state.school}  inputProps={{tabIndex: "5",maxLength:150}} InputLabelProps={{ shrink: true }}  
	  onChange={this.myChangeHandler} /></p>
	  </li>
	  <li style={{"width":"100%","float":"none"}}>
{this.state.subjectarr.length>0 && (
<div className="subjectsdiv">
<h3>Core Subjects</h3><ul className="subjects">


 {(this.state.subjectarr).map((row,index) => {
					 const isItemSelected = this.isSelected(row.sb_id);
				
           return ( <React.Fragment>

<li key={index}>
 
{row.sb_ismandatory==="1" && (<React.Fragment>
{row.selected==="0" && (
<Checkbox
  
 onChange={()=>{this.handleChangeSubject(row.sb_id,index)}}
 inputProps={{ 'aria-label': 'uncontrolled-checkbox'  }} 
   disabled={this.state.subjectsdisabled }
   />
   )} 
   {row.selected==="1" && (
<Checkbox
  checked
 onChange={()=>{this.handleChangeSubject(row.sb_id,index)}}
 inputProps={{ 'aria-label': 'uncontrolled-checkbox'  }} 
  disabled={this.state.subjectsdisabled  } 
   />
)}  
 </React.Fragment>  
)}
{row.sb_ismandatory==="0" && (<React.Fragment> 
  {row.selected==="1" && (
<Checkbox
  checked
 
 inputProps={{ 'aria-label': 'uncontrolled-checkbox'  }} 
  disabled={this.state.subjectsdisabled  } 
   />
)}
 </React.Fragment>  
)}  
{row.sb_title}

</li>


</React.Fragment>	
				);
					})}
					</ul></div>)}
			{this.state.subjectoparr.length>0 && (
<div className="subjectsdiv">
<h3>Optional Subjects</h3><ul className="subjects">


 {(this.state.subjectoparr).map((item,index) => {
					 const isItemSelected = this.isSelected1(item.sb_id);
					 
				
           return ( <React.Fragment>

<li key={index}>
 
{item.selected==="0" && (
<Checkbox
  
 onChange={()=>{this.handleChangeopSubject(item.sb_id,index)}}
 inputProps={{ 'aria-label': 'uncontrolled-checkbox'  }} 
   />
)} 
  {item.selected==="1" && (
<Checkbox
  checked
 onChange={()=>{this.handleChangeopSubject(item.sb_id,index)}}
 inputProps={{ 'aria-label': 'uncontrolled-checkbox'  }} 
   />
)}  
{item.sb_title}

</li>


</React.Fragment>	
				);
					})}
					</ul></div>)}{(this.state.subjectoparr.length==0 && this.state.subjectarr.length==0) && (
				<div className="subjectsdiv"><h3>Optional Subjects</h3>	<p>No Records</p></div>
					)}		
		 	<div className="clear"></div>
			</li>
	   <li>
	 
	 <p><span className="validation">*</span> Fee Amount</p>
	 <p><TextField id="txtfee" fullWidth type="text" size="small" disabled={this.state.feedisabled}  placeholder="Fee Amount"  name="fee" value={this.state.fee}  inputProps={{tabIndex: "6",maxLength:7}} InputLabelProps={{ shrink: true }}  
	  onChange={this.myChangeHandler} /></p>
	  </li>
	   {this.state.sylabus!=="3" && ( <li>
	  
	 <p> Optional Amount</p>
	 <p><TextField id="txtoptn" fullWidth type="text" size="small"  placeholder="Optional Amount"  name="Optional" value={this.state.Optional}  inputProps={{tabIndex: "7",maxLength:7}} InputLabelProps={{ shrink: true }}  
	  onChange={this.myChangeHandler} /></p>
	  </li> )}<li>
	   <p><span className="validation">*</span> Other Amount</p>
	 <p><TextField id="txtothramt" fullWidth type="text" size="small" disabled={this.state.gstamtdisabled}  placeholder="Other Amount"  name="otheramount" value={this.state.otheramount}  inputProps={{tabIndex: "8",maxLength:7}} InputLabelProps={{ shrink: true }}  
	  onChange={this.myChangeHandler} /></p>
	  </li>
	  {localStorage.getItem("MMCsaid")===null && (
	  <li><p><span className="validation">*</span> Joining Date</p>
	 <p>
		 	<MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-around">
	 
      <DatePicker fullWidth variant="outlined"
        value={this.state.joiningdate }
        onChange={this.handjoinChange}
		maxDate={addDays(moment().toDate(),0)}
            format="dd/MM/yyyy"
        autoOk name="joiningdate"
        ampm={false}
	id="joiningdate"
      />
	   </Grid>
    </MuiPickersUtilsProvider>
	</p>	 
	 </li>
	  )}
	   <li><p><span className="validation">*</span> Next Due Date</p>
	 <p>
		 	<MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-around">
	 
      <DatePicker fullWidth variant="outlined"
        value={this.state.nextduedate || addDays(moment().toDate(),1)}
        onChange={this.handfrChange}
		minDate={addDays(moment().toDate(),0)}
            format="dd/MM/yyyy"
        autoOk name="nextduedate"
        ampm={false}
	id="nextduedate"
      />
	   </Grid>
    </MuiPickersUtilsProvider>
	</p>	 
	 </li>	
	
	
	 
	 



</ul>
{(localStorage.getItem("MMCsaid")!==null && localStorage.getItem("MMCrole")==="1") && (
	<ul>
	 <li>
	
	 <Checkbox  label="refund"  checked={this.state.refChecked}
	 
	  onChange={()=>{this.handleChangeRefund()}}
             /><span>Refund</span>
	 </li>
	 
	 {this.state.refChecked===true &&(
	 <li>
		
	 <p>
	  <p><span className="validation">*</span> Refund Amount</p>
	 <TextField id="txtree" fullWidth type="text"  size="small"  placeholder="Refund Amount"  name="refund" value={this.state.refund}  inputProps={{tabIndex: "7",maxLength:7}} InputLabelProps={{ shrink: true }} 
	  onChange={this.myChangeHandler} /></p>
	  
	</li>
	 )}
	</ul>
 
	)
	}
<div className="clear"></div>		
		
	 
	 
							 <div className="formbtns">
							 
							 <ul>
							 <li>
							  <p><Button variant="standard" className="frmPrimarybtn" endIcon={<CheckRoundedIcon />} onClick={this.OnAdmissionSubmit}>
								  {this.state.primarybtntext}
						</Button></p></li><li>
						 <p><Button variant="standard" className="frmsecondarybtn" endIcon={<ArrowRightAltRoundedIcon />} onClick={this.OnAdmissionSave}>
							{this.state.secondarybtntext}
						</Button></p></li></ul>
						 <div className="clear"></div>
						</div>
	  </div>
	 </div>
	  </div>
	  </React.Fragment> 
	  )
	 }
}




