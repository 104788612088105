import React ,{Component} from 'react'; 
import ReactDOM from "react-dom";

import {Navigate  } from 'react-router-dom';
import axios from 'axios';
import Serverurl from './Apiurl';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';  
import Button from '@mui/material/Button';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableBody from '@mui/material/TableBody'; 
import TableCell from '@mui/material/TableCell';  
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';  
import TablePagination from '@material-ui/core/TablePagination'; 
import PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow'; 
import remainder from './images/remainder.png';
import StudentattendanceGraph from './StudentattendanceGraph';
import Studentexamgraph from './Studentexamgraph';
export default class MobNotification extends Component {
  constructor(props) {
    super(props);
	
	
    this.state = { redirect:false,pagename:'', batchid:localStorage.getItem("MMCstudid"),remainder:[],remaindercnt:0,rmpage:0, rmrowsPerPage:8
    };
  }
	componentDidMount()
	{
		
		if(localStorage.getItem("MMCPrid")===null)
	{
		this.setState({
							redirect: true,
							pagename:'/MobLogin'
						  }) 
		

	}
			this.RemainderApicall(this.state.rmpage,this.state.rmrowsPerPage)
		
		
	}
	  handleChangermPage = (event, newPage) => {  
   
    let pageval = localStorage.getItem("page");
    this.setState({  
      rmpage: newPage,
      rmrowsPerPage:parseInt(pageval)
    });
   
 
    setTimeout(() =>   this.RemainderApicall(this.state.rmpage,this.state.rmrowsPerPage) ,500); 
  };
	RemainderApicall(page,rowsPerPage)
	{
		axios.get(Serverurl+'ajaxnotifications.php?action=listuser&no_type=3&studenid='+this.state.batchid+'&index='+page+'&maxrecrd='+rowsPerPage)
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({remainder:resp.data,remaindercnt:resp.data[0].count});
			}
	
	   });
		
	}
	
	
	render() { 
	return (
		<React.Fragment>
	   <StudentattendanceGraph said={this.state.batchid}/>
 <Studentexamgraph said={this.state.batchid}/>
 

<div className="studentpanel">
		<div className="boxheading">
		<div className="admitpad"> <h2><img src={remainder} /> Remainder</h2></div></div>
   <div className="admitpad remainde-col">

 <Paper >
  <TableContainer >  
                <Table stickyHeader aria-label="sticky table">  

					  
					  
                      <TableBody>
                     {(this.state.remainder).map((item,index) => {  
           return (
 <React.Fragment>
 <TableRow key={index} >


<TableCell><h5><a href={'#/MobNotificationView?p=1&noid='+item.no_id} >{item.date}</a></h5>
<br/><a href={'#/MobNotificationView?p=1&noid='+item.no_id} >
{item.no_title}</a></TableCell>






 </TableRow>

 </React.Fragment>	
);
					})}
					
					{this.state.remaindercnt===0 &&(
					
					<TableRow key={0} >
<TableCell align="center" >No Records</TableCell>
					</TableRow>
					) }
					
                      </TableBody>


</Table>
  </TableContainer> 
  <TablePagination  
        rowsPerPageOptions={[]}  
        component="div"  
        count={this.state.remaindercnt}  
        rowsPerPage={this.state.rmrowsPerPage}  
        page={this.state.rmpage}  
        onPageChange={this.handleChangermPage}  
        
      />   
  </Paper>

	</div>

	</div>
 	  	</React.Fragment>
    );
  
  }
}
  
  