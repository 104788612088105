import React ,{Component} from 'react'; 
import { Navigate  } from 'react-router-dom';
import axios from 'axios'; 
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Serverurl from './Apiurl';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { DropzoneArea } from 'material-ui-dropzone';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,DatePicker, 
  KeyboardDatePicker,
  DateTimePicker
} from '@material-ui/pickers';
import calenderimg from './images/calender-icon.png';
import { addDays } from "date-fns";
import Moment from 'react-moment';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@mui/styles'; 
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 
 icon: {
   verticalalign:'middle',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
});
export default class  AddNotificationbox extends Component {

  constructor(props) {
      super(props)
	  var strdob=new Date();
	 var month="";
	
	 if((strdob.getMonth() + 1)<10)
	 {
		month="0"+ (strdob.getMonth() + 1)		 
	 }else{month=(strdob.getMonth() + 1)}
	
      this.state = {
		  
		   open:false,title:'',no_id:0,notype:0,ispanel:false,imgfile:'',remainderdate:0,description:'',optiontype:'',prevca_icon:'',imgCollection:'',errormessage:'',erropen:false,classesarray:[],classes:'',rolelabel:'Select Class',trustarray:[],trust:'',rolelabel:'Select Trust',
		   yearsarray:[],years:'',rolelabel:'Select Years',batcharray:[],batch:'',rolelabel:'Select Batch',brancharray:[],branch:'',rolelabel:'Select Branch',typerecordid:0,
			studentarray:[],student:'',rolelabel:'Select Student',nodate:Date.now(),nodate1:strdob.getFullYear() + '-' + month + '-' +strdob.getDate(),rmdate:Date.now(),rmdate1:strdob.getFullYear() + '-' + month + '-' +strdob.getDate(),
	  }
      
  }
handleNotifypanel=()=>{
	this.setState({ ispanel:!this.state.ispanel});  
	  
}
handfrChanges = (date) => {
	
	  var month="";
	 
	 if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)}   
	 
     this.setState({ rmdate:date,
rmdate1:date.getFullYear() + '-' + month + '-' +date.getDate()
	 })
	
	
  }
  handnodateChanges = (date) => {
	
	  var month="";
	 
	 if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)}   
	 
     this.setState({ nodate:date,
nodate1:date.getFullYear() + '-' + month + '-' +date.getDate()
	 })
	
	
  }  
  

 Downloadsgetdata() {
	var self=this;
  if(this.props.id!==0)
  {
	  
	   axios.get(Serverurl+'ajaxnotifications.php?action=list&no_id='+this.props.id)
  .then(resp => {  
		
    if(resp.data.length>0){
		
		 if(resp.data[0].no_optiontype=="1")
	 {
		this.onloadtrustbind(); 
	 }
	 else if(resp.data[0].no_optiontype=="2")
	 {
		this.onloadbranchbind(); 
	 }
	 else if(resp.data[0].no_optiontype=="3")
	 {
		this.onloadbatchbind();
	 }
	  else if(resp.data[0].no_optiontype=="4")
	 {
		this.onloadstudentbind();
	 }
	 else if(resp.data[0].no_optiontype=="5")
	 {this.onloadbranchbind(); 
		this.onloadclassbind();
	 }
		
   setTimeout(function(){ self.setState({ 
     
      no_id:resp.data[0].no_id,
	  title: resp.data[0].no_title,
      optiontype: resp.data[0].no_optiontype, 
	 file: resp.data[0].no_file,   
	 prevca_icon: resp.data[0].no_file,
	 rmdate1: resp.data[0].no_remiderdate,
	 description:resp.data[0].no_description, 
	 rmdate:resp.data[0].no_remiderdate,
	 nodate:resp.data[0].start,nodate1:resp.data[0].start,
    });
	
	 if(resp.data[0].no_optiontype=="1")
	 {
		 self.setState({ trust:resp.data[0].at_record_id });
	 }
	 else if(resp.data[0].no_optiontype=="2")
	 {
		self.setState({ branch:resp.data[0].at_record_id });
	 }
	 else if(resp.data[0].no_optiontype=="3")
	 {
		self.setState({ batch:resp.data[0].at_record_id });
	 }
	  else if(resp.data[0].no_optiontype=="4")
	 {
		self.setState({ student:resp.data[0].at_record_id });
	 }
	 else if(resp.data[0].no_optiontype=="5")
	 {
		self.setState({classes:resp.data[0].at_record_id,branch:resp.data[0].no_br_id });
	 }
	
	 }, 1500);
	
  }  
});
	
  }else{
	 this.setState({  
	  no_id:0,
	  title: '',
      optiontype: '', 
	 file: '',   
	 prevca_icon:'',
	
	 description:'', 
	 
	
	 
	  }); 
 }
  
    }

   
  handleradChange= (event) => {
	
	 this.setState({optiontype: event.target.value});
	 
	 if(event.target.value=="1")
	 {
		this.onloadtrustbind(); 
	 }
	 else if(event.target.value=="2")
	 {
		this.onloadbranchbind(); 
	 }
	 else if(event.target.value=="3")
	 {
		this.onloadbatchbind();
	 }
	  else if(event.target.value=="4")
	 {
		this.onloadstudentbind();
	 }
	 else if(event.target.value=="5")
	 {this.onloadbranchbind(); 
		this.onloadclassbind();
	 }
	
}
handleClickOpen = (e) => {

  this.setState({open:!this.state.open,notype:this.props.notype,ispanel:false,  title:'',address:'',remainderdate:'',errormessage:'',erropen:false,classesarray:[],classes:'',trust:'',
  branch:'',batch:'',student:'',rmdate1:'',rmdate:'',
	  });
		this.Downloadsgetdata();
		
		if(this.props.notype==="2" || this.props.notype==="3")
		{
		this.setState({ispanel:true});	
		}
		if(this.props.notype==="1" )
		{
		this.setState({rmdate1:this.props.celldate,rmdate:this.props.edate,nodate1:this.props.celldate,nodate:this.props.edate});	
		}
		
		console.log(this.state.rmdate1+"~"+this.state.rmdate)
		
		};
myChangeHandler = (event) => {
	
	 
  let nam = event.target.name;
  let val = event.target.value;  
 
  this.setState({[nam]: val});
  }
  


 handleErrorclose=(event)=>{
	  
	this.setState({open: false});  
  }
 onloadclassbind(){

    axios.get(Serverurl+'ajaxtrustandclass.php?action=getall&tc_type=2')
	
    
      .then((response) => {    
     
      this.setState({  classesarray: response.data,  });
      })   
    
      .then(data => {
        let roleFromApi = this.state.classesarray.map(classes => {
                            
          return {value: classes.tc_id, display:classes.tc_title}
        });
      
		
        this.setState({
          classesarray: [{value: '0', display: 'Select Class'}].concat(roleFromApi)
        });
      })
  
  
  }
 onloadtrustbind(){

    axios.get(Serverurl+'ajaxtrustandclass.php?action=getall&tc_type=1')
	
    
      .then((response) => {    
     
      this.setState({  trustarray: response.data,  });
      })   
    
      .then(data => {
        let roleFromApi = this.state.trustarray.map(trust => {
                            
          return {value: trust.tc_id, display:trust.tc_title}
        });
      
		
        this.setState({
          trustarray: [{value: '', display: 'Select Trust'}].concat(roleFromApi)
        });
      })


} 
 onloadyearsbind(){

    axios.get(Serverurl+'ajaxtrustandclass.php?action=getall&tc_type=3')
	
    
      .then((response) => {    
     
      this.setState({  yearsarray: response.data,  });
      })   
    
      .then(data => {
        let roleFromApi = this.state.yearsarray.map(years => {
                            
          return {value: years.tc_id, display:years.tc_title}
        });
      
		
        this.setState({
          yearsarray: [{value: '', display: 'Select Year'}].concat(roleFromApi)
        });
      })


} 
onloadbatchbind(){
	let strurl="";
if(localStorage.getItem("MMCrole")==="1")
	 
		  {
			strurl="action=list"
	  
		  }else{
			  strurl='action=list&bc_br_id='+localStorage.getItem("MMCbranchid");
		  }
    axios.get(Serverurl+'ajaxbatch.php?'+strurl)
	
    
      .then((response) => {    
     
      this.setState({  batcharray: response.data,  });
      })   
    
      .then(data => {
        let roleFromApi = this.state.batcharray.map(batch => {
                            
          return {value: batch.bc_id, display:batch.bc_title}
        });
      
		
        this.setState({
          batcharray: [{value: '', display: 'Select Batch'}].concat(roleFromApi)
        });
      })


} 


onloadbranchbind(){
	 let strbranch=localStorage.getItem("MMCbranchid");
 if(localStorage.getItem("MMCrole")==="1")
	 
		  {
			 strbranch=""; 
		  }
    axios.get(Serverurl+'ajaxbranch.php?action=getall&br_id='+strbranch)
	
    
      .then((response) => {    
     
      this.setState({  brancharray: response.data, branch:response.data[0]["br_id"] });
      })   
    
      .then(data => {
        let roleFromApi = this.state.brancharray.map(branch => {
                            
          return {value: branch.br_id, display:branch.br_title}
        });
      
		
        this.setState({
          brancharray: [{value: '', display: 'Select Branch'}].concat(roleFromApi)
        });
      })
} 
onloadstudentbind(){
	let strurl="";
if(localStorage.getItem("MMCrole")==="1")
	 
		  {
			strurl="action=report&sr_issupend=0"
	  
		  }else{
			  strurl='action=report&sr_issupend=0&branchid='+localStorage.getItem("MMCbranchid");
		  }
    axios.get(Serverurl+'ajaxstudentregistration.php?'+strurl)
	
    
      .then((response) => {    
     
      this.setState({  studentarray: response.data,  });
      })   
    
      .then(data => {
        let roleFromApi = this.state.studentarray.map(student => {
                            
          return {value: student.sa_id, display:student.sr_studentname+" ("+student.sr_studentcode+")"}
        });
      
		
        this.setState({
          studentarray: [{value: '', display: 'Select Student'}].concat(roleFromApi)
        });
      })
} 

handleClose = (e) => {
	
	
 let title = this.state.title;
 let description=this.state.description;
let optiontype=this.state.optiontype;
 let imgfile=this.state.imgfile;
let rmdate1=this.state.rmdate1;

let nodate1=this.state.nodate1;
if(title==="")
{
	
	this.setState({errormessage:"Please Enter Title",erropen:true});
		 window.scrollTo({
          top: 0
      })
		 return false;
	
	
}

if( this.state.notype==="2")
		{
	if(imgfile==="")
	{
		this.setState({errormessage:"Please Upload File",erropen:true});
		window.scrollTo({
        top: 0
    })
	return false;
}
		}
if(optiontype==="")
{
	
	this.setState({errormessage:"Please Select Option Type",erropen:true});
		 window.scrollTo({
          top: 0
      })
		 return false;
	
	
}

   var formData = new FormData();
  
  
   if(this.state.no_id==0)
	  {
		  formData.append('no_id',0) 
		  
	  }
	  else
	  {
		  
	   formData.append('no_id',this.state.no_id)
		
      }	  

        formData.append('action','insertorupdate') 
		formData.append('no_remiderdate',rmdate1)
			formData.append('no_date',nodate1)
		formData.append('no_title',title)
		formData.append('no_description',description)		
		if(this.state.notype==="1" || this.state.notype==="2")
		{
			if(imgfile!="")
			{
				formData.append('file',"1") 
		  formData.append('Filedata', this.state.imgfile[0])
		  }
		 else if(this.state.prevca_icon!=="")
			{
				formData.append('previcon',this.state.prevca_icon) 
			}
		  else{
			 formData.append('file',"") 
		  }
		  }
	  formData.append('no_type',this.state.notype)
		formData.append('no_optiontype',optiontype)
		
		formData.append('userid',localStorage.getItem("MMCuserid"))
		if(this.state.optiontype==1)
		{
			formData.append('at_record_id',this.state.trust)
		}
		else
			if(this.state.optiontype==2)
		{
			formData.append('at_record_id',this.state.branch)
		}
		else
			if(this.state.optiontype==3)
		{
			formData.append('at_record_id',this.state.batch)
		}
		else
			if(this.state.optiontype==4)
		{
			formData.append('at_record_id',this.state.student)
		}
		else
			if(this.state.optiontype==5)
		{
			formData.append('at_record_id',this.state.classes)
			
			
			
		}
		else
			if(this.state.optiontype==6)
		{
			formData.append('at_record_id',0)
		}
		if(localStorage.getItem("MMCrole")==="1"){
			formData.append('no_br_id',this.state.branch)
			}else{
				
			formData.append('no_br_id',localStorage.getItem("MMCbranchid"))
			}
		//formData.append('userid',localStorage.getItem("MMCuserid"))
		  
		axios.post(Serverurl+"ajaxnotifications.php", formData, {
        }).then(res => {
    
	
	
	if(res.data == -1)
{ 
	 

		this.setState({errormessage:"Title Already Exist.",erropen:true,open:true
		});
		window.scrollTo({
          top: 0
      })
		 return false;

	}
	
	
            this.props.updateDonenew();
    
        });
  
	
	this.setState({ open:false });
};

	 handleErrorclose=(event)=>{
	  
	this.setState({erropen: false});  
	 
  } 
	
	


render (){

return(
<React.Fragment>





    <Dialog open={this.state.open} onClose={this.handleClose} maxWidth="xs" fullWidth="true" aria-labelledby="form-dialog-title" className="dwnloadspanel">
      <DialogTitle id="form-dialog-title"> 
	  {this.state.notype==="2" &&(
	 <h2> Downloads </h2>
	  )}{this.state.notype==="1" &&(
	  <h2>  Notifications</h2>
	  )}
	  {this.state.notype==="3" &&(
	  <h2>  Fee Remainder</h2>
	  )}
	  </DialogTitle>
      <DialogContent>
	  
<div className="formlists">

	<p>  <Collapse in={this.state.erropen}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
               this.handleErrorclose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          } severity="error"
        >
        {this.state.errormessage}
        </Alert>
      </Collapse></p>
						  
		
	{(this.state.notype==="1" ) && (
	
	
	 <p> Date
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-around">	 
      <DatePicker fullWidth
        value={this.state.nodate || Date.now()}
        onChange={this.handnodateChanges}
		variant="inline"
		inputVariant="outlined"
		 margin="normal"            
            format="dd/MM/yyyy"			
        autoOk name="nodate"
        ampm={false}
      />
	   </Grid>
    </MuiPickersUtilsProvider></p>
		
		
	)}			  
	
						  
	<p> <span className="validation">*</span> Title</p>
	
	<p><TextField name="title" 
	   onChange={this.myChangeHandler} 
	   fullWidth
	   value={this.state.title}
			 
			  ref={this.usernameInput}
			  inputProps={{tabIndex: "1",maxLength:150}} 
	   InputLabelProps={{ shrink: true }}
                          /></p>
						  
						  <p><FormControl>
      <FormLabel id="optionstype"></FormLabel>
      <RadioGroup
        row value={this.state.optiontype}
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="optiontype" onChange={this.handleradChange}
      >
	  {localStorage.getItem("MMCrole")==="1" && (
        <FormControlLabel value="1" control={<Radio />} label="Trust"  checked={this.state.optiontype === "1"}/>
      )}  <FormControlLabel value="2" control={<Radio />} label="Branch"  checked={this.state.optiontype === "2"}/>
        <FormControlLabel value="3" control={<Radio />} label="Batch"  checked={this.state.optiontype === "3"}/>
		<br/><FormControlLabel value="4" control={<Radio />} label="Student" checked={this.state.optiontype === "4"}/>
		<FormControlLabel value="5" control={<Radio />} label="Class" checked={this.state.optiontype === "5"} />
		<FormControlLabel value="6" control={<Radio />} label="All" checked={this.state.optiontype === "6"}/>

        
      </RadioGroup>
    </FormControl></p>	
	{this.state.optiontype!=="" &&(
	<div className="optionstypedrp">
	{this.state.optiontype==="1" &&(<React.Fragment><p><FormControl  className="stadrps">
         
        <Select
         native  fullWidth
		 
		 
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.rolelabel, value: this.state.trust }}
          onChange={this.myChangeHandler}
          id="trust"
		   value={this.state.trust}
		  name="trust"
		
		
        >
		{this.state.trustarray.map(trust => (
            <option  
            
              value={trust.value}
            >
              {trust.display}
            </option  >
          ))}
		
        </Select></FormControl>
		
				</p>
	
			  
			  </React.Fragment>)}
		{(this.state.optiontype==="2" || (localStorage.getItem("MMCrole")==="1" && this.state.optiontype==="5")  ) &&(<React.Fragment>	
		<p><FormControl  className="stadrps">
         
        <Select
         native  fullWidth		 
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.rolelabel, value: this.state.branch }}
          onChange={this.myChangeHandler}
          id="branch"
		   value={this.state.branch}
		  name="branch"
		
		
        >
		{this.state.brancharray.map(branch => (
            <option  
            
              value={branch.value}
            >
              {branch.display}
            </option  >
          ))}
		
        </Select></FormControl>
		
				</p>	</React.Fragment>)}	  
			  
{this.state.optiontype==="3" &&(<React.Fragment>	
		<p><FormControl  className="stadrps">
         
        <Select
         native  fullWidth		 
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.rolelabel, value: this.state.batch }}
          onChange={this.myChangeHandler}
          id="batch"
		   value={this.state.batch}
		  name="batch"
		
		
        >
		{this.state.batcharray.map(batch => (
            <option  
            
              value={batch.value}
            >
              {batch.display}
            </option  >
          ))}
		
        </Select></FormControl>
		
				</p>	</React.Fragment>)}		  
	{this.state.optiontype==="5" &&(<React.Fragment>	
		<p><FormControl  className="stadrps">
         
        <Select
         native  fullWidth
		 
		 
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.rolelabel, value: this.state.classes }}
          onChange={this.myChangeHandler}
          id="classes"
		   value={this.state.classes}
		  name="classes"
		
		
        >
		{this.state.classesarray.map(classes => (
            <option  
            
              value={classes.value}
            >
              {classes.display}
            </option  >
          ))}
		
        </Select></FormControl>
		
				</p></React.Fragment>)}	
				
			

	{this.state.optiontype==="4" &&(<React.Fragment>	
		<p><FormControl  className="stadrps">
         
        <Select
         native  fullWidth
		 
		 
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.rolelabel, value: this.state.student }}
          onChange={this.myChangeHandler}
          id="student"
		   value={this.state.student}
		  name="student"
		
		
        >
		{this.state.studentarray.map(student => (
            <option  
            
              value={student.value}
            >
              {student.display}
            </option  >
          ))}
		
        </Select></FormControl>
		
				</p></React.Fragment>)}	
	
</div>)}

{this.state.notype==="1" && (
<div >
<p> <span className="validation">*</span> Description</p>
	
	<p><TextField name="description" 
	   onChange={this.myChangeHandler} 
	   fullWidth
	   multiline
          rows={4}
	   value={this.state.description}
			 
			  ref={this.usernameInput}
			  inputProps={{tabIndex: "4"}} 
	   InputLabelProps={{ shrink: true }}
                          /></p>
						  
			<p className="searchbutton">	

<Button variant="contained" onClick={this.handleNotifypanel} className={useStyles.submit} startIcon={<ReadMoreIcon />}>
        More Option
      </Button></p>
	
</div>)}
<div className="clear"></div>

{this.state.ispanel && (<React.Fragment>	
	{(this.state.notype==="1" ||  this.state.notype==="3") && (
	
	
	 <p>Remainder Date
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-around">	 
      <DatePicker fullWidth
        value={this.state.rmdate || Date.now()}
        onChange={this.handfrChanges}
		variant="inline"
		inputVariant="outlined"
		 margin="normal"            
            format="dd/MM/yyyy"			
        autoOk name="rmdate"
        ampm={false}
      />
	   </Grid>
    </MuiPickersUtilsProvider></p>
		
		
	)}</React.Fragment>	)}

{this.state.ispanel && (<React.Fragment>	
{(this.state.notype==="2" || this.state.notype==="1" ) && (
<div >
	<DropzoneArea dropzoneText="Upload/Browse Files"   maxFileSize={5000000} Icon={CloudUploadIcon} dropzoneClass="filesupld" filesLimit={1} acceptedFiles={['application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint,application/pdf,image/jpeg,image/jpg,image/png,image/gif']}
  onChange={(files) => {console.log('Files:', files);
  var file, img;
  var _URL = window.URL || window.webkitURL;
  this.setState({ imgfile: files })
  }
  }
   
/>
	{this.state.prevca_icon!='' && (
		
			<a href={Serverurl+"uploads/"+this.state.prevca_icon} target='_blank'>View</a>
	)}
	</div>
	)}</React.Fragment>	)}
	
	<div className="clear">&nbsp;</div>
		
       <div className="actionbtn">
<div className="cancelbtn">	  
        <Button onClick={this.handleClickOpen} color="secondary">
          Cancel
        </Button></div> 
<div className="savebtn">
        <Button onClick={this.handleClose} color="secondary">
          Save
        </Button>
		</div>
<div className="clear"></div>

 </div>     
	</div>		 
	 </DialogContent>
      
    </Dialog>
</React.Fragment>

) }
} 
  









