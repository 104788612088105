import React, { Component } from "react";
import ReactDOM from "react-dom";
import Serverurl from './Apiurl';
import axios from 'axios'; 
import Table from '@mui/material/Table';
import PropTypes from 'prop-types';
import { Navigate  } from 'react-router-dom';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import SearchIcon from '@mui/icons-material/Search';
import TableRow from '@mui/material/TableRow'; 
import { makeStyles } from '@mui/styles'; 
import Paper from '@mui/material/Paper';  
import TableSortLabel from '@mui/material/TableSortLabel';
import TableBody from '@mui/material/TableBody'; 
import TableCell from '@mui/material/TableCell';  
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';  
import TablePagination from '@material-ui/core/TablePagination'; 
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,DatePicker, 
  KeyboardDatePicker,
  DateTimePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { addDays } from "date-fns";
import Moment from 'react-moment';
import moment from 'moment';
import Grid from '@mui/material/Grid';
import CoPresentIcon from '@mui/icons-material/CoPresent';
const headCells = [
  {id:'',label:'Sl No'},
  { id: 'sr_studentname', numeric: false, label: 'Student Name' },
  { id: 'sr_studentcode', numeric: false, label: 'Student Code' },

   { id: '',  label: '' },

  
  ]
function stableSort(array, cmp) {
	  const stabilizedThis = array.map((el, index) => [el, index]);
	  stabilizedThis.sort((a, b) => {
		const order = cmp(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	  });
	  return stabilizedThis.map(el => el[0]);
}
function desc(a, b, orderBy) {
	  if (b[orderBy] < a[orderBy]) {
		return -1;
	  }
	  if (b[orderBy] > a[orderBy]) {
		return 1;
	  }
	  return 0;
}
function getSorting(order, orderBy) {
	return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

function EnhancedTableHead(props) {
		const { classes,  order, orderBy,  rowCount, onRequestSort } = props;
		const createSortHandler = property => event => {
		onRequestSort(event, property);
		};

	  return (
		<TableHead>
		  <TableRow>
			
			{headCells.map(headCell => (
			  <TableCell
				key={headCell.id}
				align={headCell.numeric ? 'right' : 'left'}
				padding={headCell.disablePadding ? 'none' : 'default'}
				sortDirection={orderBy === headCell.id ? order : false}
			  >
				<TableSortLabel
				  active={orderBy === headCell.id}
				  direction={orderBy === headCell.id ? order : 'asc'}
				  onClick={createSortHandler(headCell.id)}
				>
				  {headCell.label}
				  {orderBy === headCell.id ? (
					<span className={useStyles.visuallyHidden}>
					  {order === 'desc' ? ' ' : ' '}
					</span>
				  ) : null}
				</TableSortLabel>
			  </TableCell>
			))}
		  </TableRow>
		</TableHead>
	  );
}


EnhancedTableHead.propTypes = {
	  classes: PropTypes.object.isRequired,
	 
	  onRequestSort: PropTypes.func.isRequired,
	 
	  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	  orderBy: PropTypes.string.isRequired,
	  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 
 icon: {
   verticalalign:'middle',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
});

export default class BulkAttendance extends Component {
	
	constructor(props) {
    super(props);
	 var date = addDays(moment().toDate(),0);
	
		var month="",  month1="",date1="";
		if((date.getMonth() + 1)<10)
		{
			month="0"+ (date.getMonth() + 1)		 
		}else{month=(date.getMonth() + 1)}
		if((date.getDate() )<10)
		{
			date1="0"+ (date.getDate())		 
		}else{date1=(date.getDate() )}
		
	 this.state = {redirect: false, Studdata:[],page: 0,allchecked:false ,subjects:'0',errormessage:'',open:false,sylabus:'2',prevpage:0,totaldata:0,rowsPerPage:100,rowsPaging:[],Classarr:[],classid:'0',classlabel:'Select Class',
	 fdate:addDays(moment().toDate(),0),fdate1:date.getFullYear() + '-' + month + '-' +date1, sr_studentname:'', 
	 order:'desc' ,orderBy:'',Batcharr:[],batchlabel:'Select batch',subjectlabel:'Select Subject',batchid:'',subjectarr:[],primarybtntext:'Save'}
	 
	}
	
	
componentDidMount() {
	
		
	
	
	document.title = "MM Coching Center - Bulk Attendance";
	
	if(localStorage.getItem("MMCuserid")==null)
		  {

		   this.setState({
            redirect: true,
			pagename:'/Login'
          })
		  }
	 this.ClassBind();
}
myChangeHandler = (event) => {
		
		const re = /^[0-9\b]+$/;
  let nam = event.target.name;
  let val = event.target.value;

	  
	   this.setState({[nam]: val}, () => {
if(nam==="classid")
	   {
		this.BatchBind();	   
 	
	   }
	   
}); 
	   
	   	
}
ClassBind()
{
	axios.get(Serverurl+'ajaxtrustandclass.php?action=getall&tc_type=2')
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
						Classarr:resp.data	
							}) 
			}
	
	   }) .then(data => {
        let classFromApi = this.state.Classarr.map(row => {
                          
          return {value: row.tc_id, display:row.tc_title}
        });
      
		
        this.setState({
          Classarr: [{value: '0', display: 'Select Class'}].concat(classFromApi)
        });
      })

}
handleSyllabusChange = (event) => {
 
	 
  let nam = event.target.name;
  let val = event.target.value;

	  
	   this.setState({[nam]: val,subjects:0}, () => {
		   
		   
if(val==="3" && this.state.classid!="0")
	   {
		  
 	this.SubjectsBind();
	   }else{
		 	this.BatchBind();   
		   
	   }
});  
}

SubjectsBind()
{
	axios.get(Serverurl+'ajaxsubjects.php?action=getall&sb_class_id='+this.state.classid+'&sb_syllabustype='+this.state.sylabus)
       .then(resp => { 		
			if(resp.data.length>0){
				


				this.setState({
						subjectarr:resp.data	
							}) 
			}else{
				this.setState({
						subjectarr:[]	
							}) 
			}
			
	   }).then(data => {
        let subFromApi = this.state.subjectarr.map(row => {
                          
          return {value: row.sb_id, display:row.sb_title}
        });
      
		
        this.setState({
          subjectarr: [{value: '0', display: 'Select Subject'}].concat(subFromApi)
        });
      })

}
BatchBind()
{
	axios.get(Serverurl+'ajaxbatch.php?action=list&bc_class_id='+this.state.classid+'&bc_syllbustype='+this.state.sylabus+'&bc_br_id='+localStorage.getItem("MMCbranchid"))
        .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
						Batcharr:resp.data	
							}) 
			}else{
				
				this.setState({
						Batcharr:[]	
							}) 
			}
	this.SubjectsBind();
	   }).then(data => {
        let batchFromApi = this.state.Batcharr.map(row => {
                          
          return {value: row.bc_id, display:row.bc_title}
        });
      
		
        this.setState({
          Batcharr: [{value: '0', display: 'Select Batch'}].concat(batchFromApi)
        });
      })

}
handleClickSearch=()=>{   
 if(this.state.classid=='0'){
						
				this.setState({errormessage:"Please Select Class",open:true});
		 window.scrollTo({        top: 0     })
	return false; 
				}
	if(this.state.sylabus!=="3")			{
   if(this.state.batchid=='0'){
						
				this.setState({errormessage:"Please Select Batch",open:true});
		 window.scrollTo({        top: 0     })
	return false; 
				}
				}
				
			if(this.state.sylabus==="3")			{
   if(this.state.subjects=='0'){
						
				this.setState({errormessage:"Please Select Subjects",open:true});
		 window.scrollTo({        top: 0     })
	return false; 
				}
				}		
  this.apicall(); 

	
}
 handfrChanges = (date) => {
	 
	 var month="",strdate="";
	
	 if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)}
	 if((date.getDate() )<10)
	 {
		strdate="0"+ (date.getDate() )		 
	 }else{strdate=(date.getDate() )}
	
     this.setState({ fdate:date,
fdate1:date.getFullYear() + '-' + month + '-' +strdate
	 })
	
	
  } 
apicall(){
	
	 axios.get(Serverurl+'ajaxstudentadmission.php?action=attenstud&sa_year_id='+localStorage.getItem("MMCyearid")+'&sa_date='+this.state.fdate1+'&sa_class_id='+this.state.classid+'&sa_bc_id='+this.state.batchid+'&sa_br_id='+localStorage.getItem("MMCbranchid")+'&sa_subjects='+this.state.subjects+'&sr_studentname='+this.state.sr_studentname+'&sr_issupend=0')
     
	
  .then(resp => {  
		
    if(resp.data.length>0){
	this.setState({  	Studdata:resp.data});
	}
	else{
		   this.setState({Studdata:[]});
	 }
  })
}
	  handleRequestSort = (event, property) => {
  const isAsc = this.state.orderBy === property && this.state.order === 'asc';
  

  this.setState({   orderBy: property,
    order:isAsc  ? 'desc' : 'asc'
   
  });
};
OnhandleSubmit= (event) => 
		{
		event.preventDefault();
let r = window.confirm("Do you really want to Save?");
 if(r == true){
var studArray = this.state.Studdata;
let strsubdata = "",strsubuser="";
 var subnewRow = studArray.map((rowssub, j) => { 
 
	 
strsubdata=strsubdata+strsubuser+rowssub.sa_id+","+rowssub.ispresent;
strsubuser="~";

 })

var formData = new FormData();
				formData.append('action','insert') 	
				formData.append('attend',strsubdata)
				formData.append('batchid',this.state.batchid)
				formData.append('classid',this.state.classid)
				formData.append('date',this.state.fdate1)
				
			formData.append('userid',localStorage.getItem("MMCuserid"))
				axios.post(Serverurl+"ajaxattendance.php", formData, {
				}).then(res => {
					
					this.setState({  errormessage:'Attendance Updated Successfully',open:true});
					
				})
 }
		}
handleErrorclose=(event)=>{
	  
	this.setState({open: false});  
  }
  handleAllStudents=()=>{
	 var studArray = this.state.Studdata;
	 const updatedArray = [...this.state.Studdata];
	  this.setState({allchecked: !this.state.allchecked}, () => {
		  
		  if(this.state.allchecked===false)
		  {
			   var subnewRow = studArray.map((rowssub, j) => {
				   updatedArray[j].ispresent = "0" ;
			   });
		  }
		  else if(this.state.allchecked===true)
		  {
			   var subnewRow = studArray.map((rowssub, j) => {
				   updatedArray[j].ispresent = "1" ;
			   });
		  }
		  
		   this.setState({Studdata: updatedArray,});
	  });
  }
  
handleAbsent = (id,rowsd) => {
const updatedArray = [...this.state.Studdata];

	if(updatedArray[rowsd].ispresent==="0")
	{
		updatedArray[rowsd].ispresent = "1" ;
			  
	}
else{
	updatedArray[rowsd].ispresent = "0" ;
}
 this.setState({Studdata: updatedArray,});


	
  }; 
render() {

if (this.state.redirect === true) {
      return <Navigate to={this.state.pagename} />
    }


  return (
 
  <React.Fragment>
   
<div className="containermain">
<div className="boxheading">
				  <div className="contntpad">
<h2><CoPresentIcon /> Class-wise Attendance</h2>
</div></div>
 <p>  <Collapse in={this.state.open}>
										<Alert
										  action={
											<IconButton
											  aria-label="close"
											  color="inherit"
											  size="small"
											  onClick={() => {
											   this.handleErrorclose();
											  }}
											>
											  <CloseIcon fontSize="inherit" />
											</IconButton>
										  } severity="error"
										>
										{this.state.errormessage}
										</Alert>
								</Collapse></p>
<div className="searchmaster">
<ul>


<li>  
		  <p>Name</p>
		 
		<p>
		<TextField name="sr_studentname" 
	   onChange={this.myChangeHandler} 
	   fullWidth
	   value={this.state.sr_studentname}
			 
			  ref={this.usernameInput}
			inputProps={{tabIndex: "1",maxLength:150}} className="txtbox"
	   InputLabelProps={{ shrink: true }}
                          /></p>
 
 </li>

    <li>
<p> Date. <span className="validationtxt"></span></p>
 <p>
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-around">
	 
      <DatePicker fullWidth
        value={this.state.fdate || addDays(moment().toDate(),1)}
        onChange={this.handfrChanges}
		maxDate={addDays(moment().toDate(),0)}
            format="dd/MM/yyyy"
        autoOk name="fdate"
        ampm={false} inputVariant="outlined"
      />
	   </Grid>
    </MuiPickersUtilsProvider>
		</p></li>
		 <li><p><span className="validation">*</span> Sylabus Type</p>
	  <Radio
        checked={this.state.sylabus === '2'}
        onChange={this.handleSyllabusChange}
        value="2"
        name="sylabus"
        inputProps={{ 'aria-label': 'B' }}
      />State<Radio
        checked={this.state.sylabus === '1'}
        onChange={this.handleSyllabusChange}
        value="1"
        name="sylabus"
        inputProps={{ 'aria-label': 'A' }}
      />CBSE
    <Radio
        checked={this.state.sylabus === '3'}
        onChange={this.handleSyllabusChange}
        value="3"
        name="sylabus"
        inputProps={{ 'aria-label': 'A' }}
      />PUC

	  
	 </li>
		<li><p><span className="validation">*</span>Class</p>
	  <p>
	  <FormControl fullWidth  size="small">
       
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.classlabel, value: this.state.classid }}
          onChange={this.myChangeHandler}
          id="state"
		   value={this.state.classid}
		  name="classid"
		
		
        >
		{this.state.Classarr.map(row => (
            <option  
            
              value={row.value}
            >
              {row.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
	  
	  </li> 
	  
	
	  <li><p><span className="validation">*</span>Batch</p>
	  <p>
	  <FormControl  fullWidth size="small" >
       
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.batchlabel, value: this.state.batchid }}
          onChange={this.myChangeHandler}
          id="state"
		   value={this.state.batchid}
		  name="batchid"
		
		
        >
		{this.state.Batcharr.map(row => (
            <option  
            
              value={row.value}
            >
              {row.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
	  
	  </li> {this.state.sylabus==="3" && (
	  <li><p><span className="validation">*</span>Subjects</p>
	  <p>
	  <FormControl  fullWidth size="small" >
       
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.subjectlabel, value: this.state.subjects }}
          onChange={this.myChangeHandler}
          id="state"
		   value={this.state.subjects}
		  name="subjects"
		
		
        >
		{this.state.subjectarr.map(row => (
            <option  
            
              value={row.value}
            >
              {row.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>
	  
	  </li>)}
	  
	  <li><p><br/></p>
	<p>
	<Button variant="contained" onClick={this.handleClickSearch} className="frmsecondarybtn" endIcon={<SearchIcon />}>
        Search
      </Button></p></li></ul>
</div>
<Paper className={useStyles.root}>


  <TableContainer className={useStyles.container}>  
                <Table stickyHeader aria-label="sticky table" id="purreport">  
<TableHead> 
<TableRow> 

<TableCell>
<Checkbox
  checked={this.state.allchecked}
  onChange={()=>{this.handleAllStudents()}}
  inputProps={{ 'aria-label': 'controlled' }}
/> 
 </TableCell> 
 
 <TableCell>Sl No</TableCell>
         <TableCell>
Student
 </TableCell> <TableCell>
Student Code
 </TableCell>       
						
                      </TableRow></TableHead>
					  
					  
                      <TableBody>
                     {stableSort(this.state.Studdata, 
			getSorting(this.state.order,
			this.state.orderBy)).map((item,index) => {  
			let i=parseFloat(((this.state.page)+1)-1)*parseFloat(this.state.rowsPerPage)+(parseFloat(index)+1);

           return (
 <React.Fragment>
 <TableRow key={index} >


 <TableCell>{item.ispresent==="0" && (
<Checkbox
  
  onChange={()=>{this.handleAbsent(item.sa_id,index)}}
  inputProps={{ 'aria-label': 'controlled' }}
/> 
)}{item.ispresent==="1" && (
<Checkbox
  checked
  onChange={()=>{this.handleAbsent(item.sa_id,index)}}
  inputProps={{ 'aria-label': 'controlled' }}
/> 
)}</TableCell>


 <TableCell>{i}</TableCell>
 <TableCell>
 
 
 {item.sr_studentname}</TableCell>
<TableCell>{item.sr_studentcode}</TableCell>


</TableRow>

 </React.Fragment>	
);
					})}
					
					{this.state.Studdata.length===0 &&(
					
					<TableRow key={0} >
<TableCell align="center" colSpan="3">No Records</TableCell>
					</TableRow>
					) }
					
                      </TableBody>


</Table>
  </TableContainer> 
   
  </Paper> 
  
  	<br/>
	<p className="aligncenter"><Button variant="standard" className="frmPrimarybtn" endIcon={<CheckRoundedIcon />} onClick={this.OnhandleSubmit}>
	{this.state.primarybtntext}
	</Button></p>
</div>
  </React.Fragment>
)}
}






