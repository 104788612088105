import React, { Component } from "react";
import { Navigate  } from 'react-router-dom';
import Serverurl from './Apiurl';
import axios from 'axios';
import call from './images/call-icon.png';
import dob from './images/dob-icon.png';
import gender from './images/gender-female.png';
import payment from './images/payment-history.png';
import remainder from './images/remainder.png';
import basicicon from './images/basic-detail.png';
import downloads from './images/downloads.png';import ReactToPrint from 'react-to-print';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import institute from './images/institute-icon.png';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';  
import Button from '@mui/material/Button';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableBody from '@mui/material/TableBody'; 
import TableCell from '@mui/material/TableCell';  
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';  
import TablePagination from '@material-ui/core/TablePagination'; 
import PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow'; 
import ReplyIcon from '@mui/icons-material/Reply';
import StudentattendanceGraph from './StudentattendanceGraph';
import Studentexamgraph from './Studentexamgraph';
import Studentexamgraphprint from './Studentexamgraphprint';
import logo from './images/weblogo.png';
import headerbgimg  from './images/1.png';
import footerbgimg  from './images/2.png';

export default class StudentPage extends Component 
{
	
	constructor(props) 
	{
		super();
		this.state = {studenetdata:[],downloads:[],downlodscnt:0,isprint:false,notificationdata:[],notifycnt:0,remainder:[],remaindercnt:0,feecnt:0,rowsPerPage:5,page:0,prevpage:0,schoolname:'',feedata:[],balamount:0,batcharr:[],batchid:'',batchlabel:'Select Batch',
		rmrowsPerPage:5,rmpage:0,rmprevpage:0,norowsPerPage:5,srclass:'',studprogimg:'',srbatch:'',studfather:'',nopage:0,noprevpage:0,dowrowsPerPage:5,dowpage:0,dowprevpage:0,}
		this.updateDonenewParent = this.updateDonenewParent;
	}
	 updateDonenewParent = (text) => {this.setState({isprint: true}, () => {
	

this.setState({isprint:false });

});  }
myChangeHandler = (event) => {		
		const re = /^[0-9\b]+$/;
  let nam = event.target.name;
  let val = event.target.value;	 
this.setState({[nam]: val}, () => {
if(nam==="batchid")
	   {
		   
 	 this.OnloadStudentadmision(val);
	 
	 
	   }
}); 

}
 getParameterByName(name, url) {
    if (!url) url = window.location.href;
    url = url.toLowerCase(); 
    name = name.replace(/[\[\]]/g, "\\$&").toLowerCase();
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}
componentDidMount()
	{
		
		
	if(localStorage.getItem("MMCuserid")===null)
	{
		this.setState({
							redirect: true,
							pagename:'/Login'
						  }) 
	}
localStorage.setItem("page",this.state.rowsPerPage)
	this.OnloadStudentGetdetails();
	}
	
	componentDidUpdate() {
	
  if(this.state.prevpage !== this.state.page) {
    this.setState({   prevpage: this.state.page});


  }
}
handleChangePage = (event, newPage) => {  
   
    let pageval = localStorage.getItem("page");
    this.setState({  
      page: newPage,
      rowsPerPage:parseInt(pageval)
    });
   
 
    setTimeout(() =>   this.StudentefeeHistory(this.state.batchid,this.state.page,this.state.rowsPerPage) ,500); 
  };  
  handleChangermPage = (event, newPage) => {  
   
    let pageval = localStorage.getItem("page");
    this.setState({  
      rmpage: newPage,
      rmrowsPerPage:parseInt(pageval)
    });
   
 
    setTimeout(() =>   this.RemainderApicall(this.state.rmpage,this.state.rmrowsPerPage) ,500); 
  };
  
    handleChangedowPage = (event, newPage) => {  
   
    let pageval = localStorage.getItem("page");
    this.setState({  
      dowpage: newPage,
      dowrowsPerPage:parseInt(pageval)
    });
   
 
    setTimeout(() =>   this.DownloadsApicall(this.state.dowpage,this.state.dowrowsPerPage) ,500); 
  };
handleChangenoPage = (event, newPage) => {  
   
    let pageval = localStorage.getItem("page");
    this.setState({  
      nopage: newPage,
      norowsPerPage:parseInt(pageval)
    });
   
 
    setTimeout(() =>   this.NotificationApicall(this.state.nopage,this.state.norowsPerPage) ,500); 
  };
   handleChangeRowsPerPage = event => {  
  
    let pageval = event.target.value;
    localStorage.setItem("page",pageval)

    setTimeout(() =>  this.componentDidUpdate() ,500);
    this.setState({  
      page: 0,
      rowsPerPage:parseInt(pageval),
	  prevpage:-1
    }); 
	
	
  };
StudentefeeHistory(said,page,rowsPerPage)
{
	
axios.get(Serverurl+'ajaxfeepayments.php?action=list&fp_isfirstpay=2&fp_sa_id='+said+'&index='+page+'&maxrecrd='+rowsPerPage)
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
							
							feedata:resp.data,feecnt:resp.data[0]["count"]
						  }) 
			}
			this.RemainderApicall(this.state.rmpage,this.state.rmrowsPerPage)
	   });
}

RemainderApicall(page,rowsPerPage)
	{
		axios.get(Serverurl+'ajaxnotifications.php?action=listuser&no_type=3&studenid='+this.state.batchid+'&index='+page+'&maxrecrd='+rowsPerPage)
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({remainder:resp.data,remaindercnt:resp.data[0].count});
			}
		this.NotificationApicall(this.state.nopage,this.state.norowsPerPage)	
	   });
		
	}
NotificationApicall(page,rowsPerPage)
	{
		axios.get(Serverurl+'ajaxnotifications.php?action=listuser&no_type=1&studenid='+this.state.batchid+'&index='+page+'&maxrecrd='+rowsPerPage)
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({notificationdata:resp.data,notifycnt:resp.data[0].count});
			}
		this.DownloadsApicall(this.state.dowpage,this.state.dowrowsPerPage) 	
	   });
		
}
DownloadsApicall(page,rowsPerPage)
	{
		axios.get(Serverurl+'ajaxnotifications.php?action=listuser&no_type=2&studenid='+this.state.batchid+'&index='+page+'&maxrecrd='+rowsPerPage)
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({downloads:resp.data,downlodscnt:resp.data[0].count});
			}
			
	   });
		
}
BatchBind(srid)
{
	axios.get(Serverurl+'ajaxstudentadmission.php?action=batchstud&sa_sr_id='+srid)
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
						batcharr:resp.data	,
						batchid:resp.data[0]["sa_id"],
							}) 
							
							 this.OnloadStudentadmision(resp.data[0]["sa_id"])
			}
	
	   }).then(data => {
        let batchFromApi = this.state.batcharr.map(row => {
                          
          return {value: row.sa_id, display:row.saclass+" - ("+row.sayear+")"}
        });
     
		
        this.setState({
          batcharr: batchFromApi
        });
      })

}
handlePrint() {
	
	this.setState({isprint: true}, () => {
document.getElementById('print-button').click();	

this.setState({isprint:false });

}); 

}
	OnloadStudentadmision(said)
{
	axios.get(Serverurl+'ajaxstudentadmission.php?action=list&sa_id='+said)
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({srbranch:resp.data[0]["br_title"],
							srclass:resp.data[0]["tc_title"],srbatch:resp.data[0]["bc_title"],
							studename:resp.data[0]["sr_studentname"],studcode:resp.data[0]["sr_studentcode"] +" - "+resp.data[0]["bc_title"] 
							,schoolname:resp.data[0]["sa_schoolname"],
							
							balamount:parseFloat(resp.data[0]["totalfee"]) -parseFloat( resp.data[0]["balamt"]),totalfee:resp.data[0]["totalfee"]
						  }) 
			}
		this.StudentefeeHistory(said,this.state.page,this.state.rowsPerPage);
	   });

}
	OnloadStudentGetdetails()
{
		let search = window.location.href;
let srid = this.getParameterByName('srid',search);
	axios.get(Serverurl+'ajaxstudentregistration.php?action=list&sr_id='+srid)
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
							studfather:resp.data[0]["sr_fathername"],
							studenetdata:resp.data,studprogimg:resp.data[0]["sr_profileimg"],
						  }) 
			}
		this.BatchBind(srid);	
	   });

}

render(){

if (this.state.redirect === true) {
      return <Navigate to={this.state.pagename} />
    }
	return (
<React.Fragment>
<a href="#/StudentregistrationReport" title="Back"><ReplyIcon className="alignright"/></a>
 <div className="studentleftdiv">
<div className="studentpanel">
		<div className="boxheading">
		<div className="admitpad"> <h2><img src={basicicon} /> Basic details</h2></div></div>
<div className="admitpad">


<p>
	  <FormControl fullWidth  size="small">
       
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.batchlabel, value: this.state.batchid }}
          onChange={this.myChangeHandler}
          id="state"
		   value={this.state.batchid}
		  name="batchid"
		
		
        >
		{this.state.batcharr.map(row => (
            <option  
            
              value={row.value}
            >
              {row.display}
            </option  >
          ))}
		
        </Select>
 </FormControl></p>

 {(this.state.studenetdata).map((item,index) => { 
let strgenrtype="";
if(item.sr_gender==="1" )
{	strgenrtype="Son";	
}else{
strgenrtype="Daughter";	
}
           return ( <React.Fragment>
		<div className="studbasicimg">   
		  {item.sr_profileimg!=="" && (
			<img src={Serverurl+'uploads/'+item.sr_profileimg} />
			)}
			{item.sr_profileimg==="" && (
		<img src={Serverurl+'uploads/user.jpg'} />
			)}
		</div>
		<h3 className="aligncenter">{item.sr_studentname}</h3>
		
		
		<p className="aligncenter">{strgenrtype} of
		{item.sr_fathername!=="" && (<React.Fragment>
		
		  &nbsp;{item.sr_fathername}
		</React.Fragment>)}
		{item.sr_mothername!=="" && (<React.Fragment>
		
		 &nbsp;and {item.sr_mothername}
		</React.Fragment>)}
		</p>
		
		<ul className="studecontactdet">
		<li>
		<p>  <a href={'tel:'+item.sr_phonenumber}><img src={call} /></a></p>
		<p> <a href={'tel:'+item.sr_phonenumber}>{item.sr_phonenumber}</a></p>
		</li>
		<li>
		<p>  <img src={dob} /></p>
		<p> {item.dob}</p>
		</li>
			<li>
		<p>  <img src={gender} /></p>
		<p> {item.srgender}</p>
		</li>
		
		<li>
		<p>  <img src={institute} /></p>
		<p> {this.state.schoolname}</p>
		</li>
		
		</ul>
 </React.Fragment>	
);
					})}
</div>

</div>

 <div className="balanceamntbg">
 <p>Balance Payment</p>
 <h2><CurrencyRupeeIcon />{this.state.balamount}</h2></div>

    <div className="studentpanel">
		<div className="boxheading">
		<div className="admitpad"> <h2><img src={payment} /> Payment History</h2></div></div>
   <div className="admitpad">

 <Paper >
  <TableContainer >  
                <Table stickyHeader aria-label="sticky table">  

					  
					  
                      <TableBody>
                     {(this.state.feedata).map((item,index) => {  
           return (
 <React.Fragment>
 <TableRow key={index} >


<TableCell>{item.feedate}</TableCell>
<TableCell>{item.fp_receiptnumber}</TableCell>
<TableCell>Rs.{item.fp_amount}</TableCell>




 </TableRow>

 </React.Fragment>	
);
					})}
					
					{this.state.feecnt===0 &&(
					
					<TableRow key={0} >
<TableCell align="center" colSpan="2">No Records</TableCell>
					</TableRow>
					) }
					
                      </TableBody>


</Table>
  </TableContainer> 
  <TablePagination  
        rowsPerPageOptions={[]}  
        component="div"  
        count={this.state.feecnt}  
        rowsPerPage={this.state.rowsPerPage}  
        page={this.state.page}  
        onPageChange={this.handleChangePage}  
        onChangeRowsPerPage={this.handleChangeRowsPerPage}  
      />   
  </Paper>

	</div>

	</div>




<div className="studentpanel">
		<div className="boxheading">
		<div className="admitpad"> <h2><img src={remainder} /> Remainder</h2></div></div>
   <div className="admitpad remainde-col">

 <Paper >
  <TableContainer >  
                <Table stickyHeader aria-label="sticky table">  

					  
					  
                      <TableBody>
                     {(this.state.remainder).map((item,index) => {  
           return (
 <React.Fragment>
 <TableRow key={index} >


<TableCell><h5>{item.date}</h5>
<br/>
{item.no_description}</TableCell>






 </TableRow>

 </React.Fragment>	
);
					})}
					
					{this.state.remaindercnt===0 &&(
					
					<TableRow key={0} >
<TableCell align="center" >No Records</TableCell>
					</TableRow>
					) }
					
                      </TableBody>


</Table>
  </TableContainer> 
  <TablePagination  
        rowsPerPageOptions={[]}  
        component="div"  
        count={this.state.remaindercnt}  
        rowsPerPage={this.state.rmrowsPerPage}  
        page={this.state.rmpage}  
        onPageChange={this.handleChangermPage}  
        
      />   
  </Paper>

	</div>

	</div>
	
	
	
	
	




<div className="studentpanel">
		<div className="boxheading">
		<div className="admitpad"> <h2><img src={remainder} /> Notification</h2></div></div>
   <div className="admitpad remainde-col">

  <Paper >
  <TableContainer >  
                <Table stickyHeader aria-label="sticky table">  

					  
					  
                      <TableBody>
                     {(this.state.notificationdata).map((item,index) => {  
           return (
 <React.Fragment>
 <TableRow key={index} >


<TableCell><h5>{item.date}</h5>
<br/>
{item.no_description}</TableCell>






 </TableRow>

 </React.Fragment>	
);
					})}
					
					{this.state.notifycnt===0 &&(
					
					<TableRow key={0} >
<TableCell align="center" >No Records</TableCell>
					</TableRow>
					) }
					
                      </TableBody>


</Table>
  </TableContainer> 
  <TablePagination  
        rowsPerPageOptions={[]}  
        component="div"  
        count={this.state.notifycnt}  
        rowsPerPage={this.state.norowsPerPage}  
        page={this.state.nopage}  
        onPageChange={this.handleChangenoPage}  
        
      />   
  </Paper>

	</div>

	</div>

</div>

 <div className="studentrightdiv">
 
  <StudentattendanceGraph said={this.state.batchid}/>
 <Studentexamgraph said={this.state.batchid}/>
<ReactToPrint 
            trigger={() => <button  id="print-button" className="exportbtn">Print </button>}
			content={() =>  this.componentRef} /> 
<div className="studentpanel">
		<div className="boxheading">
		<div className="admitpad"> <h2><img src={downloads} /> Downloads</h2></div></div>
   <div className="admitpad remainde-col">

 <Paper >
  <TableContainer >  
                <Table stickyHeader aria-label="sticky table">  

					  
					  
                      <TableBody>
                     {(this.state.downloads).map((item,index) => {  
           return (
 <React.Fragment>
 <TableRow key={index} >


<TableCell>{item.no_title}</TableCell>
<TableCell> 

<Button variant="standard" className="btndonwloads" endIcon={<CloudDownloadIcon />}>
<a href={Serverurl+"uploads/"+item.no_file} target="_blank">  Download</a>
</Button>

</TableCell>







 </TableRow>

 </React.Fragment>	
);
					})}
					
					{this.state.downlodscnt===0 &&(
					
					<TableRow key={0} >
<TableCell align="center" >No Records</TableCell>
					</TableRow>
					) }
					
                      </TableBody>


</Table>
  </TableContainer> 
  <TablePagination  
        rowsPerPageOptions={[]}  
        component="div"  
        count={this.state.downlodscnt}  
        rowsPerPage={this.state.norowsPerPage}  
        page={this.state.dowpage}  
        onPageChange={this.handleChangedowPage}  
        
      />   
  </Paper>

	</div> 

	</div>
	
   

 </div>
 <PrintcopyStudentPage ref="PrintcopyStudentPage"   ref={el => (this.componentRef = el)} profimg={this.state.studprogimg} branch={this.state.srbranch} said={this.state.batchid} classna={this.state.srclass} batch={this.state.srbatch} name={this.state.studename} scode={this.state.studcode} father={this.state.studfather}  school={this.state.schoolname} />
 </React.Fragment>
	  )
	}
}

 class PrintcopyStudentPage extends Component 
{
	
	constructor(props) 
	{
		super();
		this.state = {studenetdata:[],studename:'',studcode:'',schoolname:'',srfathername:'',stuclass:''}
	}componentDidMount()
	{

	}
	
  

 render() {
	 return (
		<React.Fragment>
		
		 
		
		<div className="yourClassName">

	
<div className="pinkbg">
<div className="headerbgimage">
<img src={headerbgimg}/>

</div>	

		<div className="aligncenter">
<h1>STUDENT ACADEMIC PROFILE</h1>
</div>

<div className="studentdiv">

<div className="leftboxclass">
<p><span className="colorprint">Class:</span> &nbsp;<span className="classbox">{this.props.classna}</span></p>
</div>


<div className="leftboxbatch">
<p><span className="colorprint">Batch:</span> &nbsp;<span className="classbox">{this.props.batch}</span></p>
</div>
<div className="clear"></div>
<div className="printimg">
{this.props.profimg!=="" && (

<img src={Serverurl+"uploads/"+this.props.profimg} />
)}
</div>
<div className="printcontnt">
<div className="leftbox colorprint">
<p>Name of the Student:</p>
</div>


<div className="rightbox">
{this.props.name}
</div>
<div className="clear"></div>

<div className="leftbox colorprint">
<p>Name of the School:</p>
</div>


<div className="rightbox">
{this.props.school}
</div>

<div className="clear"></div>


<div className="leftbox colorprint">
<p>Father's Name:</p>
</div>


<div className="rightbox">
{this.props.father}
</div>

<div className="clear"></div>
</div><div className="clear"></div></div>


<Studentexamgraphprint ref="Studentexamgraphprint"  said={this.props.said}/>








</div>



	       </div>

		</React.Fragment> 
		)
 }
}





