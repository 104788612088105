import React ,{Component} from 'react';


import { Outlet } from 'react-router-dom';





export default class  Usercontentmaster extends Component {


  constructor(props) {
    super();

    this.state = {ao_id: '',CmsData:[],phone:'',email:'',address:''};
   
}
   
      
myChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
  this.setState({[nam]: val});

 }  




componentDidMount() {
	
	
	
	document.title = "Default";
	
	
 


}






   
   
   
  
  render() {
	  

  return (
		
			<React.Fragment>
	<div className="main">	 
<div className="innercontent">
    <Outlet />
</div>
  </div>        
	
	 
	 
	  <div className="copyright"> 
 <div className="copyrgtpadding">
    <p>©Copyright {this.state.year} Pixels, Mysore. All rights reserved. </p>
    </div>
   </div> 	
	
	 
 
	</React.Fragment>
		);
	}
}