import React, { Component } from "react";
import { Navigate  } from 'react-router-dom';
import Serverurl from './Apiurl';
import axios from 'axios';
import notification from './images/notification-icon.png';
import ReplyIcon from '@mui/icons-material/Reply';
export default class MobNotificationView extends Component 
{
	constructor(props) 
	  {
			super();
			
			this.state = {Notdesc:'',nottitle:'',nodate:'',redirect:false,pagename:'',pagelink:''}
	  }
	 getParameterByName(name, url) {
    if (!url) url = window.location.href;
    url = url.toLowerCase(); 
    name = name.replace(/[\[\]]/g, "\\$&").toLowerCase();
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}componentDidMount()
	{
		
		
	if(localStorage.getItem("MMCPrid")===null)
	{
		this.setState({
							redirect: true,
							pagename:'/MobLogin'
						  }) 
		

	}
	
	this.OnloadNotification();
	}
	OnloadNotification()
{
	let search = window.location.href;
let strnoid = this.getParameterByName('noid',search);

let strpage = this.getParameterByName('p',search);

if(strpage==="1")
{
	this.setState({pagelink:'#/MobStudentDashbaord'});
}else
{
	this.setState({pagelink:'#/MobileNotification'});
}
axios.get(Serverurl+'ajaxnotifications.php?action=list&no_id='+strnoid)
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({Notdesc:resp.data[0]["no_description"],
				nottitle:resp.data[0]["no_title"],nodate:resp.data[0]["remainderdate"]});
			}
	   });
}
render() {
	   let deschtml=this.state.Notdesc;
  return (
    <div className="pagebox-col"> 
	<div className="boxheading">
	  <div className="contntpad">
	  <h2><img src={notification} />  {this.state.nottitle}    <a href={this.state.pagelink}><ReplyIcon className="alignright"/></a></h2>
	  </div> </div>
	
	<div className="contntpad">
	<h5>On {this.state.nodate}</h5>
	 <div dangerouslySetInnerHTML={ { __html: deschtml}} />
	
</div>

</div>
)
}
}







