import React ,{Component} from 'react'; 
import ReactDOM from "react-dom";
import Button from '@mui/material/Button';
import {Navigate  } from 'react-router-dom';
import axios from 'axios';
import Serverurl from './Apiurl';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';

export default class MobNotification extends Component {
  constructor(props) {
    super(props);
	
	
    this.state = { notificationdata:[],redirect:false,pagename:'',startindex:0,busy:0,key:'',currentindex:0,totalcnt:0  ,transform:0   
    };
  }
	componentDidMount()
	{
		if(localStorage.getItem("MMCPrid")===null)
	{
		this.setState({
							redirect: true,
							pagename:'/MobLogin'
						  }) 
		

	}
		 window.addEventListener('scroll', this.handleScroll);
		
		this.NotificationApiCall();
		
	}
	
	handleScroll= (event) => {
    let scrollTop = event.srcElement.body.scrollTop,
        itemTranslate = Math.min(0, scrollTop/3 - 60);
let strbusy=this.state.busy;
if(strbusy===0){
    this.setState({
      transform: itemTranslate,busy:1
    }, () => {

		   
 	
	
	this.NotificationApiCall();
	
	   
});  
}
}
componentWillUnmount() {

	
    window.removeEventListener('scroll',  this.handleScroll);

}
	NotificationApiCall(){
		
	 axios.get(Serverurl+'ajaxnotifications.php?action=listuser&studenid='+localStorage.getItem("MMCstudid")+'&no_type=1&maxrecrd=14&index='+this.state.startindex)
       .then(resp => {  
		
    if(resp.data.length>0){
		let curentindex=parseFloat(this.state.currentindex)+parseFloat(resp.data.length);
		
		this.setState({notificationdata:this.state.notificationdata.concat(resp.data),currentindex:curentindex,totalcnt:resp.data[0].count});	
		if(resp.data[0].count>curentindex)
		{
			 this.setState({busy:0,startindex:Number(this.state.startindex)+1});
			
		}
		else if(resp.data[0].count==curentindex)
		{
			 this.setState({busy:1});
			
		}
		
	}
	   });
	   
		
	}
	
	render() { 
	return (
		<React.Fragment>
	 <div className="pagebox-col">
	 <div className="admitpad mobnotificationlist">
	 {this.state.notificationdata.length>0 &&(
	 <ul>
	{this.state.notificationdata.map((item,index) => { return ( 
	 <li key={index}><a href={"#/MobNotificationview?p=2&noid="+item.no_id}>
	 <div className="notheading">
	<h5> {item.no_title}</h5>
	<h6>{item.date}</h6>
	
	</div> 
	{item.no_file!=="" && (
	<div className="donwloadnot">
	<Button variant="standard" className="btndonwloads" endIcon={<CloudDownloadOutlinedIcon />}>
<a href={Serverurl+"uploads/"+item.no_file} target="_blank">  Download</a>
</Button>
	</div>
	)}
	<div className="clear"></div>
	<p>{item.no_description}</p>
	</a>
	 </li>
	 
	 
	 
	 
	 );})}
	</ul>
	 )}
	 </div></div>
 	  	</React.Fragment>
    );
  
  }
}
  
  