import React, { Component } from "react";
import { Navigate  } from 'react-router-dom';
import Serverurl from './Apiurl';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@material-ui/core/Grid';
import ArrowRightAltRoundedIcon from '@mui/icons-material/ArrowRightAltRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@mui/material/IconButton';
import DateFnsUtils from '@date-io/date-fns';
import FormControl from '@mui/material/FormControl';
import registrationhead from './images/enter-student-detail.png';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import payment from './images/payment-history.png';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,DatePicker, 
  KeyboardDatePicker,
  DateTimePicker
} from '@material-ui/pickers';

import { addDays } from "date-fns";
import Moment from 'react-moment';
import moment from 'moment';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';  

import TableSortLabel from '@mui/material/TableSortLabel';
import TableBody from '@mui/material/TableBody'; 
import TableCell from '@mui/material/TableCell';  
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';  
import TablePagination from '@material-ui/core/TablePagination'; 
import PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow'; 


export default class StudentFees extends Component 
{
	constructor(props) 
	{
		super();
		
		var date = addDays(moment().toDate(),0);
		 var month="",date1="";
	
	 if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)}
	 
	 
	  if((date.getDate() )<10)
	 {
		date1="0"+ (date.getDate() )		 
	 }else{date1=(date.getDate())}
		this.state = {primarybtntext:'Pay Now',said:0,feedata:[],remaindrdate:addDays(moment().toDate(),0),remaindrdate1:date.getFullYear() + '-' + month + '-' +date1,feecnt:0,redirect:false,pagename:'',totalfee:0,errormessage:'',open:false,feedate1:date.getFullYear() + '-' + month + '-' +date1,secondarybtntext:'Save and Continue',balamount:0,feedate:addDays(moment().toDate(),0),refno:'', receiptno:'',paymentid:'0',paymentlabel:'Select Payment', rowsPerPage:5,page:0,prevpage:0,}
			
	}
	myChangeHandler = (event) => {
		
		const re = /^[0-9\b]+$/;
  let nam = event.target.name;
  let val = event.target.value;	  
	  if(nam==="fee"   )
  {if (event.target.value === '' || re.test(event.target.value)) {
	  this.setState({[nam]: val}); 
  }
  }
  else{
	  
	   this.setState({[nam]: val});
  }
}
		componentWillMount() { 
localStorage.setItem("pageactive","2")
}
getParameterByName(name, url) {
    if (!url) url = window.location.href;
    url = url.toLowerCase(); 
    name = name.replace(/[\[\]]/g, "\\$&").toLowerCase();
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}
	componentDidMount()
	{
		if(localStorage.getItem("MMCuserid")===null)
		{
			this.setState({redirect: true,
							pagename:'/Login'}) 
		}
		let search = window.location.href;
let said = this.getParameterByName('said',search);

if(said===null)
		{
			this.setState({redirect: true,
							pagename:'/Dashboard?msg=2'}) 
		}this.OnloadGenerateecipt(said);
		
	}
	
handleErrorclose=(event)=>{
	  
	this.setState({open: false});  
  }
	OnloadStudentGetdetails(said)
{
	axios.get(Serverurl+'ajaxstudentadmission.php?action=list&sa_id='+said)
       .then(resp => { 		
			if(resp.data.length>0){
				let strbal=0;
				strbal=parseFloat(resp.data[0]["totalfee"])-parseFloat( resp.data[0]["balamt"])
				
				this.setState({
							said:said,
							studename:resp.data[0]["sr_studentname"],studcode:resp.data[0]["sr_studentcode"] +" - "+resp.data[0]["bc_title"] 
							
							,balamount:strbal,totalfee:resp.data[0]["totalfee"]
						  }) 
			}
		
	   });

}
OnloadGenerateecipt(said)
{
	
	
	
	axios.get(Serverurl+'ajaxfeepayments.php?action=recpt&fp_sa_id='+said)
       .then(resp => { 		
			if(resp.data!=""){
				this.setState({
							
							receiptno:resp.data.replace(/\s+/g, '')
						  }) 
			}
		    this.StudentefeeHistory(said,this.state.page,this.state.rowsPerPage) 
	   });

}
handleChangePage = (event, newPage) => {  
   
    let pageval = localStorage.getItem("page");
    this.setState({  
      page: newPage,
      rowsPerPage:parseInt(pageval)
    });
   
 
    setTimeout(() =>   this.StudentefeeHistory(this.state.batchid,this.state.page,this.state.rowsPerPage) ,500); 
  };  
StudentefeeHistory(said,page,rowsPerPage)
{
	
axios.get(Serverurl+'ajaxfeepayments.php?action=list&fp_isfirstpay=2&fp_sa_id='+said+'&index='+page+'&maxrecrd='+rowsPerPage)
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
							
							feedata:resp.data,feecnt:resp.data[0]["count"]
						  }) 
			}
		this.OnloadStudentGetdetails(said);
	   });
}
handfrChange = (date) => {
	 var month="",date1="";
	
	 if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)}
	 
	 
	  if((date.getDate() )<10)
	 {
		date1="0"+ (date.getDate() )		 
	 }else{date1=(date.getDate())}
     this.setState({ feedate:date,
feedate1:date.getFullYear() + '-' + month + '-' +date1
	 })
	
	
  }
  handleremaindChange = (date) => {
	 var month="",date1="";
	
	 if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)}
	 
	 
	  if((date.getDate() )<10)
	 {
		date1="0"+ (date.getDate() )		 
	 }else{date1=(date.getDate())}
     this.setState({ remaindrdate:date,
remainderdate1:date.getFullYear() + '-' + month + '-' +date1
	 })
	
	
  }
  
OnFeeinsertSubmit= (event) => 
		{
		event.preventDefault();
		
		let feedate1 = this.state.feedate1;
	let receiptno = this.state.receiptno;	
	let paymentid = this.state.paymentid;
		let balamount = this.state.balamount;
		let remainderdate=this.state.remainderdate1;
let refno = this.state.refno;	
let fee = this.state.fee;	
		let search = window.location.href;
let said = this.getParameterByName('said',search);

receiptno=receiptno.replace(/\s+/g, '')

	
				if(paymentid=='0'){
						
				this.setState({errormessage:"Please Select  Payment Mode",open:true});
		 window.scrollTo({        top: 0     })
	return false; 
				}
				if(paymentid!='3'){
					if(refno==''){	
				this.setState({errormessage:"Please Enter Payment Details",open:true});
		 window.scrollTo({        top: 0     })
	return false; 
					}
				}if(fee==''){
						
				this.setState({errormessage:"Please Enter Amount",open:true});
		 window.scrollTo({        top: 0     })
	return false; 
				}
				
				if(balamount>=fee)
				{
					
					
				}else{
						this.setState({errormessage:"Please Enter Amount less than "+balamount,open:true});
		 window.scrollTo({        top: 0     })
	return false; 
				}
				
				this.setState({primarybtntext:'Processing...'});
				var formData = new FormData();
				
				formData.append('action','insertorupdate') 
formData.append('fp_sa_id',said) 
formData.append('fp_id',0) 
formData.append('fp_date',feedate1) 	
formData.append('fp_receiptnumber',receiptno) 
formData.append('fp_amount',fee) 
formData.append('fp_referenceno',refno) 
formData.append('fp_paymentmode',paymentid) 
formData.append('fp_remainderdate',remainderdate) 
formData.append('userid',localStorage.getItem("MMCuserid")) 
formData.append('fp_isfirstpay',2)


axios.post(Serverurl+"ajaxfeepayments.php", formData, {
				}).then(res => {
					if(res.data!=="")
					{
					let strreturn=res.data;
				this.setState({primarybtntext:'Pay Now',errormessage:'Payment Done Successfully',open:true,
				fee:'',paymentid:'0',refno:'',redirect:true,pagename:'/Feeprint?feid='+strreturn
				});
					}
					this.OnloadGenerateecipt(said);
				});
	}

	 render() {
if (this.state.redirect === true) {
      return <Navigate to={this.state.pagename} />
    }
	 return (
  
	  <div className="pagebox-col">
	 <div className="formview">
			<div className="boxheading">
				  <div className="contntpad">
					 <p>  <Collapse in={this.state.open}>
										<Alert
										  action={
											<IconButton
											  aria-label="close"
											  color="inherit"
											  size="small"
											  onClick={() => {
											   this.handleErrorclose();
											  }}
											>
											  <CloseIcon fontSize="inherit" />
											</IconButton>
										  } severity="error"
										>
										{this.state.errormessage}
										</Alert>
								</Collapse></p>
				  <h2><img src={registrationhead} />Fee Payment |<span className="headingfont"> {this.state.studename} - {this.state.studcode}</span></h2>
				  </div> </div>
	   <div className="contntpad">
	 
	  <div className="feeforms">
	    <ul>
	 <li><p>  Balance Amount</p>
	 <p>Rs.{this.state.balamount}</p>
	 
	 </li>
	 
	  <li><p><span className="validation">*</span>  Date</p>
	 <p>
		 	<MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-around">
	 
      <DatePicker fullWidth variant="outlined"
        value={this.state.feedate || addDays(moment().toDate(),1)}
        onChange={this.handfrChange}
		maxDate={addDays(moment().toDate(),0)}
            format="dd/MM/yyyy"
        autoOk name="feedate"
        ampm={false}
	id="feedate"
      />
	   </Grid>
    </MuiPickersUtilsProvider>
	</p>	 
	 </li>
	 
	 <li>
	 
	 <p><span className="validation">*</span> Receipt No.</p>
	 <p><TextField id="txtrecpt" disabled fullWidth type="text" size="small"  placeholder="Receipt No."  name="receiptno" value={this.state.receiptno.trim()}  inputProps={{tabIndex: "1",maxLength:15}} InputLabelProps={{ shrink: true }}  
	  onChange={this.myChangeHandler} /></p>
	  </li>
	   <li><p><span className="validation">*</span>Payment Mode</p>
	  <p>
	  <FormControl  fullWidth size="small" >
       
        <Select
         native
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.paymentlabel, value: this.state.paymentid }}
          onChange={this.myChangeHandler}
          id="state"
		   value={this.state.paymentid}
		  name="paymentid"
		
		
        > <option  value={0}>Select</option  >
		 <option  value={1}>UPI</option  >
		<option  value={2}>Net banking</option  >
		<option  value={3}>Cash</option  >
		<option  value={4}>Cheque</option >
        </Select>
 </FormControl></p>
	  
	  </li>
	
	   <li>
	 
	 <p>Payment Details</p>
	 <p><TextField id="txtref" fullWidth multiline rows={3} type="text" size="small"  placeholder="Payment Details"  name="refno" value={this.state.refno}  inputProps={{tabIndex: "5",maxLength:150}} InputLabelProps={{ shrink: true }}  
	  onChange={this.myChangeHandler} /></p>
	  </li>
	  
	   <li>
	 
	 <p><span className="validation">*</span>  Amount</p>
	 <p><TextField id="txtfee" fullWidth type="text" size="small"  placeholder="Amount"  name="fee" value={this.state.fee}  inputProps={{tabIndex: "6",maxLength:7}} InputLabelProps={{ shrink: true }}  
	  onChange={this.myChangeHandler} /></p>
	  </li>
	  
	  	
	 <li><p><span className="validation">*</span> Next Due Date</p>
	 <p>
		 	<MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-around">
	 
      <DatePicker fullWidth variant="outlined"
        value={this.state.remaindrdate || addDays(moment().toDate(),1)}
        onChange={this.handleremaindChange}
		minDate={addDays(moment().toDate(),0)}
            format="dd/MM/yyyy"
        autoOk name="remaindrdate"
        ampm={false}
	id="nextduedate"
      />
	   </Grid>
    </MuiPickersUtilsProvider>
	</p>	 
	 </li>
	 
	 
	



</ul>
	
	

		 	<div className="clear"></div>		
	 
	 
							 <div className="formbtns">
							 
							 <ul>
							 <li>
							  <p><Button variant="standard" className="frmPrimarybtn" endIcon={<CheckRoundedIcon />} onClick={this.OnFeeinsertSubmit}>
								  {this.state.primarybtntext}
						</Button></p></li></ul>
						
						</div>
						 <div className="clear"></div>
						</div> 
						<div className="feedetailspnl">
						
						<div className="boxheading">
		<div className="admitpad"> <h2><img src={payment} /> Payment History</h2></div></div>
   <div className="admitpad">

 <Paper >
  <TableContainer >  
                <Table stickyHeader aria-label="sticky table">  

					  
					  
                      <TableBody>
                     {(this.state.feedata).map((item,index) => {  
           return (
 <React.Fragment>
 <TableRow key={index} >


<TableCell>{item.feedate}</TableCell>
<TableCell>{item.fp_receiptnumber}<br/><span className="tabfont">{item.paymentmode}</span></TableCell>
<TableCell>Rs.{item.fp_amount}</TableCell>




 </TableRow>

 </React.Fragment>	
);
					})}
					
					{this.state.feecnt===0 &&(
					
					<TableRow key={0} >
<TableCell align="center" colSpan="2">No Records</TableCell>
					</TableRow>
					) }
					
                      </TableBody>


</Table>
  </TableContainer> 
  <TablePagination  
        rowsPerPageOptions={[]}  
        component="div"  
        count={this.state.feecnt}  
        rowsPerPage={this.state.rowsPerPage}  
        page={this.state.page}  
        onPageChange={this.handleChangePage}  
        onChangeRowsPerPage={this.handleChangeRowsPerPage}  
      />   
  </Paper>

	</div>
						</div>
						
						
						<div className="clear"></div>
	  </div>
	 </div>
	  </div>
	
	  )
	 }
}

	




