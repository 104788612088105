import React, { Component } from "react";
import { Navigate  } from 'react-router-dom';
import Serverurl from './Apiurl';
import axios from 'axios';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

export default class Mystudents extends Component 
{
	constructor(props) 
	  {
			super();
			
			this.state = {Studentsarr:[],parentpho:'',studtype:0}
	  }
	 getParameterByName(name, url) {
    if (!url) url = window.location.href;
    url = url.toLowerCase(); 
    name = name.replace(/[\[\]]/g, "\\$&").toLowerCase();
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}
	componentDidMount()
	{
		
		
	if(localStorage.getItem("MMCuserid")===null)
	{
		this.setState({
							redirect: true,
							pagename:'/Login'
						  }) 
	}
	localStorage.removeItem("MMCsaid")
	
	localStorage.removeItem("MMCSRId")
	let search = window.location.href;
let prid = this.getParameterByName('pid',search);
this.OnloadStudentGetbyParents(prid);
	}
OnloadStudentGetbyParents(prid)
{localStorage.setItem("MMCParentid",prid)
	axios.get(Serverurl+'ajaxstudentregistration.php?action=geybyparents&sorting=sr_studentname&sr_pr_id='+prid)
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
							Studentsarr: resp.data,
							parentpho:resp.data[0]["pr_phonenumber"],
						  }) 
			}
			
	   });

} Onclickadmision(val,type){
	
	
        this.setState({ said: val,  studtype: type});  
    
        if(type==="1")
		{
			localStorage.setItem("MMCsaid",val)
				this.setState({
							redirect: true,
							pagename:'/Studentadmission'
						  }) 
			
		}else if(type==="3")
		{
			localStorage.setItem("MMCSRId",val)
				this.setState({
							redirect: true,
							pagename:'/Studentadmission'
						  }) 
			
		}
 else if(type==="2")
		{
			
			this.setState({
							redirect: true,
							pagename:'/StudentFees?said='+val
						  }) 
		}
      }
	
	 render() {
if (this.state.redirect === true) {
      return <Navigate to={this.state.pagename} />
    }
	 return (
  
	 <React.Fragment> 
	 
	 
	 <div className="pagebox-col">	 <div className="contntpad">
	{this.state.Studentsarr.length>0 && (
	

	<React.Fragment> 	<h2 className="parenthead">Parent Access No.{this.state.parentpho}</h2><ul className="studentlist">
		{(this.state.Studentsarr).map((item,index) => { 
	let strarr=[];
		let strval="0";
		let classname="";
		let classname1="";let total="0";
		
		
		if(item.studadmision!=""){
			 strarr=  item.studadmision.split('~');
			 classname="admsndisablebtn";
			 classname1="admsndisablebtn";
			 total=parseFloat(strarr[3])+parseFloat(strarr[4]);
			
			if(strarr[2]==="1")
			{
				classname="admisionbtn";
			}
			if(parseFloat(total)>parseFloat(strarr[6]))
			{
				classname1="admsnpaybtn";
			}
		}else{
			
			classname="admisionbtn";
			 classname1="admsndisablebtn";
		}	
			
			if(item.sr_id!==null){
			return ( <React.Fragment> 

			<li> <div className="profilepad">
			<div className="studprofilimg">
			{item.sr_profileimg!=="" && (
			<a href={'#/Studentviewpage?srid='+item.sr_id} ><img src={Serverurl+'uploads/'+item.sr_profileimg} /></a>
			)}
			{item.sr_profileimg==="" && (
			<a href={'#/Studentviewpage?srid='+item.sr_id} ><img src={Serverurl+'uploads/user.jpg'} /></a>
			)}
			</div>
			<h3>			<a href={'#/Studentviewpage?srid='+item.sr_id} >{item.sr_studentname} - {item.sr_studentcode}</a></h3>
			
			{item.studadmision!="" &&(<React.Fragment> 
			<p>{strarr[0]}</p>
			
			{(strarr[1]!==0 && strarr[2]==="1") && ( 
			<div className={classname} onClick={()=>{this.Onclickadmision(item.sr_id,"3")}}>
			Admission
			</div> )}
			{(strarr[1]!==0 && strarr[2]!=="1") && ( 
			<div className={classname} >
			Admission
			</div> )}
			&nbsp;&nbsp;
			
			{parseFloat(total)>parseFloat(strarr[6]) && ( 
			<div className={classname1} onClick={()=>{this.Onclickadmision(strarr[1],"2")}}>
			Pay
			</div>
			 )}
			 {parseFloat(total)==parseFloat(strarr[6]) && ( 
			<div className={classname1} >
			Pay
			</div>
			 )}
			</React.Fragment> )}
			
				{item.studadmision=="" &&(<React.Fragment> 
				<p><br/></p>
				<div className={classname} onClick={()=>{this.Onclickadmision(item.sr_id,"3")}}>
			Admission
			</div>&nbsp;&nbsp;
				<div className={classname1} onClick={()=>{this.Onclickadmision(0,"2")}}>
			Pay
			</div>
				</React.Fragment> )}
				
				
			
			</div></li>

			</React.Fragment>
			);	
			}
			
			
		})}
		
		<li> <div className="profilepad">
			<div className="registerstud">
			<a href="#/StudentRegistration"><AddCircleOutlineIcon /><p>Add New</p></a>	</div>
			</div></li>
		</ul></React.Fragment> 
	)}{this.state.Studentsarr.length===0 && (<React.Fragment> 
	<h2 className="parenthead">Parent Access No.{this.state.parentpho}</h2>
	
	<ul className="studentlist"><li> <div className="profilepad">
			<div className="registerstud">
			<a href="#/StudentRegistration"><AddCircleOutlineIcon /><p>Add New</p></a>	</div>
			</div></li></ul></React.Fragment> 
	)}</div></div>
	  </React.Fragment> 
	  )
	 }
}











