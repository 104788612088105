import React, { Component } from "react";
import { Navigate  } from 'react-router-dom';
import axios from 'axios'; 

import Serverurl from './Apiurl';
import Button from '@mui/material/Button';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@mui/material/IconButton';
import prof from './images/test-result.png';
import { DropzoneArea } from 'material-ui-dropzone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
export default class AttendanceImport extends Component {
	
	constructor(props) 
	  {super();
		 this.state = {redirect:false,pagename:'',errormessage:'',open:false,imgfile:'',primarybtntext:'Upload'}
	  }
			componentWillMount() { 
localStorage.setItem("pageactive",6)
}
 componentDidMount()
	{
		localStorage.setItem("pageactive","5")
	}
	handleErrorclose=(event)=>{
	  
	this.setState({open: false});  
  }
  OnImportSubmit=(event)=>{
	  
	   var formData = new FormData();
	 formData.append('action','import')
		   formData.append('Filedata', this.state.imgfile[0])
		   formData.append('branchid',localStorage.getItem("MMCbranchid"))
	formData.append('userid',localStorage.getItem("MMCuserid"))
	this.setState({primarybtntext:'Processing...'});
	axios.post(Serverurl+"ajaxattendance.php", formData, {
	}).then(res => {
		
		this.setState({primarybtntext:'Upload',open:true,errormessage:'Uploaded successfully'});
		
	});
  }
	render() {
if (this.state.redirect === true) {
      return <Navigate to={this.state.pagename} />
    }
	 return (
  
	 <React.Fragment> 
	  <div className="pagebox-col">
	 <div className="">
			<div className="boxheading">
				  <div className="contntpad">
					 <p>  <Collapse in={this.state.open}>
										<Alert
										  action={
											<IconButton
											  aria-label="close"
											  color="inherit"
											  size="small"
											  onClick={() => {
											   this.handleErrorclose();
											  }}
											>
											  <CloseIcon fontSize="inherit" />
											</IconButton>
										  } severity="error"
										>
										{this.state.errormessage}
										</Alert>
								</Collapse></p>
				  <h2><img src={prof} /> Import Attendance</h2>
				  </div> </div>
	   <div className="contntpad ">
	    <div className="formviewatt ">
	   
	<p><a href={Serverurl+"uploads/importattendance.csv"} className="frmsecondarybtn" target="_blank">Download CSV File</a>
	</p>
	 <p className="fileuploaddrop">
	   <DropzoneArea dropzoneText="" 
 showPreviews={true}
  showPreviewsInDropzone={false}
  useChipsForPreview
  previewGridProps={{container: { spacing: 1, direction: 'row' }}}
dropzoneText={"Drag and drop/Select CSV file here"}
  previewText="Uploaded files"
maxFileSize={5000000} Icon={CloudUploadIcon} dropzoneClass="filesupld" filesLimit={1} acceptedFiles={['text/csv']} 
 
 
  onChange={(files) => {
  var file, img;
  var _URL = window.URL || window.webkitURL;
  this.setState({ imgfile: files })
  
		
 

  }}
  
 
/></p>  <p className="alignright"><Button variant="standard" className="frmPrimarybtn" endIcon={<CheckRoundedIcon />} onClick={this.OnImportSubmit}>
								  {this.state.primarybtntext}
						</Button></p>
	   <div className="clear"></div>
	   </div>  </div>
	   </div>
	   </div>
	 </React.Fragment> 
	 )
	}
}








