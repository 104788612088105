import React ,{Component} from 'react'; 
import { Navigate  } from 'react-router-dom';
import axios from 'axios'; 
import Table from '@mui/material/Table';
import Serverurl from './Apiurl';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow'; 
import { makeStyles } from '@mui/styles'; 
import Paper from '@mui/material/Paper';  
import TableSortLabel from '@mui/material/TableSortLabel';
import TableBody from '@mui/material/TableBody'; 
import TableCell from '@mui/material/TableCell';  
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';  
import TablePagination from '@material-ui/core/TablePagination'; 
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import pageimg from './images/attendance.png';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import NotificationPopUp from './NotificationPopUp';

import DownloadremainderModule from './DownloadremainderModule';
const headCells = [


 
  
  
  ]
  function stableSort(array, cmp) {
	  const stabilizedThis = array.map((el, index) => [el, index]);
	  stabilizedThis.sort((a, b) => {
		const order = cmp(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	  });
	  return stabilizedThis.map(el => el[0]);
}
function desc(a, b, orderBy) {
	  if (b[orderBy] < a[orderBy]) {
		return -1;
	  }
	  if (b[orderBy] > a[orderBy]) {
		return 1;
	  }
	  return 0;
}
function getSorting(order, orderBy) {
	return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

function EnhancedTableHead(props) {
  const { classes,  order, orderBy,  rowCount, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={useStyles.visuallyHidden}>
                  {order === 'desc' ? ' ' : ' '}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
 
  onRequestSort: PropTypes.func.isRequired,
 
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  
  
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  };

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 
 icon: {
   verticalalign:'middle',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
});


export default class Downloads extends Component {
	
    constructor(props) {
    super(props);
   
      this.state = {redirect: false,
      Downloadsdata:[],noid:'',page: 0 ,prevpage:0,totaldata:0,rowsPerPage:50,rowsPaging:[],
	 
	  order:'desc' ,orderBy:'',titles:'',type:2,types:'',searchcustomer:'',popuptype:'2',
	          
      }
     
	   
	}
	
	

 getParameterByName(name, url) {
    if (!url) url = window.location.href;
    url = url.toLowerCase(); 
    name = name.replace(/[\[\]]/g, "\\$&").toLowerCase();
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}	



		componentWillMount() { 
		
			let search = window.location.href;
let strtype = this.getParameterByName('type',search);


if(strtype=="2")
{
localStorage.setItem("pageactive",8)


}
if(strtype=="3")
{
localStorage.setItem("pageactive",9)} 

 

}

	componentDidMount() {	
	
	document.title = "MM Coching Center - Downloads";
	
	if(localStorage.getItem("MMCuserid")==null)
		  {

		   this.setState({
            redirect: true,
			pagename:'/Login'
          })
		  }

} 


render() {

if (this.state.redirect === true) {
      return <Navigate to={this.state.pagename} />
    }

  return (
  
  
  



 
  <React.Fragment>
   		



<DownloadremainderModule   moduletype={"3"} />

    

 </React.Fragment> 

 
 
  );
}
}

