import { HashRouter as Router, Route, Routes,NavLink,Outlet,Outlet as head } from 'react-router-dom';
import React ,{Component} from 'react';
import ReactDOM from "react-dom";
import { Navigate  } from 'react-router-dom';
import Login from "./components/Login";
import Logout from "./components/Logout";
import Dashboard from "./components/Dashboard";
import Mystudents from "./components/Mystudents";
import { askForPermissioToReceiveNotifications } from './firebase';
import firebase from 'firebase/compat/app';
import StudentRegistration from "./components/StudentRegistration";
import Batch from "./components/Batch";
import Branch from "./components/Branch";
import Subjects from "./components/Subjects";
import Changepassword from "./components/Changepassword";
import User from "./components/User";
import Downloads from "./components/Downloads";
import StudentregistrationReport from "./components/StudentregistrationReport";
import Feeprint from "./components/Feeprint";
import Notificationview from "./components/Notificationview";
import TestReport from "./components/TestReport";
import FeeRemainder from "./components/FeeRemainder";
import AdminTest from "./components/AdminTest";
import Trustclassandyear from "./components/Trustclassandyear";
import Studentadmission from "./components/Studentadmission";
import StudentFees from "./components/StudentFees";
import ParentPassword from "./components/ParentPassword";
import Studentviewpage from "./components/Studentviewpage";
import NotificationCalendar from "./components/ReactBigCalendar";
import FeepaymentReport from "./components/FeepaymentReport";
import BulkAttendance from "./components/BulkAttendance";
import StudentattendanceGraph from "./components/StudentattendanceGraph";
import AttendanceImport from "./components/AttendanceImport";
import UploadTestResults from "./components/UploadTestResults";
import MobileNotification from "./components/MobileNotification";
import MobileChangepassword from "./components/MobileChangepassword";
import StudentnotAdmissionlist from "./components/StudentnotAdmissionlist";
import ImportStudentsMarks from "./components/ImportStudentsMarks";
import RefundReport from "./components/RefundReport";

import Studentmanagement from "./components/Studentmanagement";
import ImportStudents from "./components/ImportStudents";
import FeepaymentOtherReport from "./components/FeepaymentOtherReport";
import MobNotificationview from "./components/MobNotificationview";
import MobLogout from "./components/MobLogout";
import MobLogin from "./components/MobLogin";
import MobileMyprofile from "./components/MobileMyprofile";
import { withRouter } from "react-router";
import Layouthead from "./components/Layouthead";
import Usercontentmaster from "./components/Usercontentmaster";
import Studentexamgraph from "./components/Studentexamgraph";

import MobileMaster from "./components/MobileMaster";
import MobileContent from "./components/MobileMasterContent";

import Studentexamgraphprint from "./components/Studentexamgraphprint";
import Studentexamgraphprint1 from "./components/Studentexamgraphprint1";


import MobStudentDashbaord from "./components/MobStudentDashbaord";
import MobileDownloads from "./components/MobileDownloads";
import Printallstudents from "./components/Printallstudents";
class App extends Component {
	
	
  render() {
	 
    return (
	
	
	
	<Router basename="/">
	<Routes>
		   
			<Route element={<Layouthead />}>
			  


 <Route path="/Studentexamgraphprint" element={<Studentexamgraphprint />} />
 <Route path="/Studentexamgraphprint1" element={<Studentexamgraphprint1 />} />


			  <Route path="/Batch" element={<Batch />} />
			   <Route path="/Branch" element={<Branch />} />
			      <Route path="/Notificationview" element={<Notificationview />} />
			    <Route path="/Feeprint" element={<Feeprint />} />
			     <Route path="/Subjects" element={<Subjects />} />
				  <Route path="/Changepassword" element={<Changepassword />} />
				   <Route path="/User" element={<User />} />
		 <Route path="/AdminTest" element={<AdminTest />} />
		  <Route path="/Downloads" element={<Downloads />} />
		    <Route path="/StudentregistrationReport" element={<StudentregistrationReport />} />
		  
		    <Route path="/RefundReport" element={<RefundReport />} />
		  	  <Route path="/ImportStudentsMarks" element={<ImportStudentsMarks />} />
		   <Route path="/StudentnotAdmissionlist" element={<StudentnotAdmissionlist />} />
		   <Route path="/FeepaymentOtherReport" element={<FeepaymentOtherReport />} />
		     <Route path="/Studentmanagement" element={<Studentmanagement />} />
		   
		    <Route path="/BulkAttendance" element={<BulkAttendance />} />
		   <Route path="/TestReport" element={<TestReport />} />
		    <Route path="/FeeRemainder" element={<FeeRemainder />} />
			  <Route path="/FeepaymentReport" element={<FeepaymentReport />} />   
			   <Route path="/Dashboard" element={<Dashboard />} />
			   <Route path="/Mystudents" element={<Mystudents />} />
			    <Route path="/StudentRegistration" element={<StudentRegistration />} />
				 <Route path="/Studentadmission" element={<Studentadmission />} />
				  <Route path="/StudentFees" element={<StudentFees />} />
				  <Route path="/ParentPassword" element={<ParentPassword />} />
				   <Route path="/Studentviewpage" element={<Studentviewpage />} />
				   <Route path="/Printallstudents" element={<Printallstudents />} />
				    <Route path="/NotificationCalendar" element={<NotificationCalendar />} />
		   <Route path="/StudentattendanceGraph" element={<StudentattendanceGraph />} />			
	<Route path="/AttendanceImport" element={<AttendanceImport />} />			
<Route path="/UploadTestResults" element={<UploadTestResults />} />	

<Route path="/ImportStudents" element={<ImportStudents />} />	
		 <Route path="/Trustclassandyear" element={<Trustclassandyear />} />	
			</Route>
	  </Routes>
			 <Routes>
			 <Route element={<MobileMaster />}>
			  
			  <Route path="/MobileDownloads" element="Downloads" />
					<Route path="/MobStudentDashbaord" element="Dashboard" />
				 <Route path="/MobNotificationview"  element="Remainder" />
				<Route path="/MobileNotification" element="Notifications" />
				<Route path="/MobStudentDashbaord" element="Dashboard" />
					<Route path="/MobileMyprofile" element="My Profile"  />
				<Route path="/MobileChangepassword" element="Change Password"  />
					
			 </Route>
		 
		 	 </Routes> 
			 
			 <Routes>
			<Route element={<MobileContent />}>
					
				 <Route path="/MobNotificationview" element={<MobNotificationview/>} />
	  <Route path="/MobileNotification" element={<MobileNotification/>} />
		<Route path="/MobileDownloads" element={<MobileDownloads/>}  />
		<Route path="/MobStudentDashbaord" element={<MobStudentDashbaord/>} />
		<Route path="/MobileMyprofile" element={<MobileMyprofile/>} />
		<Route path="/MobileChangepassword" element={<MobileChangepassword/>} />
		
			</Route>
		
				<Route path="/MobLogout" element={<MobLogout/>}/>
			<Route path="/Login" element={<Login/>}/>
		<Route path="/MobLogin" element={<MobLogin/>}/>
		
				<Route path="/Logout" element={<Logout/>}/>
			<Route exact path="/"  element={<Navigate to="/Login" />}  />
		 </Routes>
	  
	</Router>
	
	
	
	
    );
  }
}

export default App;
